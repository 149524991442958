import React, { useEffect, useState } from "react";
import { HTMLProps, cx, forwardRef, useCompConfig } from "@hybrbase/system";
import { MainFooterConfig } from "../styles/MainFooter.config";
import { MainFooterVariant } from "../types/MainFooter.constants";
import { TMainFooterConfigReturn } from "../types/MainFooter.config.types";
import {
  Container,
  Grid,
  Stack,
  StackDirection,
  StackSpace,
  StackVariant,
  TextContainer,
  Tile,
} from "layout";
import { ThemeElement } from "@hybrbase/themes";
import {
  Heading,
  HeadingSize,
  HeadingVariant,
  Icon,
  IconName,
  IconSize,
  Link,
  LinkProps,
  LinkVariant,
  Text,
  TextSize,
} from "elements";
import { uid } from "react-uid";

export interface MainFooterData {
  navigationLinks?: LinkProps[];
  locations?: any[];
  privacyLinks?: LinkProps[];
}

export interface MainFooterOptions {}

export interface MainFooterProps
  extends Omit<HTMLProps<"footer">, keyof MainFooterData>,
    MainFooterOptions,
    MainFooterData {
  variant?: MainFooterVariant;
  theme?: ThemeElement;
}

export const MainFooter = forwardRef<MainFooterProps, "footer">(
  (props, ref) => {
    const {
      variant = MainFooterVariant.Default,
      className,
      theme = ThemeElement.Dark,
      navigationLinks,
      locations,
      privacyLinks,
      ...rest
    } = props;

    const { styles }: TMainFooterConfigReturn = useCompConfig(
      MainFooterConfig,
      { variant }
    );

    const updateVnTime = () => {
      return new Intl.DateTimeFormat("vi-VN", {
        hour: "2-digit",
        minute: "2-digit",
      }).format(Date.now());
    };

    const [currentVnTime, setCurrentVnTime] = useState<string>(updateVnTime);

    useEffect(() => {
      const interval = setInterval(() => {
        setCurrentVnTime(updateVnTime);
      }, 60 * 1000); // update every 60 sec
      return () => clearInterval(interval);
    }, []);

    return (
      <footer
        data-comp="main-footer"
        data-variant={variant}
        data-theme-element={theme}
        className={cx(styles.Root, className)}
        ref={ref}
        {...rest}
      >
        <Container className={styles.Container}>
          <Grid>
            <Grid.Item className="sm:flex-col-2">
              <Tile>
                <Link title="home" href="/home">
                  <span className="sr-only">home</span>
                  <div className="inline-block w-[175px]">
                    <Icon
                      name={IconName.HybrbaseType}
                      size={IconSize.Auto}
                      className="fill-current"
                    />
                  </div>
                </Link>
                {navigationLinks?.length > 0 ? (
                  <Stack variant={StackVariant.Responsive} className="mt-lg">
                    {navigationLinks?.map((item, index) => (
                      <Link
                        key={uid(index)}
                        className="text-sm"
                        href={item?.href}
                        {...item}
                      >
                        {item?.children}
                      </Link>
                    ))}
                  </Stack>
                ) : null}
              </Tile>
            </Grid.Item>
            <Grid.Item className="sm:flex-col-1  mt-md pt-xl sm:mt-0 sm:pt-0 sm:border-none">
              <Stack direction={StackDirection.Col}>
                <TextContainer>
                  <Heading
                    variant={HeadingVariant.List}
                    size={HeadingSize.FluidSm}
                  >
                    <span className="mr-xxs">Ho Chi Minh City </span>
                    <span className="text-xs text-contrast-medium">
                      {" "}
                      {currentVnTime}
                    </span>
                  </Heading>
                </TextContainer>
                <div className="max-w-[22rem] -mt-md">
                  <Link
                    href="https://www.google.com/maps/dir/10.798111,106.73527/A11.12,+Centana,+36+Mai+Chi+Tho,+Thu+Duc,+Ho+Chi+Minh+City,+Vietnam/@10.7935638,106.7352141,15z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3175275c74a30675:0xb7f9d62628a8e372!2m2!1d106.7521082!2d10.7906183"
                    variant={LinkVariant.Reset}
                    className="text-inherit"
                    isOpenNewTab
                  >
                    <Text size={TextSize.Sm}>
                      A11.12, Centana, <br />
                      36 Mai Chi Tho, Thuc Duc, <br /> Ho Chi Minh City, Vietnam
                    </Text>
                  </Link>
                  <Link
                    href="tel:+84938193083"
                    variant={LinkVariant.Reset}
                    className="text-inherit"
                  >
                    <Text size={TextSize.Sm}>Phone: +84 938 193 083</Text>
                  </Link>
                </div>
                <Stack>
                  <Link href="https://www.instagram.com/hybrbase/" isOpenNewTab>
                    <Icon name={IconName.Instagram} size={IconSize.Md} />
                  </Link>
                  <Link
                    href="https://www.linkedin.com/company/hybrbase"
                    isOpenNewTab
                  >
                    <Icon name={IconName.Linkedin} size={IconSize.Md} />
                  </Link>
                  <Link href="https://twitter.com/hybrbase" isOpenNewTab>
                    <Icon name={IconName.Twitter} size={IconSize.Md} />
                  </Link>
                </Stack>
              </Stack>
            </Grid.Item>
            <Grid.Item className="sm:flex-col-1  mt-md pt-xl sm:mt-0 sm:pt-0 sm:border-none">
              <Stack direction={StackDirection.Col} space={StackSpace.Md}>
                {privacyLinks?.map((item, index) => (
                  <Link
                    {...item}
                    key={uid(index)}
                    href={item?.href}
                    className="text-sm"
                  >
                    {item?.children}
                  </Link>
                ))}
                <TextContainer className="mt-md max-w-xxxxs">
                  <Text className="!mb-0" size={TextSize.Sm}>
                    Copyright 2023,
                  </Text>
                  <Text className="mt-0" size={TextSize.Sm}>
                    Designed by HYBRBASE
                  </Text>
                </TextContainer>
              </Stack>
            </Grid.Item>
          </Grid>
        </Container>
      </footer>
    );
  }
);

MainFooter.displayName = `MainFooter`;

export default MainFooter;
