export enum TileParts {
  Root = "Root",
}

export enum TileVariant {
  Default = "default",
}

export enum TileYSpace {
  None = "none",
  Sm = "sm",
  Md = "md",
  Lg = "lg",
  Xl = "xl",
}
export enum TileXSpace {
  None = "none",
  Sm = "sm",
  Md = "md",
  Lg = "lg",
  Xl = "xl",
}

export enum TilePosition {
  Center = "center",
}
