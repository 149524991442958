import {
  MediaFeaturesParts,
  MediaFeaturesVariant,
} from "../types/MediaFeatures.constants";
import { TMediaFeaturesConfigProps } from "../types/MediaFeatures.config.types";

export const MediaFeaturesConfig: TMediaFeaturesConfigProps = {
  parts: Object.values(MediaFeaturesParts),
  css: {
    variants: {
      [MediaFeaturesVariant.Default]: {
        Root: "",
      },
    },
  },
};
