export enum ImageParts {
  Root = "Root",
  Image = "Image",
}

export enum ImageVariant {
  Default = "default",
}

export enum ImageLayout {
  Instrinsic = "instrinsic",
  Responsive = "Responsive",
  FillCover = "fill-cover",
  FillContain = "fill-contain",
  FillNone = "fill-none",
  BackgroundCover = "background-cover",
}
