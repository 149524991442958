import React, { useCallback, useEffect } from "react";
import { HTMLProps, cx, forwardRef } from "@hybrbase/system";
import { MainHeaderVariant } from "../types/MainHeader.constants";
import {
  MainHeaderProvider,
  useMainHeader,
  UseMainHeaderProps,
} from "../utils";
import { Breakpoint, ThemeElement } from "@hybrbase/themes";
import {
  Button,
  Icon,
  IconName,
  IconSize,
  Link,
  LinkProps,
  Text,
  TextContrast,
  TextSize,
} from "elements";
import { useMediaQuery } from "hooks";
import { TextContainer, Tile } from "layout";
import { m } from "framer-motion";
import { uid } from "react-uid";

export interface MainHeaderData {
  navigationLinks?: LinkProps[];
}

export interface MainHeaderOptions {
  isSticky?: boolean;
  isPageTransition?: boolean;
  isMenuOpen: boolean;
  setMenuOpen: (isMenuOpen: boolean) => void;
}

export interface MainHeaderProps
  extends Omit<HTMLProps<"header">, keyof MainHeaderData>,
    MainHeaderOptions,
    MainHeaderData,
    UseMainHeaderProps {}

export const MainHeader = forwardRef<MainHeaderProps, "header">(
  (props, ref) => {
    const {
      variant = MainHeaderVariant.Default,
      className,
      theme = ThemeElement.Default,
      isSticky = true,
      isPageTransition,
      children,
      navigationLinks,
      isMenuOpen,
      setMenuOpen = () => null,
      ...rest
    } = props;

    const isTablet = useMediaQuery(Breakpoint.TabletLandScape);

    const { ...ctx } = useMainHeader({ variant, theme, ...rest });
    const context = React.useMemo(() => ctx, [ctx]);

    // set initial always to be false
    useEffect(() => {
      setMenuOpen(false);
    }, [isPageTransition, setMenuOpen, isTablet]);

    const toggle = useCallback(() => {
      setMenuOpen(!isMenuOpen);
    }, [isMenuOpen, setMenuOpen]);

    return (
      <MainHeaderProvider value={context}>
        <header
          data-comp="main-header"
          data-variant={variant}
          data-theme-element={theme}
          className={cx(
            ctx.styles.Root,
            { fixed: isSticky, relative: !isSticky },
            className
          )}
          ref={ref}
          {...rest}
        >
          <nav
            className={ctx.styles.Nav}
            role="navigation"
            aria-labelledby="app-header-nav"
          >
            <span id="app-header-nav" className="sr-only">
              app-header navigation
            </span>

            <Link
              title="home"
              href="/"
              className={(ctx.styles.Brand, "w-[100px]")}
            >
              <span className="sr-only">home</span>
              <Icon name={IconName.HybrbaseType} size={IconSize.Auto} />
            </Link>
            <div className="hidden sm:block space-x-md">
              {navigationLinks?.map((item, index) => (
                <Link
                  className={cx("text-xs")}
                  href={item?.href}
                  title={item?.children?.toString()}
                  key={uid(index)}
                >
                  {item?.children}
                </Link>
              ))}
              {children}
            </div>

            <Button
              title="test"
              className={"sm:hidden border-none !p-0"}
              onClick={toggle}
              aria-label="Toggle Menu"
              aria-expanded={isMenuOpen}
            >
              {isMenuOpen ? "Close" : "Menu"}
            </Button>
          </nav>

          <m.div
            initial="hide"
            animate={isMenuOpen ? "show" : "hide"}
            variants={{
              show: {
                opacity: 1,
                visibility: "visible",
              },
              hide: {
                opacity: 0,
                visibility: "hidden",
              },
            }}
            transition={{ duration: 0.9, ease: [0.16, 1, 0.3, 1] }}
            className={ctx.styles.MenuMobile}
            data-theme-element={theme}
          >
            <div className={ctx.styles.MenuMobileInner}>
              <Tile className="flex flex-col sm:justify-between">
                <div className="flex flex-grow flex-col space-y-lg">
                  <TextContainer>
                    <Text
                      className=""
                      size={TextSize.FluidXs}
                      contrast={TextContrast.Medium}
                    >
                      Digital-First Branding, <br />
                      Premium Web Production, <br /> Based in Ho Chi Minh City
                    </Text>
                  </TextContainer>
                  <div className="flex flex-col space-y-sm">
                    {navigationLinks?.map((item, index) => (
                      <div key={uid(index)}>
                        <Link
                          className="!text-fluid-sm"
                          key={uid(index)}
                          href={item?.href}
                          title={item?.children?.toString()}
                        >
                          {item?.children}
                        </Link>
                      </div>
                    ))}
                  </div>
                  <div>{children}</div>
                </div>
              </Tile>
            </div>
          </m.div>
        </header>
      </MainHeaderProvider>
    );
  }
);

MainHeader.displayName = `MainHeader`;

export default MainHeader;
