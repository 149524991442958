import { ContactCta } from "landing";
import { storyblokEditable } from "@hybrbase/storyblok";
import { useMemo } from "react";
import usePageStore from "@/stores/page";
import { renderRichText } from "@hybrbase/storyblok";

export const SbContactCta = ({ blok }) => {
  const { title, links, theme } = blok; // Component scheme

  const setCalendlyOpen = usePageStore(
    ({ setCalendlyOpen }) => setCalendlyOpen
  );

  const filteredLinks = useMemo(
    () =>
      links.map((item) => {
        return {
          href: item.link_href.url,
          children: item.link_label,
        };
      }),
    [links]
  );

  return (
    <ContactCta
      title={renderRichText(title)}
      links={filteredLinks}
      theme={theme}
      onClickCalendly={() => setCalendlyOpen(true)}
      {...storyblokEditable(blok)}
    />
  );
};
