import {
  VideoLayout,
  VideoParts,
  VideoVariant,
} from "../types/Video.constants";
import { TVideoConfigProps } from "../types/Video.config.types";

const style = {
  playBtn:
    "absolute top-0 left-0 w-full h-full z-1 flex justify-center items-center",
  playBtnBackground:
    "absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white bg-opacity-25 p-lg rounded-full",

  controls:
    "flex justify-center items-center absolute bottom-0 w-full z-1 box-border z-fixed",
  controlsButton: "border-none cursor-pointer bg-transparent p-0 color-inherit",
  controlsTime: "text-sm -mb-xxxs",
  timeline: "flex items-center relative flex-grow -mb-xxxs bg-contrast-low",
  timelineProgress: "z-1 absolute",
  timelineInputRange:
    "absolute block appearance-none w-full bg-transparent color-transparent p-0",
};

export const VideoConfig: TVideoConfigProps = {
  parts: Object.values(VideoParts),
  css: {
    variants: {
      [VideoVariant.Default]: {
        PlayBtn: style.playBtn,
        PlayBtnBackground: style.playBtnBackground,

        Controls: style.controls,
        ControlsButton: style.controlsButton,
        ControlsTime: style.controlsTime,
        Timeline: style.timeline,
        TimelineProgress: style.timelineProgress,
        TimelineInputRange: style.timelineInputRange,
      },
    },
    props: {
      layout: {
        [VideoLayout.Contain]: {
          Root: "relative inline-block !w-auto",
        },
        [VideoLayout.Ratio]: {},
        [VideoLayout.BackgroundCover]: {
          Root: "absolute top-0 left-0",
        },
      },
    },
  },
};
