import React from "react";
import { HTMLProps, cx, forwardRef, useCompConfig } from "@hybrbase/system";
import { ContactCtaConfig } from "../styles/ContactCta.config";
import { ContactCtaVariant } from "../types/ContactCta.constants";
import { TContactCtaConfigReturn } from "../types/ContactCta.config.types";
import { Button, Heading, HeadingVariant, LinkProps, Text } from "elements";
import {
  Section,
  SectionData,
  Container,
  TextContainer,
  Grid,
  Tile,
  GridGap,
  GridVariant,
  GridItemSize,
} from "layout";
import { ThemeElement } from "@hybrbase/themes";
import { uid } from "react-uid";

export interface ContactCtaData extends SectionData {
  links: LinkProps[];
}

export interface ContactCtaOptions {
  onClickCalendly?: () => void;
}

export interface ContactCtaProps
  extends Omit<HTMLProps<"section">, keyof ContactCtaData>,
    ContactCtaOptions,
    ContactCtaData {
  variant?: ContactCtaVariant;
  theme?: ThemeElement;
}

export const ContactCta = forwardRef<ContactCtaProps, "section">(
  (props, ref) => {
    const {
      variant = ContactCtaVariant.Default,
      className,
      theme = ThemeElement.Default,
      title,
      text,
      links,
      onClickCalendly,
      ...rest
    } = props;

    const { styles }: TContactCtaConfigReturn = useCompConfig(
      ContactCtaConfig,
      { variant }
    );

    return (
      <Section
        data-comp="contact-cta"
        data-variant={variant}
        theme={theme}
        className={cx(styles.Root, className)}
        ref={ref}
        {...rest}
      >
        <Container>
          <Grid gap={GridGap.None} className="lg:max-w-xxxl lg:mx-auto">
            <Grid.Item className="sm:flex-col-2">
              <Tile>
                <TextContainer className="max-w-xxs sm:max-w-sm">
                  <Heading variant={HeadingVariant.Section}>{title}</Heading>
                  {text ? <Text>{text}</Text> : null}
                </TextContainer>
              </Tile>
            </Grid.Item>
            <Grid.Item className="sm:flex-col-2 self-center mt-xl sm:mt-0">
              <Grid variant={GridVariant.Custom}>
                {onClickCalendly ? (
                  <Grid.Item size={GridItemSize.Content}>
                    <Button title={"Schedule a call"} onClick={onClickCalendly}>
                      Schedule a call
                    </Button>
                  </Grid.Item>
                ) : null}
                <Grid.Item size={GridItemSize.Content}>
                  <Button.Link href="mailto:toby.bui@hybrbase.com?subject=Start a project">
                    Start a project
                  </Button.Link>
                </Grid.Item>
                <Grid.Item size={GridItemSize.Content}>
                  <Button.Link href="mailto:toby.bui@hybrbase.com?subject=Join our team">
                    Join our team
                  </Button.Link>
                </Grid.Item>
                {links?.map((item, index) => (
                  <Grid.Item key={uid(index)} size={GridItemSize.Content}>
                    <Button.Link href={item?.href}>
                      {item?.children}
                    </Button.Link>
                  </Grid.Item>
                ))}
              </Grid>
            </Grid.Item>
          </Grid>
        </Container>
      </Section>
    );
  }
);

ContactCta.displayName = `ContactCta`;

export default ContactCta;
