import { HeroParts, HeroVariant } from "../types/Hero.constants";
import { THeroConfigProps } from "../types/Hero.config.types";

export const HeroConfig: THeroConfigProps = {
  parts: Object.values(HeroParts),
  css: {
    variants: {
      [HeroVariant.Default]: {
        Root: "",
        Container: "mb-0",
        TextContainer: "",
        Heading: "",
        Text: "text-fluid-xs",
      },
    },
  },
};
