import { MediaFeature } from "landing";
import { storyblokEditable } from "@hybrbase/storyblok";
import { useMemo } from "react";
import { getSbImageInfo } from "@/lib/storyblok";
import { getImageService, getVideoService, isVideo } from "@/utils";
import { renderRichText } from "@hybrbase/storyblok";

export const SbMediaFeature = ({ blok }) => {
  const {
    title,
    text,
    link_label,
    link_href,
    image_src,
    video_src,
    video_poster,
    heading_size,
    aspect_ratio_size,
    theme,
  } = blok; // Component scheme

  const { sbImage, sbVideo } = useMemo(
    () => ({
      sbImage: getSbImageInfo(image_src?.filename, aspect_ratio_size),
      sbVideo: {
        src: getVideoService({ src: video_src?.filename }),
        poster: getImageService({ src: video_poster?.filename }),
        isBoolean: isVideo(video_src?.filename),
      },
    }),

    [image_src?.filename, video_src, video_poster, aspect_ratio_size]
  );

  return (
    <MediaFeature
      title={renderRichText(title)}
      text={text}
      link={{
        href: link_href?.cached_url,
        isOpenNewTab: link_href?.target === "_blank",
        children: link_label,
      }}
      theme={theme}
      headingSize={heading_size}
      aspectRatioSize={aspect_ratio_size}
      image={{
        src: sbImage?.filename,
        alt: image_src?.alt,
        title: image_src?.title,

        width: sbImage.width,
        height: sbImage.height,
      }}
      isVideo={sbVideo.isBoolean}
      video={{
        src: sbVideo.src,
        poster: sbVideo.poster,
      }}
      {...storyblokEditable(blok)}
    />
  );
};
