import { createContext } from "@hybrbase/system";
import { UseCarouselReturn } from "./use-carousel";

const [CarouselProvider, useCarouselContext] = createContext<UseCarouselReturn>(
  {
    name: "CarouselContext",
    errorMessage:
      "useCarouselContext: `context` is undefined. Seems you forgot to wrap all Carousel's components within <Carousel />",
  }
);

export { CarouselProvider, useCarouselContext };
