import {
  ContactCtaParts,
  ContactCtaVariant,
} from "../types/ContactCta.constants";
import { TContactCtaConfigProps } from "../types/ContactCta.config.types";

export const ContactCtaConfig: TContactCtaConfigProps = {
  parts: Object.values(ContactCtaParts),
  css: {
    variants: {
      [ContactCtaVariant.Default]: {
        Root: "",
      },
    },
  },
};
