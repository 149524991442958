export enum MainHeaderParts {
  Root = "Root",
  Nav = "Nav",
  Brand = "Brand",
  Time = "Time",
  MenuBtn = "MenuBtn",
  MenuBtnIcon = "MenuBtnIcon",

  MenuMobile = "MenuMobile",
  MenuMobileInner = "MenuMobileInner",
  MenuMobileBackdrop = "MenuMobileBackdrop",
}

export enum MainHeaderVariant {
  Default = "default",
}
