import React from "react";
import dashify from "dashify";
import { ISbComponents, SbBlokProps } from "@hybrbase/storyblok";

import { SbWorkCard } from "./elements";
import {
  SbHero,
  SbIntro,
  SbFeature,
  SbMediaFeature,
  SbIntroWithAccordion,
  SbIntroWithCarousel,
  SbBenefit,
  SbIntroWithBrands,
  SbContactCta,
  SbMediaFeatures,
  SbIntroWithFeatures,
} from "./sections";

const SbComponents: ISbComponents = {
  // elements
  work_card: SbWorkCard,

  // sections
  hero: SbHero,
  intro: SbIntro,
  media_feature: SbMediaFeature,
  features: SbFeature,
  intro_with_accordion: SbIntroWithAccordion,
  intro_with_carousel: SbIntroWithCarousel,
  intro_with_brands: SbIntroWithBrands,
  intro_with_features: SbIntroWithFeatures,
  benefit: SbBenefit,
  contact_cta: SbContactCta,
  media_features: SbMediaFeatures,

  // features: SbFeature,
};

export const SbComponent: React.FC<SbBlokProps> = ({ blok }) => {
  if (blok) {
    const componentName = dashify(blok.component);
    if (typeof SbComponents[componentName] !== "undefined") {
      const FoundComponent = SbComponents[componentName];
      return <FoundComponent blok={blok} />;
    } else {
      return <p>{componentName} is not yet defined.</p>;
    }
  }
  return null;
};
