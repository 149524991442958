import { getSbImageInfo } from "@/lib/storyblok";
import { storyblokEditable } from "@hybrbase/storyblok";
import { WorkCard } from "landing";
import { AspectRatioSize } from "layout";
import { useMemo } from "react";

export const SbWorkCard = ({ blok }) => {
  const { title, image_src, description, labels, link } = blok; // Component scheme

  const sbImage = useMemo(
    () => getSbImageInfo(image_src?.filename, AspectRatioSize.Square),
    [image_src?.filename]
  );

  return (
    <WorkCard
      title={title}
      description={description}
      labels={labels}
      image={{
        src: sbImage?.filename,
        alt: image_src?.alt,
        title: image_src?.title,

        width: sbImage.width,
        height: sbImage.height,
      }}
      link={{
        href: link?.url,
        isOpenNewTab: link?.target === "_blank",
      }}
      {...storyblokEditable(blok)}
    />
  );
};
