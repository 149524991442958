import {
  MainFooterParts,
  MainFooterVariant,
} from "../types/MainFooter.constants";
import { TMainFooterConfigProps } from "../types/MainFooter.config.types";

export const MainFooterConfig: TMainFooterConfigProps = {
  parts: Object.values(MainFooterParts),
  css: {
    variants: {
      [MainFooterVariant.Default]: {
        Root: "w-full",
        Container: "py-xxl sm:py-xxl my-0",
      },
    },
  },
};
