export enum GridParts {
  Root = "Root",
  ItemDebugLayer = "ItemDebugLayer",
}

export enum GridVariant {
  Default = "default",
  Custom = "custom",
}

export enum GridGap {
  Xs = "xs",
  Sm = "sm",
  Md = "md",
  Lg = "lg",
  Xl = "xl",
  None = "none",
}

export enum GridColumns {
  Four = "four",
  Three = "three",
  Twelve = "twelve",
  Custom = "custom",
}
