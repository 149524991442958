import { WorkCardParts, WorkCardVariant } from "../types/WorkCard.constants";
import { TWorkCardConfigProps } from "../types/WorkCard.config.types";

export const WorkCardConfig: TWorkCardConfigProps = {
  parts: Object.values(WorkCardParts),
  css: {
    variants: {
      [WorkCardVariant.Default]: {
        Root: "relative block rounded-lg",
      },
    },
  },
};
