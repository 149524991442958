import React from "react";
import { HTMLProps, cx, forwardRef, useCompConfig } from "@hybrbase/system";
import { MediaFeatureConfig } from "../styles/MediaFeature.config";
import { MediaFeatureVariant } from "../types/MediaFeature.constants";
import {
  Heading,
  HeadingSize,
  HeadingVariant,
  ImageProps,
  LinkFx,
  LinkFxProps,
  Text,
  VideoLayout,
  VideoProps,
} from "elements";
import {
  Section,
  SectionData,
  Container,
  TextContainer,
  Grid,
  Tile,
  AspectRatioSize,
  TileXSpace,
  AspectRatioLayout,
} from "layout";
import { BorderRadius, ThemeElement } from "@hybrbase/themes";
import dynamic from "next/dynamic";
import { TMediaFeatureConfigReturn } from "../types/MediaFeature.config.types";
const LoadableAspectRatioImage = dynamic(
  () => import("elements/src/aspect-ratio-image/src/parts/AspectRatioImage")
);
const LoadableVideo = dynamic(
  () => import("elements/src/video/src/parts/Video")
);

export interface MediaFeatureData extends SectionData {
  image?: ImageProps;
  video?: VideoProps;
  link?: LinkFxProps;
}

export interface MediaFeatureOptions {
  variant?: MediaFeatureVariant;
  theme?: ThemeElement;
  isVideo?: boolean;
  headingSize?: HeadingSize.FluidLg | HeadingSize.FluidMd;
  aspectRatioSize?: AspectRatioSize;
}

export interface MediaFeatureProps
  extends Omit<HTMLProps<"section">, keyof MediaFeatureData>,
    MediaFeatureOptions,
    MediaFeatureData {}

export const MediaFeature = forwardRef<MediaFeatureProps, "section">(
  (props, ref) => {
    const {
      variant = MediaFeatureVariant.Default,
      className,
      theme = ThemeElement.Default,
      title,
      text,
      image,
      link,
      video,
      isVideo,
      headingSize = HeadingSize.FluidMd,
      aspectRatioSize = AspectRatioSize.Square,
      ...rest
    } = props;

    const { styles }: TMediaFeatureConfigReturn = useCompConfig(
      MediaFeatureConfig,
      { variant }
    );

    return (
      <Section
        data-comp="media-feature"
        data-variant={variant}
        theme={theme}
        className={cx(styles.Root, className)}
        ref={ref}
        {...rest}
      >
        <Container>
          <Grid className="items-end">
            <Grid.Item className="flex-col-2">
              <Tile className="-mx-md sm:mx-0">
                {isVideo ? (
                  <LoadableVideo
                    {...video}
                    hasTogglePlayBtn={false}
                    layout={VideoLayout.Ratio}
                    autoPlay
                    muted
                    aspectRatio={{
                      size: aspectRatioSize,
                      layout: AspectRatioLayout.Cover,
                      radius: BorderRadius.Lg,
                    }}
                  />
                ) : (
                  <LoadableAspectRatioImage
                    image={image}
                    aspectRatio={{
                      size: aspectRatioSize,
                      layout: AspectRatioLayout.Cover,
                      radius: BorderRadius.Lg,
                    }}
                  />
                )}
              </Tile>
            </Grid.Item>
            <Grid.Item className="flex-col-2">
              <Tile spaceX={TileXSpace.None} className="sm:pr-xxl">
                <TextContainer>
                  <Heading
                    variant={HeadingVariant.Section}
                    size={headingSize}
                  >
                    {title}
                  </Heading>
                  <Text>{text}</Text>
                </TextContainer>
                {link?.href || link?.children ? (
                  <div className="flex justify-end sm:justify-start mt-xl sm:mb-xl">
                    <LinkFx {...link} href={link?.href} />
                  </div>
                ) : null}
              </Tile>
            </Grid.Item>
          </Grid>
        </Container>
      </Section>
    );
  }
);

MediaFeature.displayName = `MediaFeature`;

export default MediaFeature;
