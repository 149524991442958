import { ImageConfig } from "../styles/Image.config";
import { ImageLayout, ImageVariant } from "../types/Image.constants";
import { useImage } from "../utils/use-image";
import {
  cx,
  forwardRef,
  useCompConfig,
  ForwardRefComponent,
} from "@hybrbase/system";
import NextImage from "next/image";
import { ImageProps as NextImageProps } from "next/image";
import React, { useMemo } from "react";
import { shimmer, toBase64 } from "../utils/helper";
import { TImageConfigReturn } from "../types/Image.config.types";
import { BorderRadius } from "@hybrbase/themes";

export interface ImageData {
  /**
   * Fallback image `src` to show if image is loading or image fails.
   *
   */
  fallbackSrc?: string;
  /**
   * Alternative text, passed to the <img> tag. Required for accessibility.
   */
  fallbackAlt?: string;
}

export interface ImageOptions extends NextImageProps {
  variant?: ImageVariant;
  /**
   * Fallback element to show if image is loading or image fails.
   */
  fallbackElement?: React.ReactElement;
  isBlur?: boolean;
  customLayout?: ImageLayout;

  radius?: BorderRadius;
}
export interface ImageProps extends ImageOptions, ImageData {}

type ImageParts = ForwardRefComponent<"div", ImageProps>;

/**
 * When you use Image component and you're not certain of the source domain of the image (i.e. user input) use
 * make sure to use the `unoptimized` prop. Otherwise declare the domain of the image in the `next.config.js`
 * @see https://nextjs.org/docs/api-reference/next/image#domains
 */
export const Image: ImageParts = forwardRef<ImageProps, "div">((props, ref) => {
  const {
    variant = ImageVariant.Default,
    loading,
    src,
    customLayout = ImageLayout.Instrinsic,
    alt,
    onLoad,
    onError,
    crossOrigin,
    sizes,
    fallbackSrc,
    fallbackAlt,
    fallbackElement,
    unoptimized = true,
    className,
    isBlur,
    width = 700,
    height = 475,
    ...rest
  } = props;

  const shouldIgnore =
    loading != null ||
    fallbackSrc === undefined ||
    fallbackElement === undefined; // if the user doesn't provide any kind of fallback we should ignore it

  const status = useImage({
    src: src as string,
    onLoad,
    onError,
    crossOrigin,
    sizes,
    // if the user doesn't provide any kind of fallback we should ignore it
    ignoreFallback: shouldIgnore,
  });

  const { styles }: TImageConfigReturn = useCompConfig(ImageConfig, {
    variant,
    css: {
      customLayout,
    },
  });

  return useMemo(() => {
    const sharedProps = {
      unoptimized,
    };

    /**
     * If src isn't passed return null,
     */
    if (!src) return null;

    if (status !== "loaded") {
      /**
       * If user passed a custom fallback component,
       * let's render it here.
       */
      if (fallbackElement) return fallbackElement;

      if (fallbackSrc) {
        return (
          <div
            data-comp="image"
            data-variant={variant}
            className={cx(styles.Root, className)}
            ref={ref}
          >
            <NextImage
              src={fallbackSrc}
              alt={fallbackAlt}
              width={width}
              height={height}
              className={styles.Image}
              {...Object.assign(sharedProps, rest)}
            />
          </div>
        );
      }
    }

    return (
      <div
        data-comp="image"
        data-variant={variant}
        className={cx(styles.Root, className)}
        ref={ref}
      >
        <NextImage
          src={src}
          alt={alt}
          width={width}
          height={height}
          placeholder={isBlur ? "blur" : "empty"}
          blurDataURL={`data:image/svg+xml;base64,${toBase64(
            shimmer(width, height)
          )}`}
          className={styles.Image}
          {...Object.assign(sharedProps, rest)}
        />
      </div>
    );
  }, [
    variant,
    className,
    fallbackSrc,
    fallbackAlt,
    fallbackElement,
    ref,
    status,
    unoptimized,
    styles,
    src,
    alt,
    width,
    height,
    isBlur,
    rest,
  ]);
});

Image.displayName = `Image`;

export default Image;
