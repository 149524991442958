export enum AccordionParts {
  Root = "Root",
  Button = "Button",
  Panel = "Panel",
  Item = "Item",
}

export enum AccordionVariant {
  Default = "default",
}
