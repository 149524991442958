import { FeatureParts, FeatureVariant } from "../types/Feature.constants";
import { TFeatureConfigProps } from "../types/Feature.config.types";

export const FeatureConfig: TFeatureConfigProps = {
  parts: Object.values(FeatureParts),
  css: {
    variants: {
      [FeatureVariant.Default]: {
        Root: "",
        Grid: "mt-lg",
      },
    },
  },
};
