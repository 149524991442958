import { getStoryblokVersion, storyblokApi } from "@/lib/storyblok/client";
import { IncomingMessage } from "http";
import { getPageUrls } from "../get-page-urls";

type getSlugStoryProps = {
  language: string;
  storySlug: string;
  resolvedUrl: string;
  options?: { [key in string]?: any };
  req?: IncomingMessage;
};

// change pages folder to stories
export const getSlugStory = async ({
  language,
  storySlug,
  resolvedUrl,
  req,
  options,
}: // req,
getSlugStoryProps) => {
  const [content, headerContent, footerContent, pagePath] = await Promise.all([
    storyblokApi
      .get(`cdn/stories/${storySlug}`, {
        version: getStoryblokVersion,
        language,
        ...options,
      })
      .catch((error) => {
        console.log(error);
      }),
    storyblokApi.get(`cdn/stories/settings/main-header`, {
      version: getStoryblokVersion,
      language,
    }),
    storyblokApi.get(`cdn/stories/settings/main-footer`, {
      version: getStoryblokVersion,
      language,
    }),
    req && resolvedUrl
      ? getPageUrls({
          req,
          slug: resolvedUrl,
        })
      : null,
  ]);

  return {
    pagePath,
    story: content ? content?.data?.story : {},
    headerStory: headerContent ? headerContent?.data?.story : {},
    footerStory: footerContent ? footerContent?.data?.story : {},
  };
};
