import { IntroVariant } from "../../../index";

import { IntroWithAccordionVariant } from "../types/IntroWithAccordion.constants";

export const introWithAccordionFactory = (
  variant: IntroWithAccordionVariant
) => {
  switch (variant) {
    case IntroWithAccordionVariant.Static:
      return {
        intro: {
          variant: IntroVariant.One,
        },
        accordion: {
          allowTogle: false,
        },
      };
    default:
      return {
        intro: {
          variant: IntroVariant.Three,
        },
        accordion: {
          allowTogle: true,
        },
      };
  }
};
