import { IntroParts, IntroVariant } from "../types/Intro.constants";
import { TIntroConfigProps } from "../types/Intro.config.types";

export const IntroConfig: TIntroConfigProps = {
  parts: Object.values(IntroParts),
  css: {
    variants: {
      [IntroVariant.Default]: {
        GridItemOne: "sm:flex-offset-1",
        GridItemTwo: "sm:flex-offset-1",
      },
      [IntroVariant.One]: {},
      [IntroVariant.Two]: {
        TileOne: "flex flex-col items-center",
      },
      [IntroVariant.Three]: {
        GridItemOne: "sm:flex-col-4",
        GridItemTwo: "sm:flex-offset-1",
      },
      [IntroVariant.Four]: {
        GridItemOne: "sm:flex-col-2",
        GridItemTwo: "sm:flex-col-2",
        TileOne: "sm:pr-0",
        TileTwo: "sm:pl-0",
      },
    },
  },
};
