export enum IntroWithAccordionParts {
  Root = "Root",
  Accordion = "Accordion",
  AccordionItem = "AccordionItem",
  AccordionButton = "AccordionButton",
  HeadingWrapper = "HeadingWrapper",
  Counter = "Counter",
  AccordionButtonIconWrapper = "AccordionButtonIconWrapper",
  TextContainer = "TextContainer",
  TextContainerTwo = "TextContainerTwo",
  AccordionSubItems = "AccordionSubItems",
  GridItemOne = "GridItemOne",
  GridItemTwo = "GridItemTwo",
}

export enum IntroWithAccordionVariant {
  Default = "default",
  Static = "static",
}
