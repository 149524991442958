import { cx } from "@hybrbase/system";
import { MainFooter, MainHeader } from "sections";
import { FC, useEffect, useMemo } from "react";
import {
  SbBlokProps,
  storyblokEditable,
  useStoryblok,
} from "@hybrbase/storyblok";
import Lenis from "@studio-freight/lenis";
import { ThemeProvider, PageProvider, PageContextProps } from "@/providers";
import { Theme } from "@hybrbase/themes";
import {
  // useAssetsLoaded,
  useHashScrollTo,
  useRouterPageTransition,
  useSbPreview,
  useStorySeoPage,
} from "@/hooks";
// import { isBrowser } from "utils";
import { LazyMotion, domAnimation, AnimatePresence, m } from "framer-motion";
import { globalConfig } from "@/config";
import { useRouter } from "next/router";
import { useFrame, useSafeLayoutEffect } from "hooks";
import localFont from "@next/font/local";
import usePageStore from "@/stores/page";
import { SeoHead } from "@/components";
import { LazyHydrate, LazyHydrationMode } from "@hybrbase/lazy-hydration";
import { Button, ButtonSize, CookieBanner } from "elements";
import { PopupModal } from "react-calendly";

const helveticaNowDisplay = localFont({
  variable: "--hb-font-family-primary",
  src: [
    {
      path: "../../../public/fonts/HelveticaNowDisplay/HelveticaNowDisplay-Regular.woff2",
      weight: "400",
      style: "normal",
    },
    {
      path: "../../../public/fonts/HelveticaNowDisplay/HelveticaNowDisplay-Bold.woff2",
      weight: "700",
      style: "bold",
    },
  ],
});

const helveticaNowDisplayExtended = localFont({
  variable: "--hb-font-family-secondary",
  src: [
    {
      path: "../../../public/fonts/HelveticaNowDisplay/HelveticaNowDisplay-Condensed-Bold.woff2",
      weight: "700",
      style: "bold",
    },
  ],
});

export interface PageProps {
  story: SbBlokProps;
  headerStory?: SbBlokProps;
  footerStory?: SbBlokProps;
  pageProps?: any;
  pageContext?: PageContextProps;
  children?: React.ReactNode;
  theme?: Theme;
  isDisabledSeo?: boolean;
}

export const Page: FC<PageProps> = ({
  story,
  headerStory,
  footerStory,
  children,
  pageProps,
  pageContext,
  theme,
}) => {
  const {
    resolvedUrl,
    seo,
    theme: pageTheme,
    name: pageName,
  }: PageContextProps = pageContext;
  const dataFooterStory = useStoryblok(footerStory);
  const dataHeaderStory = useStoryblok(headerStory);

  const [
    lenis,
    setLenis,
    isMenuOpen,
    setMenuOpen,
    isCalendlyOpen,
    setCalendlyOpen,
  ] = usePageStore((state) => [
    state.lenis,
    state.setLenis,
    state.isMenuOpen,
    state.setMenuOpen,
    state.isCalendlyOpen,
    state.setCalendlyOpen,
  ]);

  const isPageTransition = usePageStore(
    ({ isPageTransition }) => isPageTransition
  );
  const isSbPreview = useSbPreview();

  const isScreenLocked = usePageStore(({ isScreenLocked }) => isScreenLocked);

  const router = useRouter();

  useRouterPageTransition();

  // const { isLoaded } = useAssetsLoaded();

  const [storySeo] = useStorySeoPage({
    story,
    resolvedUrl,
    seo,
  });

  const headerLinksProps = useMemo(() => {
    return {
      navigationLinks: dataHeaderStory?.content?.navigation_links?.map(
        (item) => {
          return {
            href: `/${item.link_href.cached_url}`,
            children: item.link_label,
          };
        }
      ),
    };
  }, [dataHeaderStory]);

  const footerLinksProps = useMemo(() => {
    return {
      navigationLinks: dataFooterStory?.content?.navigation_links?.map(
        (item) => {
          return {
            href: `/${item.link_href.cached_url}`,
            children: item.link_label,
          };
        }
      ),
      privacyLinks: dataFooterStory?.content?.privacy_links?.map((item) => {
        return {
          href: `/${item.link_href.cached_url}`,
          children: item.link_label,
        };
      }),
    };
  }, [dataFooterStory]);

  // useEffect(() => {
  //   if (isBrowser) {
  //     // set lock
  //     document.body.classList.toggle("overflow-hidden", !isLoaded);
  //   }
  // }, [isLoaded]);

  useSafeLayoutEffect(() => {
    window.scrollTo(0, 0);
    const init = new Lenis({
      duration: 1.2,
      easing: (t) => (t === 1 ? 1 : 1 - Math.pow(2, -10 * t)), // https://easings.net/en#easeOutExpo
      direction: "vertical", // vertical, horizontal
      gestureDirection: "vertical", // vertical, horizontal, both
      smooth: true,
      smoothTouch: false,
      touchMultiplier: 2,
    });
    setLenis(init);

    return () => {
      init.destroy();
      setLenis(null);
    };
  }, []);

  useFrame((time) => {
    lenis?.raf(time);
  });

  const { headerRef } = useHashScrollTo();

  useEffect(() => {
    if (!isScreenLocked && !isCalendlyOpen && !isMenuOpen) {
      lenis?.start();
      document.body.classList.toggle("overflow-hidden", false);
    } else {
      lenis?.stop();
      document.body.classList.toggle("overflow-hidden", true);
    }
  }, [lenis, isCalendlyOpen, isScreenLocked, isMenuOpen]);

  return (
    <ThemeProvider themes={[Theme.Default, Theme.Dark]} defaultTheme={theme}>
      {/* If the out-in mode is selected, the SwitchTransition waits until the old child leaves and then inserts a new child. */}
      <PageProvider
        {...pageContext}
        isPageTransition={isPageTransition}
        seo={storySeo}
      >
        {seo ? <SeoHead {...storySeo} /> : null}

        <LazyMotion features={domAnimation}>
          <div
            data-page={pageName}
            data-theme-page={pageTheme || theme}
            className={cx(
              helveticaNowDisplay.variable,
              helveticaNowDisplayExtended.variable
            )}
          >
            {headerStory ? (
              <MainHeader
                theme={pageTheme || theme}
                isPageTransition={isPageTransition}
                ref={headerRef}
                isMenuOpen={isMenuOpen}
                setMenuOpen={setMenuOpen}
                {...headerLinksProps}
              >
                <Button
                  title="Schedule a call"
                  size={ButtonSize.Sm}
                  className="text-[0.5em] sm:text-xs"
                  onClick={() => setCalendlyOpen(true)}
                >
                  Schedule a call
                </Button>
              </MainHeader>
            ) : null}

            <AnimatePresence>
              <m.main
                {...pageProps}
                className={"pt-lg sm:pt-xxl"}
                key={router.asPath}
                initial="hide"
                animate="show"
                exit="hide"
                variants={pageTransitionAnim}
              >
                {children}
              </m.main>
              {/* <div className="loading-screen fixed top-0 left-0 right-0 bottom-0 p-md w-full h-full z-overlay bg-primary">
              <div className="la-ball-clip-rotate">
                <div></div>
              </div>
            </div> */}
            </AnimatePresence>
            {footerStory ? (
              <MainFooter
                {...storyblokEditable(dataFooterStory?.content)}
                {...footerLinksProps}
              />
            ) : null}

            <PopupModal
              url={"https://calendly.com/hybrbase/30min"}
              // pageSettings={this.props.pageSettings}
              // utm={this.props.utm}
              // prefill={this.props.prefill}
              onModalClose={() => setCalendlyOpen(false)}
              open={isCalendlyOpen}
              /*
               * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
               * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
               */
              rootElement={
                typeof window !== "undefined"
                  ? document.getElementById("__next")
                  : null
              }
            />
            {false && !isSbPreview ? (
              <LazyHydrate mode={LazyHydrationMode.WHEN_IDLE}>
                <CookieBanner />
              </LazyHydrate>
            ) : null}
          </div>
        </LazyMotion>
      </PageProvider>
    </ThemeProvider>
  );
};

const pageTransitionAnim = {
  show: {
    opacity: 1,
    transition: {
      duration: globalConfig.pageTransitionDuration / 1000,
      delay: 0.2,
      ease: "linear",
      when: "beforeChildren",
    },
  },
  hide: {
    opacity: 0,
    transition: {
      duration: globalConfig.pageTransitionDuration / 1000,
      ease: "linear",
      when: "beforeChildren",
    },
  },
};
