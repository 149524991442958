import { createContext } from "@hybrbase/system";
import { UseVideoReturn } from "./use-video";

const [VideoProvider, useVideoContext] = createContext<UseVideoReturn>({
  name: "VideoContext",
  errorMessage:
    "useVideoContext: `context` is undefined. Seems you forgot to wrap all Video's components within <Video />",
});

export { VideoProvider, useVideoContext };
