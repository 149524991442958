import {
  MainHeaderParts,
  MainHeaderVariant,
} from "../types/MainHeader.constants";
import { TMainHeaderConfigProps } from "../types/MainHeader.config.types";

export const MainHeaderConfig: TMainHeaderConfigProps = {
  parts: Object.values(MainHeaderParts),
  css: {
    variants: {
      [MainHeaderVariant.Default]: {
        Root: "z-fixed top-0 left-0 right-0 px-md min-h-lg sm:min-h-xxl overflow-hidden transition-none",
        Brand:
          "inline-block w-[100px] text-inherit transition-colors duration-fast -mb-xxxxs",
        Nav: "flex justify-between h-full min-h-inherit items-center sm:px-xxl",
        MenuBtn: "cursor-pointer relative",
        MenuBtnIcon: "text-contrast-higher",
        MenuMobile:
          "fixed top-lg left-0 w-full h-[calc(100%_-_theme(space.lg))] z-overlay flex flex-col will-change-transform sm:hidden",
        MenuMobileInner:
          "flex flex-col flex-grow h-[inherit] px-md py-md overflow-y-scroll",
      },
    },
  },
};
