import { useCompConfig } from "@hybrbase/system";
import { ThemeElement } from "@hybrbase/themes";
import { MainHeaderVariant } from "../types/MainHeader.constants";
import { TMainHeaderConfigReturn } from "../types/MainHeader.config.types";
import { MainHeaderConfig } from "../styles/MainHeader.config";

export interface UseMainHeaderProps {
  variant?: MainHeaderVariant;
  theme?: ThemeElement;
}

/**
 * MainHeader hook that manages all the logic
 * and returns prop getters, state and actions.
 *
 * @param props
 */
export const useMainHeader = (props: UseMainHeaderProps) => {
  const { variant, theme } = props;

  const { styles }: TMainHeaderConfigReturn = useCompConfig(MainHeaderConfig, {
    variant,
    css: { theme },
  });

  return { variant, styles };
};

export type UseMainHeaderReturn = ReturnType<typeof useMainHeader>;
