import { Intro, IntroVariant } from "landing";
import { storyblokEditable } from "@hybrbase/storyblok";
import { Grid, GridColumns, Tile } from "layout";
import { Icon, IconSize } from "elements";
import { uid } from "react-uid";

export const SbIntroWithBrands = ({ blok }) => {
  const { title, subtitle, items } = blok; // Component scheme
  return (
    <Intro
      variant={IntroVariant.Default}
      title={title}
      subtitle={subtitle}
      {...storyblokEditable(blok)}
    >
      <Grid columns={GridColumns.Twelve} className="max-w-md mx-auto">
        {items?.map((item, index) => (
          <Grid.Item
            key={uid(index)}
            className="flex items-center justify-center flex-col-2 sm:flex-col-4"
          >
            <Tile>
              <Icon name={item.brand} size={IconSize.Xxl} />
            </Tile>
          </Grid.Item>
        ))}
      </Grid>
    </Intro>
  );
};
