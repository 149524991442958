import React from "react";
import { VideoVariant } from "../types/Video.constants";
import { cx } from "@hybrbase/system";
import { cssVars } from "@hybrbase/themes";
import { useVideoContext } from "../utils";

export interface VideoTimelineData {
  inputAriaLabel?: string;
}

export interface VideoTimelineOptions {
  step?: number;
  stepFast?: number;
}
export interface VideoTimelineProps
  extends VideoTimelineOptions,
    VideoTimelineData {
  /**
   * Use the className prop in Video to add classes
   */
  className?: string;
  children?: React.ReactNode;
}

/**
 *
 */
export const VideoTimeline: React.FC<VideoTimelineProps> = ({
  className,
  step = 0.1,
  stepFast = 5,
  inputAriaLabel = "Seek  Video",
}) => {
  const inputRef = React.useRef(null);

  const {
    currentTime: currTime,
    setTimeUpdate,
    duration,
    styles,
    variant,
  } = useVideoContext();

  const [currentTime, seCurrentTime] = React.useState(currTime);
  const [currStep, setCurrStep] = React.useState(step);

  React.useEffect(() => {
    seCurrentTime(currTime);
  }, [currTime]);

  function onChange() {
    seCurrentTime(parseFloat(inputRef.current?.value ?? "0"));
    setTimeUpdate({
      currentTime: inputRef.current?.value,
      duration: duration,
    });
  }

  const { rootStyle } = React.useMemo(() => {
    switch (variant) {
      case VideoVariant.Default:
      default:
        return {
          rootStyle: {
            "--video-timeline-height": "3px",
            "--video-timeline-thumb-radius": "50rem",
            "--video-timeline-thumb-size": "1rem",
            "--video-timeline-thumb-color": cssVars.colors.accent,
            "--video-timeline-progress-color": cssVars.colors.black,
            "--video-timeline-track-color": cssVars.colors.accent,
          } as JSX.IntrinsicElements["style"],
        };
    }
  }, [variant]);

  return (
    <div
      style={rootStyle}
      className={cx("video-timeline", styles.Timeline, className)}
    >
      <div
        className={cx("video-timeline-progress", styles.TimelineProgress)}
        style={{ width: (currentTime / duration) * 100 + "%" }}
      />
      <input
        className={cx("video-timeline-input-range", styles.TimelineInputRange)}
        type="range"
        ref={inputRef}
        min="0"
        max={duration}
        step={currStep}
        onChange={onChange}
        value={currentTime}
        onKeyDown={() => setCurrStep(stepFast)}
        onKeyUp={() => setCurrStep(step)}
        onBlur={() => setCurrStep(step)}
        aria-label={inputAriaLabel}
        title={inputAriaLabel}
      />
    </div>
  );
};

VideoTimeline.displayName = `VideoTimeline`;
