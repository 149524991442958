import { useEffect, useRef, useState } from "react";
import { isBrowser } from "utils";
import FontFaceObserver from "fontfaceobserver";
import imagesLoaded from "imagesloaded";
import { globalConfig } from "@/config";
import usePageStore from "@/stores/page";

export const useAssetsLoaded = (
  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  minLoadDuration: number = globalConfig.pageTransitionDuration * 2,
  exitLoadDuration: number = globalConfig.pageTransitionDuration
) => {
  const isPageTransition = usePageStore(
    ({ isPageTransition }) => isPageTransition
  );

  const [isLoaded, setIsLoaded] = useState(false);
  const [canAnimateAfterLoaded, setCanAnimateAfterLoaded] = useState(false);
  const [startTimeLoad, setStartTimeLoad] = useState(null);
  const [endTimeLoad, setEndTimeLoad] = useState(null);

  const initLoadedTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  const initAnimateTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    if (!isBrowser) return;

    setStartTimeLoad(performance.now());

    const fontPrimary = new FontFaceObserver("HelveticaNowDisplay");
    const imgLoaded = imagesLoaded(
      document.querySelector("body"),
      { background: true },
      null
    );

    Promise.all([fontPrimary.load(null, 2000), imgLoaded.on("done")])
      .then(
        () => {
          let timeline = 0;

          setEndTimeLoad(performance.now());

          if (endTimeLoad - startTimeLoad <= minLoadDuration) {
            timeline = endTimeLoad - startTimeLoad;
          }

          initLoadedTimeout.current = setTimeout(() => {
            setIsLoaded(true);
          }, minLoadDuration - timeline);

          // set time to anim and remove the loading screen
          initAnimateTimeout.current = setTimeout(() => {
            setCanAnimateAfterLoaded(true);
          }, minLoadDuration - timeline + exitLoadDuration);
        },
        () => {
          console.warn("Assets were loading too long (over 2000ms)");
        }
      )
      .catch((err) => {
        console.warn("Some critical assets are not available:", err);
      });

    return () => {
      if (initLoadedTimeout.current) clearTimeout(initLoadedTimeout.current);
      if (initAnimateTimeout.current) clearTimeout(initAnimateTimeout.current);
    };
  }, [
    isPageTransition,
    canAnimateAfterLoaded,
    minLoadDuration,
    exitLoadDuration,
  ]);

  return { isLoaded, canAnimateAfterLoaded };
};
