import { FontFamily, FontWeight } from "@hybrbase/themes";
import { TextContrast, TextParts, TextSize } from "../types/Text.constants";
import { TTextConfigProps } from "../types/Text.config.types";

export const TextConfig: TTextConfigProps = {
  parts: Object.values(TextParts),
  css: {
    variants: {},
    props: {
      size: {
        [TextSize.FluidSm]: {
          Root: "text-fluid-sm",
        },
        [TextSize.Fluid]: {
          Root: "text-fluid",
        },
        [TextSize.FluidXs]: {
          Root: "text-fluid-xs",
        },
        [TextSize.Md]: {
          Root: "text-md",
        },
        [TextSize.Sm]: {
          Root: "text-xs sm:text-sm",
        },
        [TextSize.Xs]: {
          Root: "text-xs",
        },
        [TextSize.Base]: {
          Root: "text-base",
        },
      },
      contrast: {
        [TextContrast.High]: {
          Root: "text-contrast-high",
        },
        [TextContrast.Medium]: {
          Root: "text-contrast-medium",
        },
        [TextContrast.Low]: {
          Root: "text-contrast-low",
        },
        [TextContrast.Lower]: {
          Root: "text-contrast-lower",
        },
      },
      weight: {
        [FontWeight.Regular]: {
          Root: "font-regular",
        },
        [FontWeight.Bold]: {
          Root: "font-bold",
        },
      },
      font: {
        [FontFamily.Primary]: {
          Root: "font-primary",
        },
        [FontFamily.Secondary]: {
          Root: "font-secondary",
        },
      },
    },
  },
};
