/* eslint-disable @typescript-eslint/no-unused-vars */
import { useSafeLayoutEffect } from "hooks";
import { useEffect, useRef, useState } from "react";
import { UseVideoProps } from "./use-video";
import React from "react";
import { isBrowser } from "utils";
import { useVideoContext } from "./video-context";

export interface UseVideoControlsOptions {
  hasControls?: boolean;
  hasShowControlsOnLoad?: boolean;
  controlsTimeout?: number;
  autoPlayDelay?: number;
}
export interface UseVideoControlsProps
  extends UseVideoControlsOptions,
    UseVideoProps {}

/**
 * Video hook that manages all the logic
 * and returns prop getters, state and actions.
 *
 * @param props
 */
export const useVideoControls = (props: UseVideoControlsProps) => {
  const {
    hasControls,
    hasShowControlsOnLoad = true,
    controlsTimeout = 1.5,
    autoPlayDelay,
  } = props;

  const [isShowingControls, setIsShowingControls] = useState(
    hasShowControlsOnLoad
  );

  const autoPlayTimeout = useRef(null);
  const hideControlsTimeout = useRef(null);

  const {
    isPlaying,
    isAutoplay,
    pause,
    play,
    handleOnTimeUpdate,
    progress,
    duration,
    currentTime,
    isMuted,
    toggleMute,
    togglePlay,
    toggleCaptions,
    toggleFullscreen,
    isShowingCaptions,
    isMouseHovered,
    isFullScreen,
    formatedTime,
  } = useVideoContext();

  const isFullscreenAPISupported = React.useMemo(() => {
    if (!isBrowser) return;
    const document: any = window.document;

    return Boolean(
      document.body.requestFullscreen ||
        document.body.mozRequestFullScreen ||
        document.body.webkitRequestFullscreen ||
        document.body.webkitEnterFullScreen ||
        document.body.msRequestFullscreen
    );
  }, []);

  // controls
  const showControls = () => {
    !isShowingControls && setIsShowingControls(true);
  };

  const hideControls = () => {
    isShowingControls && setIsShowingControls(false);
  };

  const setHideControlsTimeout = () => {
    clearHideControlsTimeout();
    hideControlsTimeout.current = setTimeout(() => {
      isPlaying && hideControls();
    }, controlsTimeout * 1000);
  };

  const clearHideControlsTimeout = () => {
    hideControlsTimeout.current && clearTimeout(hideControlsTimeout.current);
  };

  const clearAutoPlayTimeout = () => {
    autoPlayTimeout.current && clearTimeout(autoPlayTimeout.current);
  };

  const handleOnMouseMove = () => {
    if (hasControls) {
      showControls();
      isPlaying && setHideControlsTimeout();
    }
  };

  const handleOnControlsFocus = () => {
    if (hasControls) {
      showControls();
      clearHideControlsTimeout();
    }
  };

  const handleOnControlsBlur = () => {
    if (hasControls) {
      clearHideControlsTimeout();
      isPlaying && setHideControlsTimeout();
    }
  };

  useSafeLayoutEffect(() => {
    if (hasControls) {
      hasShowControlsOnLoad ? setHideControlsTimeout() : hideControls();
    }

    if (isAutoplay) {
      autoPlayTimeout.current = setTimeout(() => {
        play();
        clearAutoPlayTimeout();
      }, autoPlayDelay * 1000);
    }

    return () => {
      pause();
      clearAutoPlayTimeout();
      clearHideControlsTimeout();
    };
  }, []);

  useEffect(() => {
    if (isMouseHovered) {
      handleOnMouseMove();
    } else {
      hasControls && setHideControlsTimeout();
    }
  }, [isMouseHovered, hasControls]);

  useEffect(() => {
    if (isPlaying) {
      play();
      hasControls && setHideControlsTimeout();
    } else {
      pause();
      if (hasControls && progress) {
        clearHideControlsTimeout();
        showControls();
      }
    }
  }, [isPlaying, hasControls]);

  return {
    isShowingControls,
    isShowingCaptions,
    isMuted,
    isPlaying,
    isFullScreen,
    isFullscreenAPISupported,
    handleOnTimeUpdate,
    handleOnControlsBlur,
    handleOnControlsFocus,
    duration,
    currentTime,
    formatedTime,
    toggleMute,
    togglePlay,
    toggleFullscreen,
    toggleCaptions,
  };
};

export type UseVideoControlsReturn = ReturnType<typeof useVideoControls>;
