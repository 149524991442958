import {
  getImageService,
  getRatio,
  hostRegex,
  isValidImageHostname,
} from "@/utils";
import { AspectRatioSize } from "layout";

export const getSbImageInfo = (src: string, ratioSize?: AspectRatioSize) => {
  if (
    !src &&
    !isValidImageHostname(src, hostRegex) &&
    src?.split("/")[5]?.split("x").length === 2 // ensure it return width and height dimenions
  )
    return;

  const [originalWidth, originalHeight] = src?.split("/")[5]?.split("x") || "";

  const ratioHeight = ratioSize
    ? getRatio(ratioSize, Number(originalWidth)).height
    : Number(originalHeight);

  return {
    filename: getImageService({
      src,
      param:
        originalWidth || originalHeight
          ? `/${originalWidth || 0}x${ratioHeight || 0}/smart`
          : null,
    }),
    format: src?.split(".").pop(),
    width: Number(originalWidth),
    height: ratioHeight,
  };
};
