import { Intro, IntroVariant } from "landing";
import { storyblokEditable } from "@hybrbase/storyblok";
import { Carousel } from "layout";
import { uid } from "react-uid";
import { SbComponent } from "../SbComponent";
import { renderRichText } from "@hybrbase/storyblok";
import { usePageContext } from "@/providers";
// import { useMemo } from "react";

export const SbIntroWithCarousel = ({ blok }) => {
  const { title, subtitle, link_label, theme, link_href, items } = blok; // Component scheme
  const { theme: pageTheme } = usePageContext(); // solve and refactor this later

  return (
    <Intro
      title={renderRichText(title)}
      variant={IntroVariant.One}
      theme={pageTheme || theme}
      subtitle={subtitle}
      link={{
        href: link_href?.cached_url,
        isOpenNewTab: link_href?.target === "_blank",
        children: link_label,
      }}
      {...storyblokEditable(blok)}
    >
      <Carousel>
        {({ scrollPrev, scrollNext }) => {
          return (
            <>
              <Carousel.Slides className="work-cards-carousel space-x-md">
                {items?.map((item, index) => (
                  <div
                    className={`slide-item-${index} flex flex-col min-w-[90vw] xs:min-w-[50vw]`}
                    key={uid(index)}
                  >
                    <SbComponent blok={item} key={item._uid} />
                  </div>
                ))}
              </Carousel.Slides>
              <div className="flex space-x-sm ml-md sm:ml-0 pt-fluid-md-to-lg">
                <button
                  onClick={scrollPrev}
                  className="font-secondary font-bold uppercase text-sm"
                >
                  Previous
                </button>
                <span>{"/"}</span>
                <button
                  onClick={scrollNext}
                  className="font-secondary font-bold uppercase text-sm"
                >
                  Next
                </button>
              </div>
            </>
          );
        }}
      </Carousel>
    </Intro>
  );
};
