import {
  AspectRatioLayout,
  AspectRatioParts,
  AspectRatioPosition,
  AspectRatioSize,
  AspectRatioVariant,
} from "../types/AspectRatio.constants";
import { TAspectRatioConfigProps } from "../types/AspectRatio.config.types";
import { BorderRadius } from "@hybrbase/themes";

export const AspectRatioConfig: TAspectRatioConfigProps = {
  parts: Object.values(AspectRatioParts),
  css: {
    variants: {
      [AspectRatioVariant.Default]: {
        Root: "relative block overflow-hidden",
        Content: "absolute max-w-none min-h-full min-w-full w-full transform",
      },
    },
    props: {
      layout: {
        [AspectRatioLayout.Contain]: {
          Root: "aspect-ratio-contain",
        },
        [AspectRatioLayout.Cover]: {
          Root: "aspect-ratio-cover w-full",
        },
      },
      size: {
        [AspectRatioSize.Square]: { Root: "aspect-square" },
        [AspectRatioSize.SquareToSixteenDivideByNine]: {
          Root: "aspect-square xs:aspect-16/9",
        },
        [AspectRatioSize.TwoDivideByOne]: { Root: "aspect-2/1" },
        [AspectRatioSize.TwoDivideByThree]: { Root: "aspect-2/3" },
        [AspectRatioSize.ThreeDivideByOne]: { Root: "aspect-3/1" },
        [AspectRatioSize.ThreeDivideByTwo]: { Root: "aspect-3/2" },
        [AspectRatioSize.FourDivideByThree]: { Root: "aspect-4/3" },
        [AspectRatioSize.SixteenDivideByNine]: { Root: "aspect-16/9" },
        [AspectRatioSize.SixteenDivideByTen]: { Root: "aspect-16/10" },
        [AspectRatioSize.TwentyOneDivideByNine]: { Root: "aspect-21/9" },
      },
      radius: {
        [BorderRadius.Sm]: {
          Root: "rounded-sm",
        },
        [BorderRadius.Md]: {
          Root: "rounded-md",
        },
        [BorderRadius.Lg]: {
          Root: "rounded-lg",
        },
        [BorderRadius.Full]: {
          Root: "rounded-full",
        },
      },
      position: {
        [AspectRatioPosition.LeftTop]: {
          Content: "top-0 left-0",
        },
        [AspectRatioPosition.LeftCenter]: {
          Content: "top-1/2 left-0 -translate-y-1/2",
        },
        [AspectRatioPosition.LeftBottom]: {
          Content: "bottom-0 left-0",
        },
        [AspectRatioPosition.CenterTop]: {
          Content: "top-0 left-1/2 -translate-x-1/2",
        },
        [AspectRatioPosition.CenterCenter]: {
          Content: "top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2",
        },
        [AspectRatioPosition.CenterBottom]: {
          Content: "bottom-0 left-1/2 -translate-x-1/2",
        },
        [AspectRatioPosition.RightTop]: {
          Content: "top-0 right-0",
        },
        [AspectRatioPosition.RightCenter]: {
          Content: "top-1/2 right-0 -translate-y-1/2",
        },
        [AspectRatioPosition.RightBottom]: {
          Content: "bottom-0 right-0",
        },
      },
    },
  },
};
