import { IconName, IconParts, IconSize, IconVariant } from "./Icon.constants";
import dynamic from "next/dynamic";

export const IconsMap: Record<IconName, any> = {
  [IconName.HybrbaseSymbol]: dynamic(() => import("../loader/HybrbaseSymbol")),
  [IconName.HybrbaseType]: dynamic(() => import("../loader/HybrbaseType")),
  [IconName.Twitter]: dynamic(() => import("../loader/Twitter")),
  [IconName.Instagram]: dynamic(() => import("../loader/Instagram")),
  [IconName.Linkedin]: dynamic(() => import("../loader/Linkedin")),

  [IconName.Plus]: dynamic(() => import("../loader/Plus")),
  [IconName.Hybrbase]: dynamic(() => import("../loader/Hybrbase")),
  [IconName.Sun]: dynamic(() => import("../loader/Sun")),
  [IconName.Moon]: dynamic(() => import("../loader/Moon")),
  [IconName.ArrowRight]: dynamic(() => import("../loader/ArrowRight")),
  [IconName.Play]: dynamic(() => import("../loader/Play")),
  [IconName.PlayOutline]: dynamic(() => import("../loader/PlayOutline")),
  [IconName.Muted]: dynamic(() => import("../loader/Muted")),
  [IconName.Unmuted]: dynamic(() => import("../loader/Unmuted")),
  [IconName.Pause]: dynamic(() => import("../loader/Pause")),
  [IconName.EnterFullscreen]: dynamic(
    () => import("../loader/EnterFullscreen")
  ),
  [IconName.ExitFullscreen]: dynamic(() => import("../loader/ExitFullscreen")),
  [IconName.CaptionsOn]: dynamic(() => import("../loader/CaptionsOn")),
  [IconName.CaptionsOff]: dynamic(() => import("../loader/CaptionsOff")),
};

export type TIconParts = {
  [part in IconParts]?: string;
};

export type TIconCssProps = {
  size: {
    [size in IconSize]?: TIconParts;
  };
};

export interface TIconConfigProps {
  parts: IconParts[];
  css: {
    variants: {
      [variant in IconVariant]?: TIconParts & { props?: TIconCssProps };
    };
    props?: TIconCssProps;
  };
}

export type TIconConfigReturn = { styles: TIconParts };
