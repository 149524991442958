import {
  IntroWithFeaturesParts,
  IntroWithFeaturesVariant,
} from "../types/IntroWithFeatures.constants";
import { TIntroWithFeaturesConfigProps } from "../types/IntroWithFeatures.config.types";

export const IntroWithFeaturesConfig: TIntroWithFeaturesConfigProps = {
  parts: Object.values(IntroWithFeaturesParts),
  css: {
    variants: {
      [IntroWithFeaturesVariant.Default]: {
        InnerGrid:
          "py-fluid-lg-to-xxxl border-b-1 border-solid border-contrast-high",
        TextContainer: "max-w-sm",
        TextContainerTwo: "max-w-sm",
        HeadingWrapper: "flex items-center gap-sm sm:gap-md",
        Counter: "text-sm sm:text-base",
      },
      [IntroWithFeaturesVariant.Steps]: {
        InnerGrid:
          "py-fluid-lg-to-xxxl border-b-1 border-solid border-contrast-high",
        TextContainer: "max-w-sm",
        TextContainerTwo: "max-w-sm sm:mt-md",
        HeadingWrapper: "flex items-center gap-sm sm:gap-md",
        Counter: "text-sm sm:text-base",
        Text: "mt-xl",
      },
    },
  },
};
