import {
  assureTrailingSlash,
  getLanguageAlternates,
  stripHomePath,
  stripQueryPath,
} from "@/utils";
import { IncomingMessage } from "http";
import absoluteUrl from "next-absolute-url";

type TPageUrls = {
  slug: string;
  req: IncomingMessage;
};

export async function getPageUrls({ slug, req }: TPageUrls) {
  const { origin } = absoluteUrl(req);

  const canonicalUrl = stripQueryPath(
    assureTrailingSlash(origin + stripHomePath(slug))
  );

  const languageAlternates = await getLanguageAlternates(
    stripHomePath(slug),
    req
  );

  return {
    languageAlternates,
    canonicalUrl,
  };
}
