import { Feature } from "landing";
import { storyblokEditable } from "@hybrbase/storyblok";
import { SbCard } from "../elements";
import { uid } from "react-uid";

const Components = {
  card: SbCard,
};

export const SbFeature = ({ blok }) => {
  const { title, subtitle, components } = blok; // Component scheme
  return (
    <Feature title={title} subtitle={subtitle} {...storyblokEditable(blok)}>
      {components?.map((item, index) => {
        if (typeof Components[item.component] !== "undefined") {
          const FoundComponent = Components[item.component];
          return <FoundComponent key={uid(index)} blok={item} />;
        } else {
          return <p key={uid(index)}>{item.component} is not yet defined.</p>;
        }
      })}
    </Feature>
  );
};
