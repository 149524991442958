import React from "react";
import { HTMLProps, cx, forwardRef, useCompConfig } from "@hybrbase/system";
import { IntroWithFeaturesConfig } from "../styles/IntroWithFeatures.config";
import { IntroWithFeaturesVariant } from "../types/IntroWithFeatures.constants";
import { TIntroWithFeaturesConfigReturn } from "../types/IntroWithFeatures.config.types";
import {
  Grid,
  GridGap,
  GridItemSize,
  SectionData,
  TextContainer,
} from "layout";
import { ThemeElement } from "@hybrbase/themes";
import { Intro, IntroData, IntroVariant } from "../../../index";
import { uid } from "react-uid";
import { Heading, HeadingSize, HeadingVariant, Text, TextSize } from "elements";

export interface IntroWithFeaturesData extends SectionData, IntroData {
  items: {
    title: string;
    description: string;
    props?: any;
  }[];
}

export interface IntroWithFeaturesOptions {
  introVariant?: IntroVariant;
  headingSize?: HeadingSize.FluidLg | HeadingSize.FluidMd;
}

export interface IntroWithFeaturesProps
  extends Omit<HTMLProps<"section">, keyof IntroWithFeaturesData>,
    IntroWithFeaturesOptions,
    IntroWithFeaturesData {
  variant?: IntroWithFeaturesVariant;
  theme?: ThemeElement;
}

export const IntroWithFeatures = forwardRef<IntroWithFeaturesProps, "section">(
  (props, ref) => {
    const {
      variant = IntroWithFeaturesVariant.Default,
      className,
      theme = ThemeElement.Default,
      headingSize = HeadingSize.FluidMd,
      introVariant,
      title,
      subtitle,
      text,
      link,
      items,
      ...rest
    } = props;

    const { styles }: TIntroWithFeaturesConfigReturn = useCompConfig(
      IntroWithFeaturesConfig,
      { variant }
    );

    return (
      <Intro
        data-comp="intro-with-features"
        data-variant={variant}
        variant={introVariant}
        title={title}
        subtitle={subtitle}
        link={link}
        theme={theme}
        className={cx(styles.Root, className)}
        headingSize={headingSize}
        ref={ref}
        {...rest}
      >
        <Grid className={styles.Grid} gap={GridGap.None}>
          {items?.map((item, index) => (
            <Grid.Item
              key={uid(index)}
              className={styles.GridItem}
              {...item.props}
            >
              <Grid gap={GridGap.Md} className={styles.InnerGrid}>
                <Grid.Item size={GridItemSize.Two}>
                  <TextContainer className={styles.TextContainer}>
                    {variant === IntroWithFeaturesVariant.Steps ? (
                      <div className={styles.HeadingWrapper}>
                        <span className={styles.Counter}>0{index + 1}.</span>
                        <Heading variant={HeadingVariant.List} as="h3">
                          {item.title}
                        </Heading>
                      </div>
                    ) : (
                      <Heading variant={HeadingVariant.List} as="h3">
                        {item.title}
                      </Heading>
                    )}
                  </TextContainer>
                </Grid.Item>

                <Grid.Item size={GridItemSize.Two}>
                  <TextContainer className={styles.TextContainerTwo}>
                    <Text className={styles.Text} size={TextSize.FluidSm}>
                      {item.description}
                    </Text>
                  </TextContainer>
                </Grid.Item>
              </Grid>
            </Grid.Item>
          ))}
        </Grid>
      </Intro>
    );
  }
);

IntroWithFeatures.displayName = `IntroWithFeatures`;

export default IntroWithFeatures;
