import {
  TileParts,
  TilePosition,
  TileVariant,
  TileXSpace,
  TileYSpace,
} from "../types/Tile.constants";
import { TTileConfigProps } from "../types/Tile.config.types";

export const TileConfig: TTileConfigProps = {
  parts: Object.values(TileParts),
  css: {
    variants: {
      [TileVariant.Default]: {
        Root: "block",
      },
    },
    props: {
      spaceX: {
        [TileXSpace.Sm]: {
          Root: "px-md sm:px-lg",
        },
        [TileXSpace.Md]: {
          Root: "sm:px-xxl",
        },
        [TileXSpace.Lg]: {
          Root: "px-lg",
        },
        [TileXSpace.Xl]: {
          Root: "px-xl",
        },
      },
      spaceY: {
        [TileYSpace.Sm]: {
          Root: "py-md sm:py-lg",
        },
        [TileYSpace.Md]: {
          Root: "sm:py-xxl",
        },
        [TileYSpace.Lg]: {
          Root: "py-lg",
        },
        [TileYSpace.Xl]: {
          Root: "py-xl",
        },
      },
      position: {
        [TilePosition.Center]: {
          Root: "flex justify-center items-center",
        },
      },
    },
  },
};
