import Autoplay, { AutoplayOptionsType } from "embla-carousel-autoplay";
import useEmblaCarousel, { EmblaOptionsType } from "embla-carousel-react";
import { useCallback, useEffect, useState } from "react";

export interface UseCarouselProps {
  enabled?: boolean;
  emblaApi?: EmblaOptionsType & { autoplay?: AutoplayOptionsType };
}

/**
 * Carousel hook that manages all the logic
 * and returns prop getters, state and actions.
 *
 * @param props
 */
export const useCarousel = (props: UseCarouselProps) => {
  const {
    enabled = true,
    emblaApi = { align: "start", autoplay: { playOnInit: false } },
  } = props;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setScrollSnaps] = useState<any[]>([]);

  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const autoplay = Autoplay({
    rootNode: (emblaRoot) => emblaRoot.parentElement,
    ...emblaApi.autoplay,
  });

  const [emblaRef, embla] = useEmblaCarousel(
    emblaApi,
    emblaApi.autoplay ? [autoplay] : [] // Add plugin
  );

  const scrollPrev = useCallback(() => {
    embla && embla.scrollPrev();
  }, [embla]);

  const scrollNext = useCallback(() => {
    embla && embla.scrollNext();
  }, [embla]);

  const scrollTo = useCallback(
    (index) => {
      embla && embla.scrollTo(index);
    },
    [embla]
  );

  useEffect(() => {
    const onSelect = () => {
      setCurrentIndex(embla.selectedScrollSnap());
    };
    if (embla) {
      setScrollSnaps(embla.scrollSnapList());
      embla.on("select", onSelect);
      onSelect();
    }
  }, [embla]);

  useEffect(() => {
    if (!enabled && embla) {
      embla.destroy();
    }
  }, [embla, enabled]);

  return {
    emblaRef,
    scrollTo,
    scrollPrev,
    scrollNext,
    currentIndex,
    setCurrentIndex,
  };
};

export type UseCarouselReturn = ReturnType<typeof useCarousel>;
