import { BenefitParts, BenefitVariant } from "../types/Benefit.constants";
import { TBenefitConfigProps } from "../types/Benefit.config.types";

export const BenefitConfig: TBenefitConfigProps = {
  parts: Object.values(BenefitParts),
  css: {
    variants: {
      [BenefitVariant.Default]: {},
    },
  },
};
