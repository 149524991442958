export enum IntroWithFeaturesParts {
  Root = "Root",
  GridItem = "GridItem",
  InnerGrid = "InnerGrid",
  TextContainer = "TextContainer",
  TextContainerTwo = "TextContainerTwo",
  HeadingWrapper = "HeadingWrapper",
  Counter = "Counter",
  Grid = "Grid",
  Text = "Text",
}

export enum IntroWithFeaturesVariant {
  Default = "default",
  Steps = "steps",
}
