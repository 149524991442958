import { createContext } from "@hybrbase/system";
import { useEffect, useMemo } from "react";

export interface PageSeoProps {
  title: string;
  description: string;
  thumbnail?: string;
  defaultCanonicalUrl?: string;
  languageAlternates?: Array<{
    hrefLang: string;
    href: string;
  }>;
  nextSeoProps?: { [key in string]: any };
}

export interface PageContextProps {
  name?: string;
  resolvedUrl?: string;
  language?: string;
  seo?: PageSeoProps;
  isMobile?: boolean;
  theme?: any;
  settings?: { [any: string]: any };
  isPageTransition: boolean;
}

const [Provider, usePageContext] = createContext<PageContextProps>({
  name: "PageContext",
  errorMessage:
    "usePageContext: `context` is undefined. Seems you forgot to wrap all components within <PageProvider />",
});

export const PageProvider = ({ children, isPageTransition, ...rest }) => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      // Trigger our loading class
      document.body.classList.toggle("is-loading", isPageTransition);
      // Remove preload to enable css transitions until the page is preloaded.
      document.body.classList?.remove(`preload`);
    }
  }, [isPageTransition]);

  const context = useMemo(
    () => ({ ...rest, isPageTransition }),
    [isPageTransition, rest]
  );

  return <Provider value={context}>{children}</Provider>;
};

export { usePageContext };
