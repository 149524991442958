import { StackConfig } from "../styles/Stack.config";
import {
  StackDirection,
  StackSpace,
  StackVariant,
} from "../types/Stack.constants";
import {
  HTMLProps,
  cx,
  forwardRef,
  useCompConfig,
  ForwardRefComponent,
  getValidChildren,
} from "@hybrbase/system";
import React from "react";
import { uid } from "react-uid";
import { TStackConfigReturn } from "../types/Stack.config.types";

export interface StackData {}

export interface StackOptions {
  /**
   * Sets direction to row or col.
   */
  direction?: StackDirection;
  /**
   * Sets margins on left and right to auto and displays component as block
   */
  space?: StackSpace;
  /**
   * Variants for `Stack`. You can extend the variant.
   */
  variant?: StackVariant;
}
export interface StackProps
  extends Omit<HTMLProps<"div">, keyof StackData>,
    StackOptions,
    StackData {}

type StackParts = ForwardRefComponent<"div", StackProps>;

/**
 * Stack is a layout component that makes it easy to stack elements together and apply a space between them.
 */
export const Stack: StackParts = forwardRef<StackProps, "div">((props, ref) => {
  const {
    variant = StackVariant.Default,
    space = StackSpace.Md,
    direction = StackDirection.Row,
    className,
    children,
    ...rest
  } = props;

  const { styles }: TStackConfigReturn = useCompConfig(StackConfig, {
    variant,
    css: {
      direction,
      row: direction === StackDirection.Row && space,
      col: direction === StackDirection.Col && space,
    },
  });

  const validChildren = getValidChildren(children);

  return (
    <div
      data-comp="stack"
      data-variant={variant}
      className={cx(styles.Root, className)}
      ref={ref}
      {...rest}
    >
      {validChildren.map((child, index) => {
        const isLast = index + 1 === validChildren.length;
        return (
          <div className={cx({ [styles.Item]: !isLast })} key={uid(index)}>
            {child}
          </div>
        );
      })}
    </div>
  );
});

Stack.displayName = `Stack`;

export default Stack;
