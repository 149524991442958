import usePageStore from "@/stores/page";
import { Router } from "next/router";
import { useEffect } from "react";
import { isBrowser } from "utils";

export const useRouterPageTransition = () => {
  const [isPageTransition, setPageTransition, pageTransitionDuration] =
    usePageStore((state) => [
      state.isPageTransition,
      state.setPageTransition,
      state.pageDuration,
    ]);

  // Setup page transition loading states
  useEffect(() => {
    Router.events.on("routeChangeStart", (_, { shallow }) => {
      // Bail if we're just changing URL parameters
      if (shallow) return;

      // Otherwise, start loading
      setPageTransition(true);
    });

    Router.events.on("routeChangeComplete", () => {
      setTimeout(() => setPageTransition(false), pageTransitionDuration);
    });

    Router.events.on("routeChangeError", () => {
      setPageTransition(false);
    });
  }, []);

  // useEffect(() => {
  //   if (isBrowser) {
  //     // disable smooth scroll when scroll to top
  //     document.documentElement.style.scrollBehavior = isPageTransition
  //       ? "auto"
  //       : "smooth";
  //   }
  // }, [isPageTransition]);

  useEffect(() => {
    if (isBrowser) {
      document.body.classList.toggle("overflow-hidden", isPageTransition);
    }
  }, [isPageTransition]);
};
