/* eslint-disable jsx-a11y/media-has-caption */
import React, { useMemo } from "react";
import {
  HTMLProps,
  cx,
  forwardRef,
  ForwardRefComponent,
  useMergeRefs,
} from "@hybrbase/system";
import { VideoLayout, VideoVariant } from "../types/Video.constants";
import { useVideo, UseVideoProps } from "../utils/use-video";
import {
  AspectRatio,
  AspectRatioLayout,
  AspectRatioPosition,
  AspectRatioProps,
  AspectRatioSize,
} from "layout";
import { Icon, IconName, IconSize } from "../../../icon";
import { VideoControls, VideoControlsData } from "./VideoControls";
import { UseVideoControlsOptions } from "../utils/use-video-controls";
import { VideoTimeline } from "./VideoTimeline";
import { VideoProvider } from "../utils";
import { VideoCaptions } from "./VideoCaptions";
import { noop } from "utils";

export interface VideoData {}

export interface VideoOptions {
  aspectRatio?: AspectRatioProps;
  hasTogglePlayBtn?: boolean;
  tabIndex?: number;
}
export interface VideoProps
  extends Omit<HTMLProps<"video">, keyof UseVideoProps>,
    VideoOptions,
    VideoData,
    UseVideoProps,
    VideoControlsData,
    UseVideoControlsOptions {
  src: string;
  crossOrigin?: "anonymous" | "use-credentials";

  variant?: VideoVariant;
}

type VideoParts = ForwardRefComponent<"div", VideoProps> & {
  Controls?: typeof VideoControls;
  Timeline?: typeof VideoTimeline;
};

export const Video: VideoParts = forwardRef<VideoProps, "div">((props, ref) => {
  const {
    variant = VideoVariant.Default,
    aspectRatio = {
      size: AspectRatioSize.SixteenDivideByNine,
      layout: AspectRatioLayout.Cover,
      position: AspectRatioPosition.LeftTop,
    },
    layout = VideoLayout.Contain,
    hasTogglePlayBtn = true,
    hasShowControlsOnLoad,
    className,
    playsInline = true, // set disable mobile play
    loop = true,
    crossOrigin = "anonymous",
    onMute = noop,
    onUnmute = noop,
    onReady = noop,
    onPlay = noop,
    onPause = noop,
    onEnd = noop,
    onTimeUpdate = noop,
    onCaption = noop,
    onFullScreen = noop,
    onMouseMove = noop,
    containerWidth,
    containerHeight,
    hasDisableBackgroundCover = layout === VideoLayout.BackgroundCover
      ? false
      : true,
    horizontalAlign = 0.5,
    verticalAlign = 0.5,
    startTime = 0,
    volume = 1,
    autoPlay,
    muted = false,
    hasAllowKeyboardControl,
    tabIndex = 0,
    preload = "auto",
    hasControls,
    captions,
    navAriaLabel = "Video Controls",
    playLabel = "Play Video",
    pauseLabel = "Pause Video",
    captionsHideLabel = "Hide Captions",
    captionsShowLabel = "Show Captions",
    unmuteLabel = "Unmute Video",
    muteLabel = "Mute Video",
    exitFullscreenLabel = "Exit Fullscreen Mode",
    enterFullscreenLabel = "Enter Fullscreen Mode",
    ...rest
  } = props;

  const defaultSetting = useMemo(
    () => ({
      autoPlay: hasTogglePlayBtn ? false : autoPlay || true,
      muted: hasTogglePlayBtn ? false : muted || true,
    }),
    [autoPlay, muted, hasTogglePlayBtn]
  );

  const { ...ctx } = useVideo({
    variant,
    layout,
    onMute,
    onUnmute,
    onReady,
    onPlay,
    onPause,
    onEnd,
    onTimeUpdate,
    onCaption,
    onFullScreen,
    onMouseMove,
    containerWidth,
    containerHeight,
    hasDisableBackgroundCover,
    horizontalAlign,
    verticalAlign,
    startTime,
    volume,
    autoPlay: defaultSetting.autoPlay,
    muted: defaultSetting.muted,
    captions,
  });

  const containerStyle = useMemo(
    () => ({
      width: containerWidth ? `${containerWidth}px` : "100%",
      height: containerHeight ? `${containerHeight}px` : "100%",
    }),
    [containerWidth, containerHeight]
  );

  const context = React.useMemo(() => ({ ...ctx }), [ctx]);

  return (
    <VideoProvider value={context}>
      {layout === VideoLayout.Ratio ? (
        <AspectRatio
          {...aspectRatio}
          className={cx("relative", aspectRatio.className)}
          tabIndex={tabIndex}
          ref={ctx.containerRef}
        >
          {ctx.isLoaded && hasTogglePlayBtn && (
            <button onClick={ctx.togglePlay} className={ctx.styles.PlayBtn}>
              {ctx.isPaused && (
                <>
                  <div className={ctx.styles.PlayBtnBackground} />
                  <Icon size={IconSize.Md} name={IconName.Play} />
                </>
              )}
            </button>
          )}

          <div
            data-comp="video"
            data-variant={variant}
            style={containerStyle}
            className={cx(
              "video",
              ctx.styles.Root,
              {
                visible: ctx.isLoaded,
                invisible: !ctx.isLoaded,
              },
              className
            )}
          >
            <video
              ref={useMergeRefs(ctx.videoRef, ref)}
              onTimeUpdate={ctx.handleOnTimeUpdate}
              onEnded={ctx.handleOnEnded}
              onKeyPress={ctx.handleOnKeyPress}
              playsInline={playsInline}
              loop={loop}
              preload={preload}
              autoPlay={defaultSetting.autoPlay}
              muted={defaultSetting.muted}
              crossOrigin={crossOrigin}
              {...rest}
            />

            {Boolean(captions) && <VideoCaptions />}

            {hasControls && (
              <VideoControls
                hasCaptions={Boolean(captions)}
                hasControls={hasControls}
                navAriaLabel={navAriaLabel}
                playLabel={playLabel}
                pauseLabel={pauseLabel}
                captionsHideLabel={captionsHideLabel}
                captionsShowLabel={captionsShowLabel}
                unmuteLabel={unmuteLabel}
                muteLabel={muteLabel}
                exitFullscreenLabel={exitFullscreenLabel}
                enterFullscreenLabel={enterFullscreenLabel}
              />
            )}
          </div>
        </AspectRatio>
      ) : (
        <div
          data-comp="video"
          data-variant={variant}
          style={containerStyle}
          tabIndex={tabIndex}
          className={cx(
            "video",
            ctx.styles.Root,
            {
              visible: ctx.isLoaded,
              invisible: !ctx.isLoaded,
            },
            className
          )}
          ref={ctx.containerRef}
        >
          {ctx.isLoaded && hasTogglePlayBtn && (
            <button onClick={ctx.togglePlay} className={ctx.styles.PlayBtn}>
              {ctx.isPaused && (
                <>
                  <div className={ctx.styles.PlayBtnBackground} />
                  <Icon size={IconSize.Md} name={IconName.Play} />
                </>
              )}
            </button>
          )}

          <video
            ref={useMergeRefs(ctx.videoRef, ref)}
            onTimeUpdate={ctx.handleOnTimeUpdate}
            onEnded={ctx.handleOnEnded}
            onKeyPress={ctx.handleOnKeyPress}
            playsInline={playsInline}
            autoPlay={defaultSetting.autoPlay}
            muted={defaultSetting.muted}
            loop={loop}
            preload={preload}
            crossOrigin={crossOrigin}
            {...rest}
          />

          {Boolean(captions) && <VideoCaptions />}

          {hasControls && (
            <VideoControls
              hasCaptions={Boolean(captions)}
              navAriaLabel={navAriaLabel}
              playLabel={playLabel}
              pauseLabel={pauseLabel}
              captionsHideLabel={captionsHideLabel}
              captionsShowLabel={captionsShowLabel}
              unmuteLabel={unmuteLabel}
              muteLabel={muteLabel}
              exitFullscreenLabel={exitFullscreenLabel}
              enterFullscreenLabel={enterFullscreenLabel}
            />
          )}
        </div>
      )}
    </VideoProvider>
  );
});

Video.Controls = VideoControls;
Video.Timeline = VideoTimeline;

Video.displayName = `Video`;
Video.Controls.displayName = "Video.Controls";
Video.Timeline.displayName = "Video.Timeline";

export default Video;
