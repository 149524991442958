import React from "react";
import { HTMLProps, cx, forwardRef, useCompConfig } from "@hybrbase/system";
import { MediaFeaturesConfig } from "../styles/MediaFeatures.config";
import { MediaFeaturesVariant } from "../types/MediaFeatures.constants";
import { TMediaFeaturesConfigReturn } from "../types/MediaFeatures.config.types";
import {
  AspectRatioImage,
  Heading,
  HeadingVariant,
  ImageProps,
  Text,
  Video,
  VideoLayout,
  VideoProps,
} from "elements";
import {
  SectionData,
  TextContainer,
  Carousel,
  Tile,
  AspectRatioSize,
  TileXSpace,
} from "layout";
import { BorderRadius, Breakpoint, rem, ThemeElement } from "@hybrbase/themes";
import { Intro, IntroData, IntroVariant } from "../../../index";
import { uid } from "react-uid";

export interface MediaFeaturesData extends SectionData, IntroData {
  items: {
    video?: VideoProps & { isBoolean: boolean };
    image?: ImageProps;
    title: string;
    description: string;
    props?: any;
  }[];
}

export interface MediaFeaturesOptions {
  introVariant?: IntroVariant;
  aspectRatioSize?: AspectRatioSize;
  hasNavigation?: boolean;
}

export interface MediaFeaturesProps
  extends Omit<HTMLProps<"section">, keyof MediaFeaturesData>,
    MediaFeaturesOptions,
    MediaFeaturesData {
  variant?: MediaFeaturesVariant;
  theme?: ThemeElement;
}

export const MediaFeatures = forwardRef<MediaFeaturesProps, "section">(
  (props, ref) => {
    const {
      variant = MediaFeaturesVariant.Default,
      className,
      theme = ThemeElement.Default,
      subtitle,
      title,
      text,
      link,
      items,
      introVariant,
      aspectRatioSize = AspectRatioSize.Square,
      hasNavigation = false,
      ...rest
    } = props;

    const { styles }: TMediaFeaturesConfigReturn = useCompConfig(
      MediaFeaturesConfig,
      { variant }
    );

    return (
      <Intro
        data-comp="media-features"
        data-variant={variant}
        title={title}
        subtitle={subtitle}
        variant={introVariant}
        link={link}
        theme={theme}
        className={cx(styles.Root, className)}
        ref={ref}
        {...rest}
      >
        <Carousel
          emblaApi={{
            align: "start",
            dragFree: true,
            breakpoints: {
              [`(min-width: ${rem(Breakpoint.Tablet)})`]: {
                active: items?.length === 3 ? false : true,
              },
            },
          }}
        >
          {({ scrollPrev, scrollNext }) => {
            return (
              <>
                <Carousel.Slides className={cx("space-x-md cursor-grab")}>
                  {items?.map((item, index) => (
                    <div
                      className={cx(
                        `slide-item-${index} flex flex-col min-w-[66vw] xs:min-w-[50vw] sm:min-w-[33vw]`,
                        { "sm:min-w-min sm:cursor-auto": items?.length === 3 }
                      )}
                      key={uid(index)}
                      {...item.props}
                    >
                      {item.video?.isBoolean ? (
                        <Video
                          src={item.video.src}
                          poster={item.video.poster}
                          layout={VideoLayout.Ratio}
                          aspectRatio={{
                            radius: BorderRadius.Lg,
                            size: aspectRatioSize,
                            className:
                              "border-1 border-solid border-contrast-low",
                          }}
                          hasTogglePlayBtn={false}
                        />
                      ) : (
                        <AspectRatioImage
                          image={item.image}
                          aspectRatio={{
                            radius: BorderRadius.Lg,
                            size: aspectRatioSize,
                            className:
                              "border-1 border-solid border-contrast-low",
                          }}
                        />
                      )}
                      <Tile
                        className="py-fluid-lg-to-xxxl"
                        spaceX={TileXSpace.Sm}
                      >
                        <TextContainer>
                          <Heading variant={HeadingVariant.List}>
                            {item.title}
                          </Heading>
                          {item.description ? (
                            <Text>{item.description}</Text>
                          ) : null}
                        </TextContainer>
                      </Tile>
                    </div>
                  ))}
                </Carousel.Slides>
                {hasNavigation ? (
                  <div
                    className={cx("flex space-x-sm mt-lg", {
                      " sm:hidden": items?.length === 3,
                    })}
                  >
                    <button
                      onClick={scrollPrev}
                      className="font-secondary font-bold uppercase text-sm"
                    >
                      Previous
                    </button>
                    <span>{"/"}</span>
                    <button
                      onClick={scrollNext}
                      className="font-secondary font-bold uppercase text-sm"
                    >
                      Next
                    </button>
                  </div>
                ) : null}
              </>
            );
          }}
        </Carousel>
      </Intro>
    );
  }
);

MediaFeatures.displayName = `MediaFeatures`;

export default MediaFeatures;
