import React, { useMemo } from "react";
import { cx } from "@hybrbase/system";
import { cssVars } from "@hybrbase/themes";

import { Button, Icon, ButtonVariant, IconSize } from "../../../index";
import { VideoTimeline } from "./VideoTimeline";
import { useVideoContext, VideoVariant } from "../index";
import {
  useVideoControls,
  UseVideoControlsOptions,
} from "../utils/use-video-controls";
import { factoryVideoControls } from "../utils/factory-video-controls";

export interface VideoControlsData {
  exitFullscreenLabel?: string;
  enterFullscreenLabel?: string;
  navAriaLabel?: string;
  playLabel?: string;
  pauseLabel?: string;
  captionsHideLabel?: string;
  captionsShowLabel?: string;
  unmuteLabel?: string;
  muteLabel?: string;
}

export interface VideoControlsOptions {
  hasCaptions?: boolean;
  isShowingCaptions?: boolean;
  isShowingControls?: boolean;
}
export interface VideoControlsProps
  extends VideoControlsOptions,
    VideoControlsData,
    UseVideoControlsOptions {
  /**
   * Use the className prop in Video to add classes
   */
  className?: string;
  children?: React.ReactNode;
}

/**
 *
 */
export const VideoControls: React.FC<VideoControlsProps> = ({
  className,
  hasCaptions,
  hasControls,
  hasShowControlsOnLoad,
  controlsTimeout,
  autoPlayDelay,
  navAriaLabel = "Video Controls",
  playLabel = "Play Video",
  pauseLabel = "Pause Video",
  captionsHideLabel = "Hide Captions",
  captionsShowLabel = "Show Captions",
  unmuteLabel = "Unmute Video",
  muteLabel = "Mute Video",
  exitFullscreenLabel = "Exit Fullscreen Mode",
  enterFullscreenLabel = "Enter Fullscreen Mode",
}) => {
  const { styles, variant } = useVideoContext();

  const {
    isPlaying,
    isFullScreen,
    isFullscreenAPISupported,
    isMuted,
    isShowingControls,
    isShowingCaptions,
    handleOnControlsBlur,
    handleOnControlsFocus,
    formatedTime,
    togglePlay,
    toggleMute,
    toggleFullscreen,
    toggleCaptions,
  } = useVideoControls({
    hasControls,
    hasShowControlsOnLoad,
    controlsTimeout,
    autoPlayDelay,
  });

  const { icon } = useMemo(() => factoryVideoControls(variant), [variant]);

  const { rootStyle } = React.useMemo(() => {
    switch (variant) {
      case VideoVariant.Default:
      default:
        return {
          rootStyle: {
            "--video-controls-button-width": "auto",
            "--video-controls-button-height": "auto",
            "--video-controls-button-color": cssVars.colors.accent,
            "--video-controls-button-margin": cssVars.spacing.md,
            "--video-controls-height": cssVars.spacing.xl,
            "--video-controls-bg-color": cssVars.colors.white,
            "--video-controls-time-color": cssVars.colors.accent,
            "--video-controls-time-width": cssVars.spacing.xl,
            "--video-controls-transition": "transform .3s ease-in",
          } as JSX.IntrinsicElements["style"],
        };
    }
  }, [variant]);

  return (
    <nav
      style={{ ...rootStyle }}
      className={cx(
        "video-controls",
        { "video-controls--visible": isShowingControls },
        styles.Controls,
        className
      )}
      aria-label={navAriaLabel}
      onFocus={handleOnControlsFocus}
      onBlur={handleOnControlsBlur}
    >
      <Button
        variant={ButtonVariant.Reset}
        className={cx("video-controls-button", styles.ControlsButton)}
        title={isPlaying ? pauseLabel : playLabel}
        onClick={togglePlay}
      >
        <Icon
          name={isPlaying ? icon?.pause : icon?.play}
          title={isPlaying ? pauseLabel : playLabel}
          size={IconSize.Md}
        />
      </Button>

      <VideoTimeline />

      {formatedTime && (
        <time className={cx("video-controls-time", styles.ControlsTime)}>
          {formatedTime}
        </time>
      )}

      {hasCaptions && (
        <Button
          variant={ButtonVariant.Reset}
          className={cx("video-controls-button", styles.ControlsButton)}
          title={isShowingCaptions ? captionsHideLabel : captionsShowLabel}
          onClick={toggleCaptions}
        >
          <Icon
            name={isShowingCaptions ? icon?.captionsOn : icon?.captionsOff}
            title={isShowingCaptions ? captionsHideLabel : captionsShowLabel}
            size={IconSize.Md}
          />
        </Button>
      )}

      <Button
        variant={ButtonVariant.Reset}
        className={cx("video-controls-button", styles.ControlsButton)}
        title={isMuted ? unmuteLabel : muteLabel}
        onClick={toggleMute}
      >
        <Icon
          name={isMuted ? icon?.muted : icon?.unmuted}
          title={isMuted ? unmuteLabel : muteLabel}
          size={IconSize.Md}
        />
      </Button>

      {isFullscreenAPISupported && (
        <Button
          variant={ButtonVariant.Reset}
          className={cx("video-controls-button", styles.ControlsButton)}
          title={isFullScreen ? exitFullscreenLabel : enterFullscreenLabel}
          onClick={toggleFullscreen}
        >
          <Icon
            name={isFullScreen ? icon?.exitFullscreen : icon?.enterFullscreen}
            title={isFullScreen ? exitFullscreenLabel : enterFullscreenLabel}
            size={IconSize.Md}
          />
        </Button>
      )}
    </nav>
  );
};

VideoControls.displayName = `VideoControls`;
