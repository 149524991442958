import {
  AspectRatioImageParts,
  AspectRatioImageVariant,
} from "../types/AspectRatioImage.constants";
import { TAspectRatioImageConfigProps } from "../types/AspectRatioImage.config.types";

export const AspectRatioImageConfig: TAspectRatioImageConfigProps = {
  parts: Object.values(AspectRatioImageParts),
  css: {
    variants: {
      [AspectRatioImageVariant.Default]: {
        Root: "",
      },
    },
    props: {},
  },
};
