import {
  GridColumns,
  GridGap,
  TextContainerAlign,
  TextContainerSize,
} from "layout";
import { IntroVariant } from "../types/Intro.constants";

export const introFactory = (variant: IntroVariant) => {
  switch (variant) {
    case IntroVariant.Four:
      return {
        grid: { columns: GridColumns.Four, gap: GridGap.Xl },
        textContainer: {
          align: TextContainerAlign.Left,
          size: TextContainerSize.Sm,
        },
      };

    case IntroVariant.Three:
      return {
        grid: { columns: GridColumns.Four, gap: GridGap.Xl },
        textContainer: {
          align: TextContainerAlign.Left,
          size: TextContainerSize.Md,
        },
      };

    case IntroVariant.Two:
      return {
        grid: { columns: GridColumns.Three, gap: GridGap.Xl },
        textContainer: {
          align: TextContainerAlign.Center,
          size: TextContainerSize.Md,
        },
      };
    case IntroVariant.One:
      return {
        grid: {
          columns: GridColumns.Four,
          gap: GridGap.Xl,
        },
        textContainer: {
          align: TextContainerAlign.Left,
          size: TextContainerSize.Md,
        },
      };

    default:
      return {
        grid: {
          columns: GridColumns.Four,
          gap: GridGap.Xl,
        },
        textContainer: {
          align: TextContainerAlign.Left,
          size: TextContainerSize.Md,
        },
      };
  }
};
