import { createContext } from "@hybrbase/system";

export interface UseFeatureProps {}

/**
 * Feature hook that manages all the logic
 * and returns prop getters, state and actions.
 *
 * @param props
 */
export const useFeature = (props: UseFeatureProps) => {
  const {} = props;
  return {};
};

export type UseFeatureReturn = ReturnType<typeof useFeature>;

const [FeatureProvider, useFeatureContext] = createContext<UseFeatureReturn>({
  name: "FeatureContext",
  errorMessage:
    "useFeatureContext: `context` is undefined. Seems you forgot to wrap all Feature's components within <Feature />",
});

export { FeatureProvider, useFeatureContext };
