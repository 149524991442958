import { MediaFeatures } from "landing";
import { storyblokEditable } from "@hybrbase/storyblok";
import { AspectRatioSize } from "layout";

import { getImageService, getVideoService, isVideo } from "@/utils";
import { getSbImageInfo } from "@/lib/storyblok";
import { useMemo } from "react";
import { renderRichText } from "@hybrbase/storyblok";
// import { useMemo } from "react";

export const SbMediaFeatures = ({ blok }) => {
  const {
    title,
    subtitle,
    link_label,
    link_href,
    items,
    intro_variant,
    theme,
  } = blok; // Component scheme

  const filteredItems = useMemo(
    () =>
      items.map((item) => {
        return {
          title: item.title,
          description: item.text,
          image: {
            src: getImageService({
              src: item?.image_src?.filename,
            }),
            alt: item?.image_src?.alt,
            title: item?.image_src?.title,
            width: getSbImageInfo(
              item?.image_src?.filename,
              AspectRatioSize.TwoDivideByThree
            ).width,
            height: getSbImageInfo(
              item?.image_src?.filename,
              AspectRatioSize.TwoDivideByThree
            ).height,
          },
          video: {
            src: getVideoService({ src: item?.video_src?.filename }),
            poster: getImageService({
              src: item?.video_poster?.filename,
            }),
            isBoolean: isVideo(item?.video_src?.filename),
          },
          props: storyblokEditable(item),
        };
      }),
    [items]
  );

  return (
    <MediaFeatures
      title={renderRichText(title)}
      subtitle={subtitle}
      theme={theme}
      introVariant={intro_variant}
      link={{
        href: link_href?.cached_url,
        isOpenNewTab: link_href?.target === "_blank",
        children: link_label,
      }}
      items={filteredItems}
      {...storyblokEditable(blok)}
    />
  );
};
