export enum HeadingParts {
  Root = "Root",
}

export enum HeadingVariant {
  Hero = "hero",
  Display = "display",
  Section = "section",
  SubTitle = "sub-title",
  Feature = "feature",
  Card = "card",
  List = "list",
  Clickable = "clickable",
}

export enum HeadingSize {
  EightXl = `8xl`,
  SevenXl = `7xl`,
  SixXl = `6xl`,
  FiveXl = `5xl`,
  Xxxxl = "xxxxl",
  Xxxl = "xxxl",
  Xxl = "xxl",
  Xl = "xl",
  Lg = "lg",
  Md = "md",
  Base = "base",
  Sm = "sm",
  Fluid = "fluid",
  FluidSm = "fluid-sm",
  FluidMd = "fluid-md",
  FluidLg = "fluid-lg",
  FluidXl = "fluid-xl",
}

export enum HeadingContrast {
  Higher = "higher",
  High = "high",
  Medium = "medium",
}
