import { IconName } from "../../../icon";
import { VideoVariant } from "../types/Video.constants";

export const factoryVideoControls = (variant: VideoVariant) => {
  switch (variant) {
    case VideoVariant.Default:
    default:
      return {
        icon: {
          play: IconName.PlayOutline,
          pause: IconName.Pause,
          captionsOn: IconName.CaptionsOn,
          captionsOff: IconName.CaptionsOff,
          muted: IconName.Muted,
          unmuted: IconName.Unmuted,
          exitFullscreen: IconName.ExitFullscreen,
          enterFullscreen: IconName.EnterFullscreen,
        },
      };
  }
};
