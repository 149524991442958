import React from "react";
import {
  cx,
  forwardRef,
  ForwardRefComponent,
  HTMLProps,
  getValidChildren,
} from "@hybrbase/system";
import { AccordionVariant } from "../types/Accordion.constants";
import { AccordionItem } from "./AccordionItem";

import {
  AccordionDescendantsProvider,
  AccordionProvider,
  useAccordion,
  UseAccordionProps,
} from "../utils";
import { AccordionButton } from "./AccordionButton";
import { AccordionPanel } from "./AccordionPanel";
import { uid } from "react-uid";

export interface AccordionData {}

export interface AccordionOptions {}

// TODO: INSPIRATION => https://codesandbox.io/s/framer-motion-accordion-om24w?file=/src/index.js
export interface AccordionProps
  extends Omit<HTMLProps<"div">, "onChange">,
    AccordionOptions,
    AccordionData,
    UseAccordionProps {}

type AccordionParts = ForwardRefComponent<"div", AccordionProps> & {
  Item?: typeof AccordionItem;
  Button?: typeof AccordionButton;
  Panel?: typeof AccordionPanel;
};

export const Accordion: AccordionParts = forwardRef<AccordionProps, "div">(
  (props, ref) => {
    const {
      variant = AccordionVariant.Default,
      className,
      children,
      onChange,
      allowToggle,
      allowMultiple,
      index,
      defaultIndex,
      ...rest
    } = props;

    const { descendants, ...ctx } = useAccordion({
      variant,
      onChange,
      index,
      allowMultiple,
      allowToggle,
      defaultIndex,
      ...rest,
    });

    const context = React.useMemo(() => ctx, [ctx]);

    return (
      <AccordionDescendantsProvider value={descendants}>
        <AccordionProvider value={context}>
          <div
            data-comp="accordion"
            data-variant={variant}
            className={cx(ctx.styles.Root, className)}
            ref={ref}
            {...rest}
          >
            {getValidChildren(children).map((child, index) => {
              if (child.type === AccordionItem) {
                return (
                  <React.Fragment key={uid(index)}>{child}</React.Fragment>
                );
              } else {
                return (
                  <>{"Unsupported component -> Please use Accordion.Item"}</>
                );
              }
            })}
          </div>
        </AccordionProvider>
      </AccordionDescendantsProvider>
    );
  }
);

Accordion.Item = AccordionItem;
Accordion.Button = AccordionButton;
Accordion.Panel = AccordionPanel;

Accordion.displayName = `Accordion`;
Accordion.Item.displayName = `Accordion.Item`;
Accordion.Button.displayName = `Accordion.Button`;
Accordion.Panel.displayName = `Accordion.Panel`;

export default Accordion;
