import { IntroWithFeatures } from "landing";
import { storyblokEditable } from "@hybrbase/storyblok";
import { useMemo } from "react";
import { renderRichText } from "@hybrbase/storyblok";

export const SbIntroWithFeatures = ({ blok }) => {
  const {
    title,
    subtitle,
    link_label,
    link_href,
    items,
    intro_variant,
    variant,
    theme,
    heading_size,
  } = blok; // Component scheme

  const filteredItems = useMemo(
    () =>
      items.map((item) => {
        return {
          title: item.title,
          description: item.description,
          props: storyblokEditable(item),
        };
      }),
    [items]
  );
  return (
    <IntroWithFeatures
      title={renderRichText(title)}
      subtitle={subtitle}
      theme={theme}
      headingSize={heading_size}
      link={{
        href: link_href?.cached_url,
        isOpenNewTab: link_href?.target === "_blank",
        children: link_label,
      }}
      variant={variant}
      introVariant={intro_variant}
      items={filteredItems}
      {...storyblokEditable(blok)}
    />
  );
};
