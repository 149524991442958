import { createContext } from "@hybrbase/system";
import { UseMarqueeReturn } from "./use-marquee";

const [MarqueeProvider, useMarqueeContext] = createContext<UseMarqueeReturn>({
  name: "MarqueeContext",
  errorMessage:
    "useMarqueeContext: `context` is undefined. Seems you forgot to wrap all Marquee's components within <Marquee />",
});

export { MarqueeProvider, useMarqueeContext };
