import { TileConfig } from "../styles/Tile.config";
import {
  TilePosition,
  TileVariant,
  TileXSpace,
  TileYSpace,
} from "../types/Tile.constants";
import {
  HTMLProps,
  cx,
  forwardRef,
  useCompConfig,
  ForwardRefComponent,
} from "@hybrbase/system";
import { ThemeElement } from "@hybrbase/themes";
import React from "react";
import { TTileConfigReturn } from "../types/Tile.config.types";

export interface TileData {}

export interface TileOptions {
  /**
   * Variants for `Tile`. You can extend the variant.
   */
  variant?: TileVariant;
  theme?: ThemeElement;
  position?: TilePosition;
  spaceY?: TileYSpace;
  spaceX?: TileXSpace;
}
export interface TileProps
  extends Omit<HTMLProps<"div">, keyof TileData>,
    TileOptions,
    TileData {}

type TileParts = ForwardRefComponent<"div", TileProps>;

export const Tile: TileParts = forwardRef<TileProps, "div">((props, ref) => {
  const {
    variant = TileVariant.Default,
    className,
    theme,
    position,
    spaceY = TileYSpace.None,
    spaceX = TileXSpace.Md,
    children,
    ...rest
  } = props;

  const { styles }: TTileConfigReturn = useCompConfig(TileConfig, {
    variant,
    css: {
      position,
      spaceY,
      spaceX,
    },
  });

  return (
    <div
      data-comp="tile"
      data-variant={variant}
      data-theme-element={theme}
      className={cx(styles.Root, className)}
      ref={ref}
      {...rest}
    >
      {children}
    </div>
  );
});

Tile.displayName = `Tile`;

export default Tile;
