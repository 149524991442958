import { useEffect, useMemo, useRef, useState } from "react";
import { MarqueeVariant } from "../types/Marquee.constants";

export interface UseMarqueeProps {
  variant?: MarqueeVariant;
  /**
   * Speed calculated as pixels/second
   * Type: number
   * Default: 20
   */
  speed?: number;
}

/**
 * Marquee hook that manages all the logic
 * and returns prop getters, state and actions.
 *
 * @param props
 */
export const useMarquee = (props: UseMarqueeProps) => {
  const { variant, speed = 20 } = props;

  const [containerWidth, setContainerWidth] = useState(0);
  const [marqueeWidth, setMarqueeWidth] = useState(0);
  const [isMounted, setIsMounted] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const marqueeRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!isMounted) return;

    const calculateWidth = () => {
      // Find width of container and width of marquee
      if (marqueeRef.current && containerRef.current) {
        setContainerWidth(containerRef.current.getBoundingClientRect().width);
        setMarqueeWidth(marqueeRef.current.getBoundingClientRect().width);
      }
    };

    calculateWidth();
    // Rerender on window resize
    window.addEventListener("resize", calculateWidth);
    return () => {
      window.removeEventListener("resize", calculateWidth);
    };
  }, [isMounted]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const duration = useMemo(
    () =>
      marqueeWidth < containerWidth
        ? containerWidth / speed
        : marqueeWidth / speed,
    [marqueeWidth, containerWidth, speed]
  );

  return { variant, isMounted, duration, containerRef, marqueeRef };
};

export type UseMarqueeReturn = ReturnType<typeof useMarquee>;
