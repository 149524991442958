export enum IntroParts {
  Root = "Root",
  Container = "Container",
  Grid = "Grid",
  GridItemOne = "GridItemOne",
  GridItemTwo = "GridItemTwo",
  TileOne = "TileOne",
  TileTwo = "TileTwo",
  TextContainer = "TextContainer",
  Heading = "Heading",
  Text = "Text",
  LinkFx = "LinkFx",
}

export enum IntroVariant {
  Default = "default",
  One = "one",
  Two = "two",
  Three = "three",
  Four = "four",
}
