import { TGridItemConfigProps } from "../types/GridItem.config.types";
import {
  GridItemOffset,
  GridItemParts,
  GridItemSize,
} from "../types/GridItem.constants";

export const GridItemConfig: TGridItemConfigProps = {
  parts: Object.values(GridItemParts),
  css: {
    variants: {},
    props: {
      size: {
        [GridItemSize.Auto]: {
          Root: "flex-col",
        },
        [GridItemSize.Content]: {
          Root: "flex-content",
        },
        [GridItemSize.One]: {
          Root: "flex-col-1",
        },
        [GridItemSize.Two]: {
          Root: "flex-col-2",
        },
        [GridItemSize.Three]: {
          Root: "md:flex-col-3",
        },
        [GridItemSize.Four]: {
          Root: "md:flex-col-4",
        },
        [GridItemSize.Five]: {
          Root: "md:flex-col-5",
        },
        [GridItemSize.Six]: {
          Root: "md:flex-col-6",
        },
        [GridItemSize.Seven]: {
          Root: "md:flex-col-7",
        },
        [GridItemSize.Eight]: {
          Root: "md:flex-col-8",
        },
        [GridItemSize.Nine]: {
          Root: "md:flex-col-9",
        },
        [GridItemSize.Ten]: {
          Root: "md:flex-col-10",
        },
        [GridItemSize.Eleven]: {
          Root: "md:flex-col-11",
        },
        [GridItemSize.Twelve]: {
          Root: "md:flex-col-12",
        },
      },
      offset: {
        [GridItemOffset.One]: {
          Root: "flex-offset-1",
        },
        [GridItemOffset.Two]: {
          Root: "flex-offset-2",
        },
        [GridItemOffset.Three]: {
          Root: "md:flex-offset-3",
        },
        [GridItemOffset.Four]: {
          Root: "md:flex-offset-4",
        },
        [GridItemOffset.Five]: {
          Root: "md:flex-offset-5",
        },
        [GridItemOffset.Six]: {
          Root: "md:flex-offset-6",
        },
        [GridItemOffset.Seven]: {
          Root: "md:flex-offset-7",
        },
        [GridItemOffset.Eight]: {
          Root: "md:flex-offset-8",
        },
        [GridItemOffset.Nine]: {
          Root: "md:flex-offset-9",
        },
        [GridItemOffset.Ten]: {
          Root: "md:flex-offset-10",
        },
        [GridItemOffset.Eleven]: {
          Root: "md:flex-offset-11",
        },
        [GridItemOffset.Twelve]: {
          Root: "md:flex-offset-12",
        },
      },
    },
  },
};
