import { AspectRatioSize } from "layout";

export const getRatio = (size: AspectRatioSize, width: number) => {
  switch (size) {
    case AspectRatioSize.Square:
      return {
        width: width,
        height: Math.round(width / (1 / 1)),
      };

    case AspectRatioSize.TwoDivideByOne:
      return {
        width: width,
        height: Math.round(width / (2 / 1)),
      };

    case AspectRatioSize.TwoDivideByThree:
      return {
        width: width,
        height: Math.round(width / (2 / 3)),
      };

    case AspectRatioSize.ThreeDivideByOne:
      return {
        width: width,
        height: Math.round(width / (3 / 1)),
      };

    case AspectRatioSize.ThreeDivideByTwo:
      return {
        width: width,
        height: Math.round(width / (3 / 2)),
      };

    case AspectRatioSize.FourDivideByThree:
      return {
        width: width,
        height: Math.round(width / (4 / 3)),
      };

    case AspectRatioSize.TwentyOneDivideByNine:
      return {
        width: width,
        height: Math.round(width / (21 / 9)),
      };

    case AspectRatioSize.SixteenDivideByNine:
    default:
      return {
        width: width,
        height: Math.round(width / (16 / 9)),
      };
  }
};
