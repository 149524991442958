import { FontFamily, FontWeight } from "@hybrbase/themes";
import {
  HeadingContrast,
  HeadingParts,
  HeadingSize,
  HeadingVariant,
} from "../types/Heading.constants";
import { THeadingConfigProps } from "../types/Heading.config.types";

export const HeadingConfig: THeadingConfigProps = {
  parts: Object.values(HeadingParts),
  css: {
    variants: {
      [HeadingVariant.Clickable]: {},
    },
    props: {
      size: {
        [HeadingSize.FluidXl]: {
          Root: "text-fluid-xl",
        },
        [HeadingSize.FluidLg]: {
          Root: "text-fluid-lg",
        },
        [HeadingSize.FluidMd]: {
          Root: "text-fluid-md",
        },
        [HeadingSize.FluidSm]: {
          Root: "text-fluid-sm",
        },
        [HeadingSize.Fluid]: {
          Root: "text-fluid",
        },
        [HeadingSize.EightXl]: {
          Root: "text-8xl",
        },
        [HeadingSize.SevenXl]: {
          Root: "text-7xl",
        },
        [HeadingSize.SixXl]: {
          Root: "text-6xl",
        },
        [HeadingSize.FiveXl]: {
          Root: "text-5xl",
        },
        [HeadingSize.Xxxxl]: {
          Root: "text-xxxxl",
        },
        [HeadingSize.Xxxl]: {
          Root: "text-xxxl",
        },
        [HeadingSize.Xxl]: {
          Root: "text-xxl",
        },
        [HeadingSize.Xl]: {
          Root: "text-xl",
        },
        [HeadingSize.Lg]: {
          Root: "text-lg",
        },
        [HeadingSize.Md]: {
          Root: "text-md",
        },
        [HeadingSize.Sm]: {
          Root: "text-sm",
        },
        [HeadingSize.Base]: {
          Root: "text-base",
        },
      },
      contrast: {
        [HeadingContrast.Higher]: {
          Root: "text-contrast-higher",
        },
        [HeadingContrast.High]: {
          Root: "text-contrast-high",
        },
        [HeadingContrast.Medium]: {
          Root: "text-contrast-medium",
        },
      },
      weight: {
        [FontWeight.Regular]: {
          Root: "font-regular",
        },
        [FontWeight.Bold]: {
          Root: "font-bold",
        },
      },
      font: {
        [FontFamily.Primary]: {
          Root: "font-primary",
        },
        [FontFamily.Secondary]: {
          Root: "font-secondary",
        },
      },
    },
  },
};
