export enum FeatureParts {
  Root = "Root",
  ColumnOne = "ColumnOne",
  ColumnTwo = "ColumnTwo",
  Grid = "Grid",
  GridItem = "GridItem",
}

export enum FeatureVariant {
  Default = "default",
}
