export enum TextParts {
  Root = "Root",
}

export enum TextVariant {
  Body = "body",
  Leading = "leading",
  Small = "small",
  Sliding = "sliding",
}

export enum TextSize {
  Md = "md",
  Base = "base",
  Sm = "sm",
  Xs = "xs",
  Fluid = "fluid",
  FluidSm = "fluid-sm",
  FluidXs = "fluid-xs",
}

export enum TextContrast {
  High = "high",
  Medium = "medium",
  Low = "low",
  Lower = "lower",
}
