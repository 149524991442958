import { useCompConfig } from "@hybrbase/system";
import { GridItemConfig } from "../styles/GridItem.config";
import { GridItemOffset, GridItemSize } from "../types/GridItem.constants";
import { TGridItemConfigReturn } from "../types/GridItem.config.types";
import { useGridContext } from "./grid-context";

export interface UseGridItemProps {
  size?: GridItemSize;
  offset?: GridItemOffset;
}

/**
 * Grid hook that manages all the logic
 * and returns prop getters, state and actions.
 *
 * @param props
 */
export const useGridItem = (props: UseGridItemProps) => {
  const { size, offset } = props;

  const { variant, isDebug, styles: rootStyles } = useGridContext();
  const { styles }: TGridItemConfigReturn = useCompConfig(GridItemConfig, {
    variant,
    css: { size, offset },
  });

  return {
    variant,
    isDebug,
    styles,
    rootStyles,
  };
};

export type UseGridItemReturn = ReturnType<typeof useGridItem>;
