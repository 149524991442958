import { TextConfig } from "../styles/Text.config";
import { TextContrast, TextSize, TextVariant } from "../types/Text.constants";
import { TTextConfigReturn, TTextHtml } from "../types/Text.config.types";
import {
  HTMLProps,
  cx,
  forwardRef,
  useCompConfig,
  ForwardRefComponent,
} from "@hybrbase/system";
import React, { useMemo } from "react";
import { FontFamily, FontWeight, cssVars } from "@hybrbase/themes";

const textAsHtml: Record<TextVariant, TTextHtml> = {
  [TextVariant.Body]: `p`,
  [TextVariant.Leading]: `p`,
  [TextVariant.Small]: `p`,
  [TextVariant.Sliding]: `p`,
};

const textSize: Record<TextVariant, TextSize> = {
  [TextVariant.Body]: TextSize.Base,
  [TextVariant.Leading]: TextSize.Fluid,
  [TextVariant.Small]: TextSize.Fluid,
  [TextVariant.Sliding]: TextSize.Fluid,
};

const textWeight: Record<TextVariant, FontWeight> = {
  [TextVariant.Body]: FontWeight.Regular,
  [TextVariant.Leading]: FontWeight.Regular,
  [TextVariant.Small]: FontWeight.Regular,
  [TextVariant.Sliding]: FontWeight.Regular,
};

const headingContrast: Record<TextVariant, TextContrast> = {
  [TextVariant.Body]: TextContrast.High,
  [TextVariant.Leading]: TextContrast.High,
  [TextVariant.Small]: TextContrast.High,
  [TextVariant.Sliding]: TextContrast.High,
};

const textFont: Record<TextVariant, FontFamily> = {
  [TextVariant.Body]: FontFamily.Primary,
  [TextVariant.Leading]: FontFamily.Primary,
  [TextVariant.Small]: FontFamily.Primary,
  [TextVariant.Sliding]: FontFamily.Primary,
};

export interface TextData {}

export interface TextOptions {
  /**
   * Variants for `Text`. You can extend the variant.
   */
  variant?: TextVariant;
  /**
   * Use to override default Html tag
   */
  as?: TTextHtml;
  size?: TextSize;
  contrast?: TextContrast;
  /**
   * Remove the top space of a text element that is caused by the element line-height
   */
  lhCrop?: number;
}
export interface TextProps
  extends Omit<HTMLProps<any>, keyof TextData>,
    TextOptions,
    TextData {}

type TextParts = ForwardRefComponent<any, TextProps>;

/**
 * Text are used for text and paragraphs.
 */
export const Text: TextParts = forwardRef<TextProps, any>((props, ref) => {
  const {
    variant = TextVariant.Body,
    size,
    as,
    contrast = TextContrast.High,
    className,
    children,
    lhCrop,
    ...rest
  } = props;

  const defaultSetting = useMemo(
    () => ({
      Component: as || textAsHtml[variant],
      size: size || textSize[variant],
      contrast: contrast || headingContrast[variant],
      weight: textWeight[variant],
      font: textFont[variant],
      lhCrop,
    }),
    [variant, contrast, as, size, lhCrop]
  );

  const { styles }: TTextConfigReturn = useCompConfig(TextConfig, {
    variant,
    css: {
      contrast: defaultSetting.contrast,
      size: defaultSetting.size,
      weight: defaultSetting.weight,
      font: defaultSetting.font,
    },
  });

  return (
    <defaultSetting.Component
      data-comp="text"
      data-variant={variant}
      className={cx(styles.Root, className)}
      ref={ref}
      {...rest}
    >
      {defaultSetting.lhCrop ? (
        <span
          className="block h-0 w-0 -mt-[var(--text-lh-crop)]"
          style={
            {
              ["--text-lh-crop"]: `calc(${cssVars.lineHeight.body} * ${defaultSetting.lhCrop}rem)`,
            } as JSX.IntrinsicElements["style"]
          }
        />
      ) : null}
      {children}
    </defaultSetting.Component>
  );
});

Text.displayName = `Text`;

export default Text;
