import React from "react";
import { forwardRef, ForwardRefComponent } from "@hybrbase/system";

import {
  useCarousel,
  UseCarouselProps,
  UseCarouselReturn,
} from "../utils/use-carousel";
import { CarouselProvider } from "../utils/carousel-context";
import { CarouselSlides } from "./CarouselSlides";

export interface CarouselData {}

export interface CarouselOptions {}
export interface CarouselProps
  extends CarouselOptions,
    CarouselData,
    UseCarouselProps {
  children?:
    | React.ReactNode
    | ((
        props: Omit<UseCarouselReturn, "emblaRef"> & { total: number }
      ) => React.ReactNode);
}

type CarouselParts = ForwardRefComponent<any, CarouselProps> & {
  Slides?: typeof CarouselSlides;
};

export const Carousel: CarouselParts = forwardRef<CarouselProps, any>(
  (props) => {
    const { children, emblaApi, enabled, ...rest } = props;

    const { ...context } = useCarousel({ emblaApi, enabled, ...rest });
    const ctx = React.useMemo(() => context, [context]);

    return (
      <CarouselProvider value={ctx}>
        {typeof children === "function"
          ? children({
              currentIndex: ctx.currentIndex,
              setCurrentIndex: ctx.setCurrentIndex,
              scrollPrev: ctx.scrollPrev,
              scrollNext: ctx.scrollNext,
              scrollTo: ctx.scrollTo,
              total: children.length,
            })
          : null}
      </CarouselProvider>
    );
  }
);

Carousel.Slides = CarouselSlides;

Carousel.displayName = `Carousel`;
Carousel.Slides.displayName = "Carousel.Slides";

export default Carousel;
