import usePageStore from "@/stores/page";
import { useSafeLayoutEffect } from "hooks";

export const useScroll = (callback, deps = []) => {
  const lenis = usePageStore(({ lenis }) => lenis);

  useSafeLayoutEffect(() => {
    if (!lenis) return;
    lenis.on("scroll", callback);
    lenis.notify();

    return () => {
      lenis.off("scroll", callback);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lenis, callback, [...deps]]);
};
