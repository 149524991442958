export const hostRegex = /(img.hybrbase.com|a.storyblok.com)/g;

export const isValidImageHostname = (src: string, validHostRegex: RegExp) =>
  new RegExp(validHostRegex).test(src);

export type AssetService = {
  src: string;
  param?: string;
  replaceHostImage?: string;
  validHostRegex?: RegExp;
  cms?: "storyblok";
};

export const getImageService = ({
  src,
  param,
  replaceHostImage,
  cms = "storyblok",
}: AssetService) => {
  const replaceHostDomain = src?.replace(
    hostRegex,
    replaceHostImage || "a2.storyblok.com"
  );

  // get image service from Storyblok
  if (isValidImageHostname(src, hostRegex) && cms === "storyblok") {
    return typeof param === "undefined"
      ? `${replaceHostDomain}/m/`
      : `${replaceHostDomain}/m/${param}`;
  }

  return src;
};

export const getVideoService = ({
  src,
  replaceHostImage,
  cms = "storyblok",
}: AssetService) => {
  const replaceHostDomain = src?.replace(
    hostRegex,
    replaceHostImage || "a2.storyblok.com"
  );

  // get image service from Storyblok
  if (isValidImageHostname(src, hostRegex) && cms === "storyblok") {
    return replaceHostDomain;
  }

  return src;
};
