import React from "react";
import {
  HTMLProps,
  cx,
  forwardRef,
  useCompConfig,
  ForwardRefComponent,
} from "@hybrbase/system";
import { AspectRatioImageConfig } from "../styles/AspectRatioImage.config";
import { AspectRatioImageVariant } from "../types/AspectRatioImage.constants";
import { AspectRatio, AspectRatioProps } from "layout";
import { Image, ImageProps } from "../../../image/index";
import { TAspectRatioImageConfigReturn } from "../types/AspectRatioImage.config.types";

export interface AspectRatioImageData {
  aspectRatio?: AspectRatioProps;
  image?: ImageProps;
}

export interface AspectRatioImageOptions {
  /**
   * Variants for `AspectRatioImage`. You can extend the variant.
   */
  variant?: AspectRatioImageVariant;
}
export interface AspectRatioImageProps
  extends Omit<HTMLProps<"div">, keyof AspectRatioImageData>,
    AspectRatioImageOptions,
    AspectRatioImageData {}

type AspectRatioImageParts = ForwardRefComponent<
  "div",
  AspectRatioImageProps
> & {};

export const AspectRatioImage: AspectRatioImageParts = forwardRef<
  AspectRatioImageProps,
  "div"
>((props, ref) => {
  const {
    variant = AspectRatioImageVariant.Default,
    className,
    children,
    image,
    aspectRatio,
    ...rest
  } = props;

  const { styles }: TAspectRatioImageConfigReturn = useCompConfig(
    AspectRatioImageConfig,
    { variant }
  );

  return (
    <div
      data-comp="aspect-ratio-image"
      data-variant={variant}
      className={cx(styles.Root, className)}
      ref={ref}
      {...rest}
    >
      <AspectRatio {...aspectRatio}>
        <Image
          {...image}
          src={image?.src}
          alt={image?.alt}
          width={image?.width ? image?.width : null}
          height={image?.height ? image?.height : null}
        />
      </AspectRatio>
    </div>
  );
});

AspectRatioImage.displayName = `AspectRatioImage`;

export default AspectRatioImage;
