import React from "react";
import { cx, HTMLProps } from "@hybrbase/system";
import { useVideoContext } from "../utils";
import { cssVars } from "@hybrbase/themes";
import { VideoVariant } from "../types/Video.constants";

export interface VideoCaptionsData {}

export interface VideoCaptionsOptions {}
export interface VideoCaptionsProps
  extends VideoCaptionsOptions,
    VideoCaptionsData,
    HTMLProps<"div"> {
  /**
   * Use the className prop in Video to add classes
   */
  className?: string;
  children?: React.ReactNode;
}

/**
 *
 */
export const VideoCaptions: React.FC<VideoCaptionsProps> = ({
  className,
  children,
  ...rest
}) => {
  const { variant, styles, isShowingCaptions, currentCaptions } =
    useVideoContext();

  const { rootStyle } = React.useMemo(() => {
    switch (variant) {
      case VideoVariant.Default:
      default:
        return {
          rootStyle: {
            "--video-captions-bottom-pos": "6rem",
            "--video-captions-bottom-collapsed-pos": "3rem",
            "--video-captions-bg-color": `rgba(0, 0, 0, 0.8)`,
            "--video-captions-text-color": cssVars.colors.white,
            "--video-captions-transition": "transform .3s ease-in",
          } as JSX.IntrinsicElements["style"],
        };
    }
  }, [variant]);

  return (
    <>
      {isShowingCaptions && currentCaptions ? (
        <div
          className={cx(
            "video-captions",
            { "video-captions--visible": isShowingCaptions },
            styles.Captions,
            className
          )}
          style={{ ...rootStyle }}
          {...rest}
        >
          {currentCaptions ? <p>{currentCaptions}</p> : null}
        </div>
      ) : null}
    </>
  );
};

VideoCaptions.displayName = `VideoCaptions`;
