import { useSafeLayoutEffect } from "hooks";
import { useState } from "react";

export const useSbPreview = () => {
  const [isSbPreview, setSbPreview] = useState<boolean>(false);

  useSafeLayoutEffect(() => {
    if (window?.location?.search?.includes("_storyblok=")) {
      setSbPreview(true);
    }
  }, []);
  return isSbPreview;
};

export default useSbPreview;
