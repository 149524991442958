import { LinkFxParts, LinkFxVariant } from "../types/LinkFx.constants";
import { TLinkFxConfigProps } from "../types/LinkFx.config.types";

export const LinkFxConfig: TLinkFxConfigProps = {
  parts: Object.values(LinkFxParts),
  css: {
    variants: {
      [LinkFxVariant.Default]: {
        Root: "hover:!text-accent",
      },
    },
  },
};
