import { callAllHandlers, useCompConfig } from "@hybrbase/system";
import { mergeRefs } from "@hybrbase/system";
import { useControllableState } from "hooks";
import { useCallback, useEffect, useRef, useState } from "react";
import { AccordionVariant } from "../types/Accordion.constants";
import {
  useAccordionContext,
  useAccordionDescendant,
  useAccordionDescendants,
} from "./accordion-context";
import { uid } from "react-uid";
import { TAccordionConfigReturn } from "../types/Accordion.config.types";
import { AccordionConfig } from "../styles/Accordion.config";

export type ExpandedIndex = number | number[];

export interface UseAccordionProps {
  variant?: AccordionVariant;
  /**
   * If `true`, multiple accordion items can be expanded at once.
   */
  allowMultiple?: boolean;
  /**
   * The index(es) of the expanded accordion item
   */
  index?: ExpandedIndex;
  /**
   * The initial index(es) of the expanded accordion item
   */
  defaultIndex?: ExpandedIndex;
  /**
   * If `true`, any expanded accordion item can be collapsed again.
   */
  allowToggle?: boolean;
  /**
   * The callback invoked when accordion items are expanded or collapsed.
   */
  onChange?(expandedIndex: ExpandedIndex): void;
}

/**
 * Accordion hook that manages all the logic
 * and returns prop getters, state and actions.
 *
 * @param props
 */
export const useAccordion = (props: UseAccordionProps) => {
  const {
    variant,
    onChange,
    defaultIndex,
    index: currentIndex,
    allowMultiple,
    allowToggle = true,
  } = props;

  const { styles }: TAccordionConfigReturn = useCompConfig(AccordionConfig, {
    variant,
  });

  /**
   * Think of this as the register to each accordion item.
   * We used to manage focus between accordion item buttons.
   *
   * Every accordion item, registers their button refs in this context
   */
  const descendants = useAccordionDescendants();

  /**
   * This state is used to track the index focused accordion
   * button when click on the button, tab on the button, or
   * use the down/up arrow to navigate.
   */
  const [focusedIndex, setFocusedIndex] = useState(-1);

  /**
   * Reset focused index when accordion unmounts
   * or descendants change
   */
  useEffect(() => {
    return () => {
      setFocusedIndex(-1);
    };
  }, []);

  /**
   * Hook that manages the controlled and un-controlled state
   * for the accordion.
   */
  const [index, setIndex] = useControllableState({
    value: currentIndex,
    defaultValue() {
      if (allowMultiple) return defaultIndex ?? [];
      return defaultIndex ?? -1;
    },
    onChange,
  });

  /**
   * Gets the `isOpen` and `onChange` props for a child accordion item based on
   * the child's index.
   *
   * @param idx {number} The index of the child accordion item
   */
  const getAccordionItemProps = (idx: number | null) => {
    let isOpen = false;

    if (idx !== null) {
      isOpen = Array.isArray(index) ? index.includes(idx) : index === idx;
    }

    const onChange = (isOpen: boolean) => {
      if (idx === null) return;

      if (allowMultiple && Array.isArray(index)) {
        //
        const nextState = isOpen
          ? index.concat(idx)
          : index.filter((i) => i !== idx);

        setIndex(nextState);
        //
      } else if (isOpen) {
        setIndex(idx);
      } else if (allowToggle) {
        setIndex(-1);
      }
    };

    return { isOpen, onChange };
  };

  return {
    variant,
    styles,
    descendants,
    focusedIndex,
    setFocusedIndex,
    getAccordionItemProps,
    setIndex,
  };
};

export type UseAccordionReturn = ReturnType<typeof useAccordion>;

export interface UseAccordionItemProps {
  /**
   * If `true`, the accordion item will be disabled.
   */
  isDisabled?: boolean;
  /**
   * If `true`, the accordion item will be focusable.
   */
  isFocusable?: boolean;
  /**
   * A unique id for the accordion item.
   */
  id?: string;
}

/**
 * useAccordionItem
 *
 * React hook that provides the open/close functionality
 * for an accordion item and its children
 */
export function useAccordionItem(props: UseAccordionItemProps) {
  const { isDisabled, isFocusable, id, ...htmlProps } = props;
  const { getAccordionItemProps, setFocusedIndex } = useAccordionContext();

  const buttonRef = useRef<HTMLElement>(null);

  /**
   * Think of this as a way to register this accordion item
   * with its parent `useAccordion`
   */
  const { register, index, descendants } = useAccordionDescendant({
    disabled: isDisabled && !isFocusable,
  });

  const _uid = id ?? uid(index);

  /**
   * Generate unique ids for all accordion item components (button and panel)
   */

  const buttonId = `accordion-button-${_uid}`;
  const panelId = `accordion-panel-${_uid}`;

  const { isOpen, onChange } = getAccordionItemProps(
    index === -1 ? null : index
  );

  const onOpen = () => {
    onChange?.(true);
  };

  const onClose = () => {
    onChange?.(false);
  };

  /**
   * Toggle the visibility of the accordion item
   */
  const onClick = useCallback(() => {
    onChange?.(!isOpen);
    setFocusedIndex(index);
  }, [index, setFocusedIndex, isOpen, onChange]);

  /**
   * Manage keyboard navigation between accordion items.
   */
  const onKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      const keyMap: Record<string, React.KeyboardEventHandler> = {
        ArrowDown: () => {
          const next = descendants.nextEnabled(index);
          next?.node.focus();
        },
        ArrowUp: () => {
          const prev = descendants.prevEnabled(index);
          prev?.node.focus();
        },
        Home: () => {
          const first = descendants.firstEnabled();
          first?.node.focus();
        },
        End: () => {
          const last = descendants.lastEnabled();
          last?.node.focus();
        },
      };

      const action = keyMap[event.key];

      if (action) {
        event.preventDefault();
        action(event);
      }
    },
    [descendants, index]
  );

  /**
   * Since each accordion item's button still remains tabbable, let's
   * update the focusedIndex when it receives focus
   */
  const onFocus = useCallback(() => {
    setFocusedIndex(index);
  }, [setFocusedIndex, index]);

  const getButtonProps = useCallback(
    function getButtonProps(
      props: React.HTMLAttributes<HTMLElement> = {},
      ref: React.Ref<HTMLButtonElement> | null = null
    ): React.ComponentProps<"button"> {
      return {
        ...props,
        type: "button",
        ref: mergeRefs(register, buttonRef, ref),
        id: buttonId,
        disabled: !!isDisabled,
        "aria-expanded": !!isOpen,
        "aria-controls": panelId,
        onClick: callAllHandlers(props.onClick, onClick),
        onFocus: callAllHandlers(props.onFocus, onFocus),
        onKeyDown: callAllHandlers(props.onKeyDown, onKeyDown),
      };
    },
    [
      buttonId,
      isDisabled,
      isOpen,
      onClick,
      onFocus,
      onKeyDown,
      panelId,
      register,
    ]
  );

  const getPanelProps = useCallback(
    function getPanelProps<T>(
      props: Omit<React.HTMLAttributes<T>, "color"> = {},
      ref: React.Ref<T> | null = null
    ): React.HTMLAttributes<T> & React.RefAttributes<T> {
      return {
        ...props,
        ref,
        role: "region",
        id: panelId,
        "aria-labelledby": buttonId,
        hidden: !isOpen,
      };
    },
    [buttonId, isOpen, panelId]
  );

  return {
    isOpen,
    isDisabled,
    isFocusable,
    onOpen,
    onClose,
    getButtonProps,
    getPanelProps,
    htmlProps,
  };
}

export type UseAccordionItemReturn = ReturnType<typeof useAccordionItem>;
