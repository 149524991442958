import { HeadingConfig } from "../styles/Heading.config";
import {
  HeadingContrast,
  HeadingSize,
  HeadingVariant,
} from "../types/Heading.constants";
import {
  THeadingConfigReturn,
  THeadingHtml,
} from "../types/Heading.config.types";
import {
  HTMLProps,
  cx,
  forwardRef,
  useCompConfig,
  ForwardRefComponent,
} from "@hybrbase/system";
import { useMemo } from "react";
import { FontFamily, FontWeight, cssVars } from "@hybrbase/themes";

const headingAsHtml: Record<HeadingVariant, THeadingHtml> = {
  [HeadingVariant.Hero]: `h1`,
  [HeadingVariant.Display]: `h2`,
  [HeadingVariant.Section]: `h2`,
  [HeadingVariant.SubTitle]: `h3`,
  [HeadingVariant.Feature]: `h3`,
  [HeadingVariant.Card]: `h3`,
  [HeadingVariant.List]: `h3`,
  [HeadingVariant.Clickable]: `h3`,
};

const headingSize: Record<HeadingVariant, HeadingSize> = {
  [HeadingVariant.Hero]: HeadingSize.FluidXl,
  [HeadingVariant.Display]: HeadingSize.FluidXl,
  [HeadingVariant.Section]: HeadingSize.FluidLg,
  [HeadingVariant.SubTitle]: HeadingSize.Base,
  [HeadingVariant.Feature]: HeadingSize.Fluid,
  [HeadingVariant.Card]: HeadingSize.Fluid,
  [HeadingVariant.List]: HeadingSize.Fluid,
  [HeadingVariant.Clickable]: HeadingSize.Fluid,
};

const headingContrast: Record<HeadingVariant, HeadingContrast> = {
  [HeadingVariant.Hero]: HeadingContrast.Higher,
  [HeadingVariant.Display]: HeadingContrast.Higher,
  [HeadingVariant.Section]: HeadingContrast.Higher,
  [HeadingVariant.SubTitle]: HeadingContrast.Medium,
  [HeadingVariant.Feature]: HeadingContrast.Higher,
  [HeadingVariant.Card]: HeadingContrast.Higher,
  [HeadingVariant.List]: HeadingContrast.Higher,
  [HeadingVariant.Clickable]: HeadingContrast.Higher,
};

const headingWeight: Record<HeadingVariant, FontWeight> = {
  [HeadingVariant.Hero]: FontWeight.Bold,
  [HeadingVariant.Display]: FontWeight.Regular,
  [HeadingVariant.Section]: FontWeight.Regular,
  [HeadingVariant.SubTitle]: FontWeight.Regular,
  [HeadingVariant.Feature]: FontWeight.Regular,
  [HeadingVariant.Card]: FontWeight.Bold,
  [HeadingVariant.List]: FontWeight.Bold,
  [HeadingVariant.Clickable]: FontWeight.Bold,
};

const headingFont: Record<HeadingVariant, FontFamily> = {
  [HeadingVariant.Hero]: FontFamily.Primary,
  [HeadingVariant.Display]: FontFamily.Primary,
  [HeadingVariant.Section]: FontFamily.Primary,
  [HeadingVariant.SubTitle]: FontFamily.Primary,
  [HeadingVariant.Feature]: FontFamily.Primary,
  [HeadingVariant.Card]: FontFamily.Primary,
  [HeadingVariant.List]: FontFamily.Primary,
  [HeadingVariant.Clickable]: FontFamily.Primary,
};

export interface HeadingData {}

export interface HeadingOptions {
  /**
   * Variants for `Heading`. You can extend the variant.
   */
  variant?: HeadingVariant;
  size?: HeadingSize;
  /**
   * Use to override default Html tag
   */
  as?: THeadingHtml;
  contrast?: HeadingContrast;
  /**
   * Remove the top space of a text element that is caused by the element line-height
   */
  lhCrop?: number;
}
export interface HeadingProps
  extends Omit<HTMLProps<any>, keyof HeadingData>,
    HeadingOptions,
    HeadingData {}

type HeadingParts = ForwardRefComponent<any, HeadingProps>;

/**
 * Heading - Headings are used for rendering title and headlines
 */
export const Heading: HeadingParts = forwardRef<HeadingProps, any>(
  (props, ref) => {
    const {
      variant = HeadingVariant.Section,
      as,
      contrast,
      size,
      className,
      children,
      lhCrop = 0,
      ...rest
    } = props;

    const defaultSetting = useMemo(
      () => ({
        Component: as || headingAsHtml[variant],
        contrast: contrast || headingContrast[variant],
        size: size || headingSize[variant],
        weight: headingWeight[variant],
        font: headingFont[variant],
        lhCrop,
      }),
      [variant, contrast, as, lhCrop, size]
    );

    const { styles }: THeadingConfigReturn = useCompConfig(HeadingConfig, {
      variant,
      css: {
        contrast: defaultSetting.contrast,
        size: defaultSetting.size,
        weight: defaultSetting.weight,
        font: defaultSetting.font,
      },
    });

    return (
      <defaultSetting.Component
        data-comp="heading"
        data-variant={variant}
        className={cx(styles.Root, className)}
        ref={ref}
        {...rest}
      >
        {defaultSetting.lhCrop ? (
          <span
            className="block h-0 w-0 -mt-[var(--heading-lh-crop)]"
            style={
              {
                ["--heading-lh-crop"]: `calc(${cssVars.lineHeight.heading} * ${defaultSetting.lhCrop}rem)`,
              } as JSX.IntrinsicElements["style"]
            }
          />
        ) : null}
        {children}
      </defaultSetting.Component>
    );
  }
);

Heading.displayName = `Heading`;

export default Heading;
