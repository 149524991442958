import { IntroWithAccordion } from "landing";
import { storyblokEditable } from "@hybrbase/storyblok";

import { useMemo } from "react";
import { getImageService, getVideoService, isVideo } from "@/utils";
import { getSbImageInfo } from "@/lib/storyblok";
import { AspectRatioSize } from "layout";
import { renderRichText } from "@hybrbase/storyblok";

export const SbIntroWithAccordion = ({ blok }) => {
  const { title, subtitle, link_label, link_href, items, variant, theme } =
    blok; // Component scheme

  const filteredItems = useMemo(
    () =>
      items.map((item) => {
        return {
          title: item.title,
          description: item.description,
          image: {
            src: getImageService({
              src: item?.image_src?.filename,
            }),
            alt: item?.image_src?.alt,
            title: item?.image_src?.title,
            width: getSbImageInfo(
              item?.image_src?.filename,
              AspectRatioSize.Square
            ).width,
            height: getSbImageInfo(
              item?.image_src?.filename,
              AspectRatioSize.Square
            ).height,
          },
          video: {
            src: getVideoService({ src: item?.video_src?.filename }),
            poster: getImageService({
              src: item?.video_poster?.filename,
            }),
            isBoolean: isVideo(item?.video_src?.filename),
          },
          subItems: item.list.map((listItem) => ({
            title: listItem.title,
            props: storyblokEditable(listItem),
          })),
          props: storyblokEditable(item),
        };
      }),
    [items]
  );

  return (
    <IntroWithAccordion
      title={renderRichText(title)}
      subtitle={subtitle}
      theme={theme}
      variant={variant}
      link={{
        href: link_href?.cached_url,
        isOpenNewTab: link_href?.target === "_blank",
        children: link_label,
      }}
      items={filteredItems}
      {...storyblokEditable(blok)}
    />
  );
};
