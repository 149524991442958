import dynamic from "next/dynamic";

const FeaturePolicy = dynamic(
  () => import(/* webpackChunkName: 'feature-policy' */ "./FeaturePolicy"),
  {
    ssr: false,
  }
);
const ContentSecurityPolicy = dynamic(
  () =>
    import(
      /* webpackChunkName: 'content-security-policy' */ "./ContentSecurityPolicy"
    ),
  { ssr: false }
);

export { FeaturePolicy, ContentSecurityPolicy };
