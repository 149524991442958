import { GridColumns, GridGap, GridVariant } from "../types/Grid.constants";
import { useGrid, UseGridProps } from "../utils/use-grid";
import { GridItem } from "./GridItem";
import {
  HTMLProps,
  cx,
  forwardRef,
  ForwardRefComponent,
  getValidChildren,
} from "@hybrbase/system";
import React, { useMemo } from "react";
import { uid } from "react-uid";
import { GridProvider } from "../utils/grid-context";

const gridColumns: Record<GridVariant, GridColumns> = {
  [GridVariant.Default]: GridColumns.Four,
  [GridVariant.Custom]: null,
};

export interface GridData {}

export interface GridOptions extends UseGridProps {}
export interface GridProps
  extends Omit<HTMLProps<"div">, keyof GridData>,
    GridOptions,
    GridData {}

type GridParts = ForwardRefComponent<"div", GridProps> & {
  Item?: typeof GridItem;
};

export const Grid: GridParts = forwardRef<GridProps, "div">((props, ref) => {
  const {
    variant = GridVariant.Default,
    gap = GridGap.Md,
    columns,
    className,
    children,
    isDebug,
    ...rest
  } = props;

  const defaultSetting = useMemo(
    () => ({
      columns: columns || gridColumns[variant],
    }),
    [variant, columns]
  );

  const { ...ctx } = useGrid({
    variant,
    gap,
    columns: defaultSetting.columns,
    isDebug,
  });
  const context = React.useMemo(
    () => ({ variant, isDebug, ...ctx }),
    [variant, isDebug, ctx]
  );

  return (
    <GridProvider value={context}>
      <div
        data-comp="grid"
        data-variant={variant}
        className={cx(ctx.styles.Root, className)}
        ref={ref}
        {...rest}
      >
        {getValidChildren(children).map((child, index) => {
          if (child.type === GridItem) {
            return <React.Fragment key={uid(index)}>{child}</React.Fragment>;
          } else {
            return <>{"Unsupported component -> Please use Grid.Item"}</>;
          }
        })}
      </div>
    </GridProvider>
  );
});

Grid.Item = GridItem;

Grid.displayName = "Grid";
Grid.Item.displayName = "Grid.Item";

export default Grid;
