import React from "react";
import {
  cx,
  forwardRef,
  useCompConfig,
  ForwardRefComponent,
} from "@hybrbase/system";
import { LinkFxConfig } from "../styles/LinkFx.config";
import { LinkFxVariant } from "../types/LinkFx.constants";
import { Link, LinkOptions } from "../../../link";
import { TLinkFxConfigReturn } from "../types/LinkFx.config.types";

export interface LinkFxData {}

export interface LinkFxOptions {
  /**
   * Variants for `LinkFx`. You can extend the variant.
   */
  variant?: LinkFxVariant;
}
export interface LinkFxProps extends LinkOptions, LinkFxOptions, LinkFxData {}

type LinkFxParts = ForwardRefComponent<"a", LinkFxProps> & {};

export const LinkFx: LinkFxParts = forwardRef<LinkFxProps, "a">(
  (props, ref) => {
    const {
      variant = LinkFxVariant.Default,
      className,
      children,
      // icon = {
      //   name: IconName.ArrowRight,
      // },
      ...rest
    } = props;

    const { styles }: TLinkFxConfigReturn = useCompConfig(LinkFxConfig, {
      variant,
    });
    return (
      <Link
        data-comp="link-fx"
        title={typeof children === "string" ? children.toString() : null}
        data-variant={variant}
        className={cx("link-fx cursor-pointer", styles.Root, className)}
        ref={ref}
        {...rest}
      >
        <span className="mr-xs">{children}</span>

        {/* <div className="relative w-lg h-lg -ml-sm flex justify-center items-center">
          <svg
            className="link-fx-circle -left-md"
            viewBox="0 0 32 32"
            aria-hidden="true"
          >
            <g
              fill="none"
              stroke="#d9d9d9"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <circle cx="16" cy="16" r="15.5" />
            </g>
          </svg>
          <Icon
            className="absolute right-sm mr-xxs top-xxs translate-x-full "
            {...icon}
            size={IconSize.Md}
          />
        </div> */}
      </Link>
    );
  }
);

LinkFx.displayName = `LinkFx`;

export default LinkFx;
