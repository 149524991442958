import {
  IntroWithAccordionParts,
  IntroWithAccordionVariant,
} from "../types/IntroWithAccordion.constants";
import { TIntroWithAccordionConfigProps } from "../types/IntroWithAccordion.config.types";

export const IntroWithAccordionConfig: TIntroWithAccordionConfigProps = {
  parts: Object.values(IntroWithAccordionParts),
  css: {
    variants: {
      [IntroWithAccordionVariant.Default]: {
        HeadingWrapper: "flex items-center gap-sm sm:gap-md",
        Counter: "text-sm sm:text-base",
        AccordionButtonIconWrapper: "w-md md:w-lg mt-sm",
        TextContainer: "mt-sm max-w-sm pl-xl",
        TextContainerTwo: "pl-xl",
        AccordionButton: "py-fluid-lg-to-xl",
      },

      [IntroWithAccordionVariant.Static]: {
        Accordion: "mt-lg sm:mt-0",
        HeadingWrapper: "flex items-center gap-sm sm:gap-md",
        Counter: "text-sm sm:text-base",
        TextContainer: "max-w-sm mb-md sm:mb-0",
        AccordionButton: "cursor-auto",
        GridItemOne: "sm:flex-col-2 overflow-hidden",
        GridItemTwo: "sm:flex-col-2 pb-fluid-lg-to-xxxl self-end",
        AccordionItem: "border-none -mt-md sm:mt-0",
        TextContainerTwo: "sm:mt-lg sm:max-w-sm mb-lg sm:mb-0",
      },
    },
  },
};
