import { HTMLProps, cx, forwardRef, useCompConfig } from "@hybrbase/system";
import { IntroWithAccordionConfig } from "../styles/IntroWithAccordion.config";
import { IntroWithAccordionVariant } from "../types/IntroWithAccordion.constants";
import { TIntroWithAccordionConfigReturn } from "../types/IntroWithAccordion.config.types";
import {
  Accordion,
  AspectRatioImage,
  Heading,
  HeadingVariant,
  Icon,
  IconName,
  IconSize,
  ImageProps,
  Text,
  TextSize,
  Video,
  VideoLayout,
  VideoProps,
} from "elements";
import {
  AspectRatioSize,
  Grid,
  GridGap,
  SectionData,
  TextContainer,
} from "layout";
import { BorderRadius, ThemeElement } from "@hybrbase/themes";
import { Intro, IntroData } from "../../../index";
import { uid } from "react-uid";
import { useMemo } from "react";
import { introWithAccordionFactory } from "../utils/intro-with-accordion";

export interface IntroWithAccordionData extends SectionData, IntroData {}

export interface IntroWithAccordionOptions {
  items: {
    video?: VideoProps & { isBoolean: boolean };
    image?: ImageProps;
    aspectRatioSize?: AspectRatioSize;
    title: string;
    description: string;
    props?: any;
    subItems?: {
      title: string;
      props?: any;
    }[];
  }[];
}

export interface IntroWithAccordionProps
  extends Omit<HTMLProps<"section">, keyof IntroWithAccordionData>,
    IntroWithAccordionOptions,
    IntroWithAccordionData {
  variant?: IntroWithAccordionVariant;
  theme?: ThemeElement;
}

export const IntroWithAccordion = forwardRef<
  IntroWithAccordionProps,
  "section"
>((props, ref) => {
  const {
    variant = IntroWithAccordionVariant.Default,
    className,
    theme = ThemeElement.Default,
    title,
    text,
    subtitle,
    link,
    items,
    ...rest
  } = props;

  const { styles }: TIntroWithAccordionConfigReturn = useCompConfig(
    IntroWithAccordionConfig,
    { variant }
  );

  const factory = useMemo(() => introWithAccordionFactory(variant), [variant]);
  return (
    <Intro
      data-comp="intro-with-accordion"
      data-variant={variant}
      title={title}
      subtitle={subtitle}
      link={link}
      theme={theme}
      variant={factory?.intro?.variant}
      className={cx(styles.Root, className)}
      ref={ref}
      {...rest}
    >
      <Accordion
        className={styles.Accordion}
        defaultIndex={
          variant === IntroWithAccordionVariant.Static
            ? [...items?.map((_, index) => index)]
            : null
        }
        allowToggle={factory?.accordion?.allowTogle}
      >
        {items?.map((item, index) => (
          <Accordion.Item
            key={uid(index)}
            className={cx(styles.AccordionItem, {
              "sm:!opacity-100":
                variant === IntroWithAccordionVariant.Static &&
                !item.video?.src &&
                !item.image?.src,
            })}
            {...item.props}
          >
            <Accordion.Button className={styles.AccordionButton}>
              {variant !== IntroWithAccordionVariant.Static ? (
                <>
                  <TextContainer>
                    <div className={styles.HeadingWrapper}>
                      <span className={styles.Counter}>0{index + 1}.</span>
                      <Heading variant={HeadingVariant.Clickable}>
                        {item.title}
                      </Heading>
                    </div>
                  </TextContainer>
                  <div className={styles.AccordionButtonIconWrapper}>
                    <Icon name={IconName.Plus} size={IconSize.Auto} />
                  </div>
                </>
              ) : null}
            </Accordion.Button>
            <Accordion.Panel>
              <Grid gap={GridGap.Xl}>
                {item.video?.src || item.image?.src ? (
                  <Grid.Item
                    className={styles.GridItemOne}
                    data-theme-element={theme}
                  >
                    {item?.video?.isBoolean ? (
                      <Video
                        src={item.video.src}
                        poster={item.video.poster}
                        layout={VideoLayout.Ratio}
                        aspectRatio={{
                          radius: BorderRadius.Lg,
                          size: item.aspectRatioSize || AspectRatioSize.Square,
                        }}
                        hasTogglePlayBtn={false}
                      />
                    ) : (
                      <AspectRatioImage
                        image={item.image}
                        aspectRatio={{
                          radius: BorderRadius.Lg,
                          size: item.aspectRatioSize || AspectRatioSize.Square,
                        }}
                      />
                    )}
                  </Grid.Item>
                ) : null}
                <Grid.Item
                  className={cx(styles.GridItemTwo, {
                    "sm:flex sm:flex-col-4 border-b-1 border-solid border-contrast-high":
                      variant === IntroWithAccordionVariant.Static &&
                      !item.video?.src &&
                      !item.image?.src,
                  })}
                >
                  <TextContainer
                    className={cx(styles.TextContainer, {
                      "sm:flex-col-2":
                        variant === IntroWithAccordionVariant.Static &&
                        !item.video?.src &&
                        !item.image?.src,
                    })}
                  >
                    {variant === IntroWithAccordionVariant.Static ? (
                      <div className={styles.HeadingWrapper}>
                        <span className={styles.Counter}>0{index + 1}.</span>
                        <Heading variant={HeadingVariant.Clickable}>
                          {item.title}
                        </Heading>
                      </div>
                    ) : null}
                  </TextContainer>
                  <TextContainer
                    className={cx(styles.TextContainerTwo, {
                      "sm:flex-col-2":
                        variant === IntroWithAccordionVariant.Static &&
                        !item.video?.src &&
                        !item.image?.src,
                    })}
                  >
                    <Text size={TextSize.FluidSm} className="!mb-lg">
                      {item.description}
                    </Text>
                    {item.subItems?.map((subItem) => {
                      return (
                        <div
                          className={cx(
                            "pt-sm text-contrast-high border-t-1 border-solid border-contrast-low py-sm",
                            {
                              "sm:-mr-xl":
                                variant === IntroWithAccordionVariant.Static,
                            }
                          )}
                          key={uid(subItem)}
                          {...subItem.props}
                        >
                          <Text
                            className="ml-xxs !my-0"
                            size={TextSize.FluidSm}
                          >
                            {subItem.title}
                          </Text>
                        </div>
                      );
                    })}
                  </TextContainer>
                </Grid.Item>
              </Grid>
            </Accordion.Panel>
          </Accordion.Item>
        ))}
      </Accordion>
    </Intro>
  );
});

IntroWithAccordion.displayName = `IntroWithAccordion`;

export default IntroWithAccordion;
