import usePageStore from "@/stores/page";
import { useMeasure, useSafeLayoutEffect } from "hooks";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

export const useHashScrollTo = () => {
  const lenis = usePageStore(({ lenis }) => lenis);
  const router = useRouter();

  const [hash, setHash] = useState<string>();
  const [ref, { height }] = useMeasure({ debounce: 100 });

  useEffect(() => {
    if (lenis && hash) {
      // scroll to on hash change
      const target = document.querySelector(hash);
      lenis.scrollTo(target, { offset: -1.1 * height });
    }
  }, [lenis, hash, height]);

  useSafeLayoutEffect(() => {
    // update scroll position on page refresh based on hash
    if (router.asPath.includes("#")) {
      const hash = router.asPath.split("#").pop();
      setHash("#" + hash);
    }
  }, [router]);

  useSafeLayoutEffect(() => {
    // catch anchor links clicks
    function onClick(e) {
      e.preventDefault();
      const node = e.currentTarget;
      const hash = node.href.split("#").pop();
      setHash("#" + hash);
      setTimeout(() => {
        window.location.hash = hash;
      }, 0);
    }

    const internalLinks = [...document.querySelectorAll("[href]")].filter(
      (node: HTMLAnchorElement) => node.href.includes(router.pathname + "#")
    );

    internalLinks.forEach((node) => {
      node.addEventListener("click", onClick, false);
    });

    return () => {
      internalLinks.forEach((node) => {
        node.removeEventListener("click", onClick, false);
      });
    };
  }, []);

  return { headerRef: ref, headerHeight: height };
};
