import { ImageLayout, ImageParts } from "../types/Image.constants";
import { TImageConfigProps } from "../types/Image.config.types";

const styles = {
  layout: {
    fill: "object-cover w-full h-full",
    contain: "object-contain w-full h-full",
    none: "object-none w-full h-full",
  },
};

export const ImageConfig: TImageConfigProps = {
  parts: Object.values(ImageParts),
  css: {
    variants: {},
    props: {
      customLayout: {
        [ImageLayout.BackgroundCover]: {
          Root: "absolute h-screen w-screen overflow-hidden -z-1",
          Image: styles.layout.fill,
        },
        [ImageLayout.FillCover]: {
          Root: "relative",
          Image: styles.layout.fill,
        },
        [ImageLayout.FillContain]: {
          Root: "relative",
          Image: styles.layout.contain,
        },
        [ImageLayout.FillNone]: {
          Root: "relative",
          Image: styles.layout.none,
        },
        [ImageLayout.Responsive]: {
          Image: "w-full h-auto",
        },
      },
    },
  },
};
