import React, { Fragment } from "react";
import {
  HTMLProps,
  cx,
  forwardRef,
  useCompConfig,
  ForwardRefComponent,
} from "@hybrbase/system";
import { MarqueeConfig } from "../styles/Marquee.config";
import { MarqueeVariant } from "../types/Marquee.constants";
import { useMarquee, UseMarqueeProps } from "../utils/use-marquee";
import { MarqueeProvider } from "../utils/marquee-context";
import { TMarqueeConfigReturn } from "../types/Marquee.config.types";

export interface MarqueeData {}

export interface MarqueeOptions {
  repeat?: number;
  /**
   * Whether to play or pause the marquee.
   * Type: boolean
   * Default: true
   */
  isPlaying?: boolean;
  /**
   * Whether to pause the marquee when hovered
   * Type: boolean
   * Default: false
   */
  isPauseOnHover?: boolean;
  /**
   * Whether to pause the marquee when clicked
   * Type: boolean
   * Default: false
   */
  isPauseOnClick?: boolean;
  /**
   * The direction the marquee is sliding
   * Type: "left" or "right"
   * Default: "left"
   */
  direction?: "left" | "right";
  /**
   * Duration to delay the animation after render, in seconds
   * Type: number
   * Default: 0
   */
  delay?: number;
  /**
   * The number of times the marquee should loop, 0 is equivalent to infinite
   * Type: number
   * Default: 0
   */
  loop?: number;
  /**
   * Whether to show the gradient or not
   * Type: boolean
   * Default: true
   */
  isGradient?: boolean;
  /**
   * The rgb color of the gradient as an array of length 3
   * Type: Array<number> of length 3
   * Default: [255, 255, 255]
   */
  gradientColor?: [number, number, number];
  /**
   * The width of the gradient on either side
   * Type: string
   * Default: 200
   */
  gradientWidth?: number | string;
  /**
   * A callback for when the marquee finishes scrolling and stops. Only calls if loop is non-zero.
   * Type: Function
   * Default: null
   */
  onFinish?: () => void;
  /**
   * A callback for when the marquee finishes a loop. Does not call if maximum loops are reached (use onFinish instead).
   * Type: Function
   * Default: null
   */
  onCycleComplete?: () => void;
}
export interface MarqueeProps
  extends Omit<HTMLProps<"div">, keyof MarqueeData>,
    MarqueeOptions,
    MarqueeData,
    UseMarqueeProps {}

type MarqueeParts = ForwardRefComponent<"div", MarqueeProps> & {};

export const Marquee: MarqueeParts = forwardRef<MarqueeProps, "div">(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  (props, _) => {
    const {
      variant = MarqueeVariant.Default,
      className,
      children,
      speed = 20,
      isPlaying = true,
      isPauseOnHover = false,
      isPauseOnClick = false,
      direction = "left",
      repeat = 3,
      delay = 0,
      loop = 0,
      isGradient = false,
      gradientColor = [255, 255, 255],
      gradientWidth = 200,
      onFinish,
      onCycleComplete,
      ...rest
    } = props;

    const { ...ctx } = useMarquee({ variant, speed, ...rest });
    const context = React.useMemo(() => ctx, [ctx]);

    const { styles }: TMarqueeConfigReturn = useCompConfig(MarqueeConfig, {
      variant,
    });

    // Gradient color in an unfinished rgba format
    const rgbaGradientColor = `rgba(${gradientColor[0]}, ${gradientColor[1]}, ${gradientColor[2]}`;

    return (
      <MarqueeProvider value={context}>
        {!ctx.isMounted ? null : (
          <div
            data-comp="marquee"
            data-variant={variant}
            className={cx("marquee", styles.Root, className)}
            ref={ctx.containerRef}
            style={{
              ["--marquee-pause-on-hover" as string]:
                !isPlaying || isPauseOnHover ? "paused" : "running",
              ["--marquee-pause-on-click" as string]:
                !isPlaying ||
                (isPauseOnHover && !isPauseOnClick) ||
                isPauseOnClick
                  ? "paused"
                  : "running",
            }}
          >
            {isGradient && (
              <div
                style={{
                  ["--marquee-gradient-color" as string]: `${rgbaGradientColor}, 1), ${rgbaGradientColor}, 0)`,
                  ["--marquee-gradient-width" as string]:
                    typeof gradientWidth === "number"
                      ? `${gradientWidth}px`
                      : gradientWidth,
                }}
                className="marquee-overlay"
              />
            )}
            <div
              ref={ctx.marqueeRef}
              style={{
                ["--marquee-play" as string]: isPlaying ? "running" : "paused",
                ["--marquee-direction" as string]:
                  direction === "left" ? "normal" : "reverse",
                ["--marquee-duration" as string]: `${ctx.duration}s`,
                ["--marquee-delay" as string]: `${delay}s`,
                ["--marquee-iteration-count" as string]: !!loop
                  ? `${loop}`
                  : "infinite",
              }}
              className="marquee-inner"
              onAnimationIteration={onCycleComplete}
              onAnimationEnd={onFinish}
            >
              {new Array(repeat).fill(children).map((_, i) => (
                <Fragment key={i}>{children}</Fragment>
              ))}
            </div>
          </div>
        )}
      </MarqueeProvider>
    );
  }
);

Marquee.displayName = `Marquee`;

export default Marquee;
