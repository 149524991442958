import React from "react";
import { HTMLProps, cx, forwardRef, useCompConfig } from "@hybrbase/system";
import { BenefitConfig } from "../styles/Benefit.config";
import { BenefitVariant } from "../types/Benefit.constants";
import {
  Heading,
  HeadingSize,
  HeadingVariant,
  ImageProps,
  Marquee,
  Text,
  TextVariant,
  VideoLayout,
  VideoProps,
} from "elements";
import {
  Section,
  SectionData,
  Container,
  TextContainer,
  Grid,
  Tile,
  TileXSpace,
  AspectRatioSize,
  AspectRatioLayout,
  GridGap,
} from "layout";
import { ThemeElement } from "@hybrbase/themes";
import { TBenefitConfigReturn } from "../types/Benefit.config.types";
import dynamic from "next/dynamic";
import { uid } from "react-uid";

const LoadableAspectRatioImage = dynamic(
  () => import("elements/src/aspect-ratio-image/src/parts/AspectRatioImage")
);

const LoadableVideo = dynamic(
  () => import("elements/src/video/src/parts/Video")
);

export interface BenefitData extends SectionData {
  video?: VideoProps;
  image?: ImageProps;
  items: {
    title: string;
  }[];
}

export interface BenefitOptions {
  variant?: BenefitVariant;
  theme?: ThemeElement;
  isVideo?: boolean;
}

export interface BenefitProps
  extends Omit<HTMLProps<"section">, keyof BenefitData>,
    BenefitOptions,
    BenefitData {}

export const Benefit = forwardRef<BenefitProps, "section">((props, ref) => {
  const {
    variant = BenefitVariant.Default,
    className,
    theme = ThemeElement.Dark,
    title,
    subtitle,
    text,
    image,
    video,
    items,
    isVideo,
    ...rest
  } = props;

  const { styles }: TBenefitConfigReturn = useCompConfig(BenefitConfig, {
    variant,
  });

  return (
    <Section
      data-comp="benefit"
      data-variant={variant}
      theme={theme}
      className={cx(styles.Root, className)}
      ref={ref}
      {...rest}
    >
      <Container
        className={cx("mb-0 pb-0 px-md w-full", {
          "bg-black": theme === ThemeElement.Dark,
        })}
      >
        <Grid gap={GridGap.None} className="">
          <Grid.Item className="pb-fluid-xxl-to-7xl">
            <Tile className="">
              {subtitle && (
                <TextContainer className="mb-md">
                  <Heading variant={HeadingVariant.SubTitle}>
                    {subtitle}
                  </Heading>
                </TextContainer>
              )}
              <TextContainer>
                <Heading
                  variant={HeadingVariant.Section}
                  size={HeadingSize.FluidLg}
                >
                  {title}
                </Heading>
                {text ? <Text>{text}</Text> : null}
              </TextContainer>
            </Tile>
          </Grid.Item>
          <Grid.Item>
            {items?.length > 0 ? (
              <Marquee repeat={20} speed={50}>
                <ul className="flex p-0">
                  {items?.map((item, index) => (
                    <React.Fragment key={uid(index)}>
                      <li className="mr-lg sm:mr-xxl">
                        <Text variant={TextVariant.Sliding}>{item.title}</Text>
                      </li>
                      <li className="mr-lg sm:mr-xxl">
                        <Text variant={TextVariant.Sliding}>{"·"}</Text>
                      </li>
                    </React.Fragment>
                  ))}
                </ul>
              </Marquee>
            ) : null}
            {video?.src || image?.src ? (
              <Tile spaceX={TileXSpace.None} className={"-mx-md"}>
                {isVideo ? (
                  <LoadableVideo
                    {...video}
                    hasTogglePlayBtn={false}
                    layout={VideoLayout.Ratio}
                    autoPlay
                    muted
                    aspectRatio={{
                      size: AspectRatioSize.SquareToSixteenDivideByNine,
                      layout: AspectRatioLayout.Cover,
                    }}
                  />
                ) : (
                  <LoadableAspectRatioImage
                    image={image}
                    aspectRatio={{
                      size: AspectRatioSize.SquareToSixteenDivideByNine,
                      layout: AspectRatioLayout.Cover,
                    }}
                  />
                )}
              </Tile>
            ) : null}
          </Grid.Item>
        </Grid>
      </Container>
    </Section>
  );
});

Benefit.displayName = `Benefit`;

export default Benefit;
