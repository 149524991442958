import { createContext } from "@hybrbase/system";
import { UseMainHeaderReturn } from "./use-main-header";

const [MainHeaderProvider, useMainHeaderContext] =
  createContext<UseMainHeaderReturn>({
    name: "MainHeaderContext",
    errorMessage:
      "useMainHeaderContext: `context` is undefined. Seems you forgot to wrap all MainHeader's components within <MainHeader />",
  });

export { MainHeaderProvider, useMainHeaderContext };
