import { getStoryblokVersion, storyblokApi } from "@/lib/storyblok/client";

type getStoryProp = {
  slug?: string;
  apiOptions?: any;
};

export const getStory = async ({ slug, apiOptions }: getStoryProp) => {
  const data = await storyblokApi
    .get(`cdn/stories${slug}`, {
      version: getStoryblokVersion,
      ...apiOptions,
    })
    .catch((error) => {
      console.log(error);
    });

  return data;
};
