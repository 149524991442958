export enum VideoParts {
  Root = "Root",
  PlayBtn = "PlayBtn",
  PlayBtnBackground = "PlayBtnBackground",
  Timeline = "Timeline",
  TimelineProgress = "TimelineProgress",
  TimelineInputRange = "TimelineInputRange",
  Controls = "Controls",
  ControlsButton = "ControlsButton",
  ControlsTime = "ControlsTime",
  Captions = "Captions",
}

export enum VideoVariant {
  Default = "default",
}

export enum VideoLayout {
  Contain = "contain",
  Ratio = "ratio",
  BackgroundCover = "background-cover",
}
