import { HTMLProps, forwardRef, useMergeRefs, cx } from "@hybrbase/system";
import { useCarouselContext } from "../utils/carousel-context";

/**
 * CarouselSlides
 */
export interface CarouselSlidesProps extends HTMLProps<"div"> {}

export const CarouselSlides = forwardRef<CarouselSlidesProps, "div">(
  ({ className, children, ...rest }, ref) => {
    const { emblaRef } = useCarouselContext();

    return (
      <div
        data-comp="carousel-slides"
        className={cx("carousel-slides")}
        ref={useMergeRefs(emblaRef, ref)}
        {...rest}
      >
        <div className={cx("carousel-slides-container", className)}>
          {children}
        </div>
      </div>
    );
  }
);
