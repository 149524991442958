import { Hero } from "landing";
import { storyblokEditable } from "@hybrbase/storyblok";
import { useMemo } from "react";
import { getSbImageInfo } from "@/lib/storyblok/get-image-info";
import { AspectRatioSize } from "layout";
import { getImageService, getVideoService, isVideo } from "@/utils";
import { renderRichText } from "@hybrbase/storyblok";
import { usePageContext } from "@/providers";

export const SbHero = ({ blok }) => {
  const {
    title,
    text,
    link_label,
    link_href,
    image_src,
    video_src,
    video_poster,
    theme,
  } = blok; // Component scheme
  const { theme: pageTheme } = usePageContext(); // solve and refactor this later

  const { sbImage, sbVideo } = useMemo(
    () => ({
      sbImage: getSbImageInfo(
        image_src?.filename,
        AspectRatioSize.ThreeDivideByOne
      ),
      sbVideo: {
        src: getVideoService({ src: video_src?.filename }),
        poster: getImageService({ src: video_poster?.filename }),
        isBoolean: isVideo(video_src?.filename),
      },
    }),

    [image_src?.filename, video_src, video_poster]
  );

  return (
    <Hero
      title={renderRichText(title)}
      text={text}
      link={{
        href: link_href?.cached_url,
        isOpenNewTab: link_href?.target === "_blank",
        children: link_label,
      }}
      theme={pageTheme || theme}
      image={{
        src: sbImage?.filename,
        alt: image_src?.alt,
        title: image_src?.title,

        width: sbImage.width,
        height: sbImage.height,
      }}
      isVideo={sbVideo.isBoolean}
      video={{
        src: sbVideo?.src,
        poster: sbVideo?.poster,
      }}
      {...storyblokEditable(blok)}
    />
  );
};
