export enum StackParts {
  Root = "Root",
  Item = "Item",
}

export enum StackVariant {
  Default = "default",
  Responsive = "responsive",
}

export enum StackDirection {
  Row = "row",
  Col = "col",
}

export enum StackSpace {
  Xs = "xs",
  Sm = "sm",
  Md = "md",
  Lg = "lg",
  Xl = "xl",
}
