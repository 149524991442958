import { Benefit } from "landing";
import { storyblokEditable } from "@hybrbase/storyblok";
import { useMemo } from "react";
import { getSbImageInfo } from "@/lib/storyblok";
import { AspectRatioSize } from "layout";
import { getImageService, getVideoService, isVideo } from "@/utils";
import { renderRichText } from "@hybrbase/storyblok";

export const SbBenefit = ({ blok }) => {
  const {
    title,
    subtitle,

    image_src,
    video_src,
    video_poster,
    items,
    theme,
  } = blok; // Component scheme

  const { sbImage, sbVideo } = useMemo(
    () => ({
      sbImage: getSbImageInfo(
        image_src?.filename,
        AspectRatioSize.ThreeDivideByOne
      ),
      sbVideo: {
        src: getVideoService({ src: video_src?.filename }),
        poster: getImageService({ src: video_poster?.filename }),
        isBoolean: isVideo(video_src?.filename),
      },
    }),

    [image_src?.filename, video_src, video_poster]
  );

  return (
    <Benefit
      title={renderRichText(title)}
      subtitle={subtitle}
      items={items}
      theme={theme}
      image={{
        src: sbImage?.filename,
        alt: image_src?.alt,
        title: image_src?.title,

        width: sbImage.width,
        height: sbImage.height,
      }}
      isVideo={sbVideo.isBoolean}
      video={{
        src: sbVideo?.src,
        poster: sbVideo?.poster,
      }}
      {...storyblokEditable(blok)}
    />
  );
};
