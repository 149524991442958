import { HTMLProps, cx, forwardRef } from "@hybrbase/system";
import { useGridItem, UseGridItemProps } from "../utils/use-grid-item";

/**
 * GridItem
 */
export interface GridItemProps extends UseGridItemProps, HTMLProps<"div"> {}

export const GridItem = forwardRef<GridItemProps, "div">(
  ({ className, size, offset, children, ...rest }, ref) => {
    const { variant, styles, rootStyles, isDebug } = useGridItem({
      size,
      offset,
    });

    return (
      <div
        data-comp="grid-item"
        data-variant={variant}
        className={cx(styles.Root, className, {
          relative: isDebug,
        })}
        ref={ref}
        {...rest}
      >
        {children}
        {isDebug && <span className={rootStyles.ItemDebugLayer} />}
      </div>
    );
  }
);
