import { CardParts, CardVariant } from "../types/Card.constants";
import { TCardConfigProps } from "../types/Card.config.types";

export const CardConfig: TCardConfigProps = {
  parts: Object.values(CardParts),
  css: {
    variants: {
      [CardVariant.Default]: {
        Root: "block rounded-md h-full",
        Header: "aspect-21/9 sm:aspect-auto sm:pb-[150px] pt-lg px-lg",
        Body: "py-lg px-lg sm:pt-lg border-t-1 border-solid border-contrast-medium flex justify-between items-start space-x-md md:space-x-md",
        Icon: "flex-shrink-0 fill-none text-contrast-medium",
      },
    },
  },
};
