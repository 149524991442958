import React, { useMemo } from "react";
import { HTMLProps, cx, forwardRef, useCompConfig } from "@hybrbase/system";
import { IntroConfig } from "../styles/Intro.config";
import { IntroVariant } from "../types/Intro.constants";
import {
  Heading,
  HeadingSize,
  HeadingVariant,
  LinkFx,
  LinkFxProps,
  Text,
} from "elements";
import {
  Section,
  SectionData,
  Container,
  TextContainer,
  Grid,
  Tile,
} from "layout";
import { ThemeElement } from "@hybrbase/themes";
import { introFactory } from "../utils/intro.factory";
import { TIntroConfigReturn } from "../types/Intro.config.types";

export interface IntroData extends SectionData {
  link?: LinkFxProps;
}

export interface IntroOptions {
  theme?: ThemeElement;
  headingSize?: HeadingSize.FluidLg | HeadingSize.FluidMd;
}

export interface IntroProps
  extends Omit<HTMLProps<"section">, keyof IntroData>,
    IntroOptions,
    IntroData {
  variant?: IntroVariant;
}

export const Intro = forwardRef<IntroProps, "section">((props, ref) => {
  const {
    variant = IntroVariant.Default,
    className,
    theme = ThemeElement.Default,
    headingSize,
    title,
    text,
    subtitle,
    link,
    children,
    ...rest
  } = props;

  const { styles }: TIntroConfigReturn = useCompConfig(IntroConfig, {
    variant,
  });

  const factory = useMemo(() => introFactory(variant), [variant]);

  return (
    <Section
      data-comp="intro"
      data-variant={variant}
      theme={theme}
      className={cx(styles.Root, className)}
      ref={ref}
      {...rest}
    >
      <Container className={styles.Container}>
        <Grid
          columns={factory?.grid?.columns}
          className={styles.Grid}
          gap={factory?.grid?.gap}
        >
          <Grid.Item className={styles.GridItemOne}>
            <Tile className={styles.TileOne}>
              {subtitle && (
                <TextContainer
                  align={factory?.textContainer?.align}
                  size={factory?.textContainer?.size}
                  className={cx(styles.TextContainer, "mb-md")}
                >
                  <Heading variant={HeadingVariant.SubTitle}>
                    {subtitle}
                  </Heading>
                </TextContainer>
              )}
              <TextContainer
                align={factory?.textContainer?.align}
                size={factory?.textContainer?.size}
                className={styles.TextContainer}
              >
                <Heading
                  variant={HeadingVariant.Section}
                  size={headingSize}
                  className={cx(styles.Heading)}
                >
                  {title}
                </Heading>
                {text && <Text className={styles.Text}>{text}</Text>}
              </TextContainer>
              {link?.href || link?.children ? (
                <div className="pt-fluid-md-to-lg">
                  <LinkFx
                    {...link}
                    className={styles.LinkFx}
                    href={link?.href}
                  />
                </div>
              ) : null}
            </Tile>
          </Grid.Item>
          {children ? (
            <Grid.Item className={styles.GridItemTwo}>
              <Tile className={styles.TileTwo}>{children}</Tile>
            </Grid.Item>
          ) : null}
        </Grid>
      </Container>
    </Section>
  );
});

Intro.displayName = `Intro`;

export default Intro;
