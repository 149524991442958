import { getSbImageInfo, useStoryblok } from "@/lib/storyblok";
import { PageSeoProps } from "@/providers";
import { useMemo } from "react";

export interface UseStorySeoPageProps {
  resolvedUrl?: string;
  seo: PageSeoProps;
  story: any;
}

export function useStorySeoPage({
  // resolvedUrl,
  seo,
  story,
}: UseStorySeoPageProps) {
  // for static page
  // const pageUrlsQuery = useQuery(
  //   [
  //     "language-alternates",
  //     {
  //       resolvedUrl,
  //     },
  //   ],
  //   () => fetcher(`/api/page-urls?slug=${resolvedUrl}`)
  // );

  const pageStory = useStoryblok(story);

  const storySeo = useMemo(
    () => ({
      title: pageStory?.content?.meta_title || pageStory?.name,
      description:
        pageStory?.content?.meta_description || pageStory?.content?.excerpt,
      thumbnail: getSbImageInfo(
        pageStory?.content?.og_image?.filename ||
          pageStory?.content?.thumbnail?.filename
      ).filename,
      // client and ssr fallback
      languageAlternates: seo?.languageAlternates,
      // client and ssr fallback
      defaultCanonicalUrl: seo?.defaultCanonicalUrl,
      ...seo,
    }),
    [pageStory, seo]
  );

  return [storySeo];
}
