import React from "react";
import {
  HTMLProps,
  cx,
  forwardRef,
  useCompConfig,
  ForwardRefComponent,
} from "@hybrbase/system";
import { WorkCardConfig } from "../styles/WorkCard.config";
import { WorkCardVariant } from "../types/WorkCard.constants";
import { TWorkCardConfigReturn } from "../types/WorkCard.config.types";
import {
  AspectRatioSize,
  Grid,
  GridGap,
  GridItemSize,
  TextContainer,
  Tile,
  TileXSpace,
  TileYSpace,
} from "layout";
import {
  AspectRatioImage,
  Heading,
  HeadingVariant,
  Text,
  ImageProps,
  Link,
  LinkVariant,
  TextSize,
  LinkProps,
} from "elements";
import { uid } from "react-uid";
import { BorderRadius, ThemeElement } from "@hybrbase/themes";

export interface WorkCardData {
  image?: ImageProps;
  link?: LinkProps;
  title: string;
  description: string;
  labels?: string[];
}

export interface WorkCardOptions {
  /**
   * Variants for `WorkCard`. You can extend the variant.
   */
  variant?: WorkCardVariant;
}
export interface WorkCardProps
  extends Omit<HTMLProps<"div">, keyof WorkCardData>,
    WorkCardOptions,
    WorkCardData {}

type WorkCardParts = ForwardRefComponent<"div", WorkCardProps> & {};

export const WorkCard: WorkCardParts = forwardRef<WorkCardProps, "div">(
  (props, ref) => {
    const {
      variant = WorkCardVariant.Default,
      className,
      image,
      link,
      labels = ["Label 1", "Label 2"],
      title = "Title 1",
      description,
      ...rest
    } = props;

    const { styles }: TWorkCardConfigReturn = useCompConfig(WorkCardConfig, {
      variant,
    });

    return (
      <div
        data-comp="work-card"
        data-theme-element={ThemeElement.Dark}
        data-variant={variant}
        className={cx(styles.Root, className)}
        ref={ref}
        {...rest}
      >
        <Link {...link} href={link?.href} variant={LinkVariant.Reset}>
          <AspectRatioImage
            image={{
              ...image,
              className: "transition-all duration-base",
              title,
            }}
            aspectRatio={{
              size: AspectRatioSize.Square,
              radius: BorderRadius.Lg,
            }}
          />

          <Tile
            className="absolute bottom-0 left-0 w-full"
            spaceX={TileXSpace.Sm}
            spaceY={TileYSpace.Sm}
          >
            {title ? (
              <TextContainer>
                <Heading variant={HeadingVariant.Card}>{title}</Heading>
                <Text size={TextSize.FluidSm}>{description}</Text>
              </TextContainer>
            ) : null}

            <div className="hidden xs:block mt-md border-b-1 border-solid border-contrast-high opacity-50" />

            {labels ? (
              <Grid
                gap={GridGap.Sm}
                className="mt-md hidden xs:inline-flex xs:gap-xs"
              >
                {labels?.map((item, index) => {
                  return (
                    <Grid.Item
                      size={GridItemSize.Content}
                      className="py-xs px-sm rounded-lg text-xs bg-white text-black"
                      key={uid(index)}
                    >
                      {item}
                    </Grid.Item>
                  );
                })}
              </Grid>
            ) : null}
          </Tile>
        </Link>
      </div>
    );
  }
);

WorkCard.displayName = `WorkCard`;

export default WorkCard;
