import { useCompConfig } from "@hybrbase/system";
import { GridConfig } from "../styles/Grid.config";
import { GridColumns, GridGap, GridVariant } from "../types/Grid.constants";
import { TGridConfigReturn } from "../types/Grid.config.types";

export interface UseGridProps {
  /**
   * Variants for `Grid`. You can extend the variant.
   */
  variant?: GridVariant;
  gap?: GridGap;
  columns?: GridColumns;
  isDebug?: boolean;
}

/**
 * Grid hook that manages all the logic
 * and returns prop getters, state and actions.
 *
 * @param props
 */
export const useGrid = (props: UseGridProps) => {
  const { variant, isDebug, gap, columns } = props;

  const { styles }: TGridConfigReturn = useCompConfig(GridConfig, {
    variant,
    css: { gap, columns },
  });

  return {
    variant,
    isDebug,
    styles,
  };
};

export type UseGridReturn = ReturnType<typeof useGrid>;
