import { createContext } from "@hybrbase/system";
import { UseGridReturn } from "./use-grid";

const [GridProvider, useGridContext] = createContext<UseGridReturn>({
  name: "GridContext",
  errorMessage:
    "useGridContext: `context` is undefined. Seems you forgot to wrap all Grid's components within <Grid />",
});

export { GridProvider, useGridContext };
