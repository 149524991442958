import {
  GridColumns,
  GridGap,
  GridParts,
  GridVariant,
} from "../types/Grid.constants";
import { TGridConfigProps } from "../types/Grid.config.types";

const itemDebugLayerStyle =
  "bg-accent opacity-25 absolute h-full w-full top-0 left-0";

export const GridConfig: TGridConfigProps = {
  parts: Object.values(GridParts),
  css: {
    variants: {
      [GridVariant.Default]: {
        Root: "flex-grid",
        ItemDebugLayer: itemDebugLayerStyle,
      },
      [GridVariant.Custom]: {
        Root: "flex-grid",
        ItemDebugLayer: itemDebugLayerStyle,
      },
    },
    props: {
      columns: {
        [GridColumns.Four]: {
          Root: "flex-grid-2 sm:flex-grid-4",
        },
        [GridColumns.Three]: {
          Root: "flex-grid-1 sm:flex-grid-3",
        },
        [GridColumns.Twelve]: {
          Root: "flex-grid-6 sm:flex-grid-12",
        },
        [GridColumns.Custom]: {
          Root: null,
        },
      },
      gap: {
        [GridGap.None]: {
          Root: null,
        },
        [GridGap.Xs]: {
          Root: "flex-gap-xs",
        },
        [GridGap.Sm]: {
          Root: "flex-gap-sm",
        },
        [GridGap.Md]: {
          Root: "flex-gap-md",
        },
        [GridGap.Lg]: {
          Root: "flex-gap-lg",
        },
        [GridGap.Xl]: {
          Root: "flex-gap-md sm:flex-gap-xl",
        },
      },
    },
  },
};
