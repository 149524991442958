import { MarqueeParts, MarqueeVariant } from "../types/Marquee.constants";
import { TMarqueeConfigProps } from "../types/Marquee.config.types";

export const MarqueeConfig: TMarqueeConfigProps = {
  parts: Object.values(MarqueeParts),
  css: {
    variants: {
      [MarqueeVariant.Default]: {
        Root: "",
      },
    },
  },
};
