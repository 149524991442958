import { ThemeProvider as NextThemeProvider, useTheme } from "next-themes";
import { ThemeProviderProps as NextThemeProviderProps } from "next-themes/dist/types";

import React from "react";

export { useTheme };

export interface ThemeProviderProps extends NextThemeProviderProps {
  children?: React.ReactNode;
}

export const ThemeProvider = ({ children, ...rest }: ThemeProviderProps) => {
  return (
    <NextThemeProvider
      enableSystem={false}
      attribute="class"
      disableTransitionOnChange
      {...rest}
    >
      {children}
    </NextThemeProvider>
  );
};
