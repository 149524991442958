import { cx, forwardRef, HTMLProps } from "@hybrbase/system";
import { useAccordionContext, useAccordionItemContext } from "../utils";

export interface AccordionPanelProps extends HTMLProps<"div"> {}

export const AccordionPanel = forwardRef<AccordionPanelProps, "div">(
  ({ className, children, ...rest }, ref) => {
    const { styles } = useAccordionContext();
    const { getPanelProps, isOpen } = useAccordionItemContext();

    const panelProps = getPanelProps(rest, ref);

    return (
      <div
        data-comp="accordion-panel"
        className={cx(styles.Panel, { "is-open": isOpen }, className)}
        {...panelProps}
      >
        {children}
      </div>
    );
  }
);
