import {
  StackDirection,
  StackParts,
  StackSpace,
  StackVariant,
} from "../types/Stack.constants";
import { TStackConfigProps, TStackParts } from "../types/Stack.config.types";

const responsiveSpacing: Record<StackSpace, TStackParts> = {
  [StackSpace.Xs]: {
    Item: "mb-xs xs:mr-xs",
  },
  [StackSpace.Sm]: {
    Item: "mb-sm xs:mr-sm",
  },
  [StackSpace.Md]: {
    Item: "mb-md xs:mr-md",
  },
  [StackSpace.Lg]: {
    Item: "mb-lg xs:mr-lg",
  },
  [StackSpace.Xl]: {
    Item: "mb-xl xs:mr-xl",
  },
};

export const StackConfig: TStackConfigProps = {
  parts: Object.values(StackParts),
  css: {
    variants: {
      [StackVariant.Default]: {
        Root: "flex",
      },
      [StackVariant.Responsive]: {
        Root: "block xs:flex xs:flex-wrap",
        props: {
          row: responsiveSpacing,
          col: responsiveSpacing,
        },
      },
    },
    props: {
      direction: {
        [StackDirection.Row]: {
          Root: "flex-row",
        },
        [StackDirection.Col]: {
          Root: "flex-col",
        },
      },
      row: {
        [StackSpace.Xs]: {
          Item: "mr-xs",
        },
        [StackSpace.Sm]: {
          Item: "mr-sm",
        },
        [StackSpace.Md]: {
          Item: "mr-md",
        },
        [StackSpace.Lg]: {
          Item: "mr-lg",
        },
        [StackSpace.Xl]: {
          Item: "mr-xl",
        },
      },
      col: {
        [StackSpace.Xs]: {
          Item: "mb-xs",
        },
        [StackSpace.Sm]: {
          Item: "mb-sm",
        },
        [StackSpace.Md]: {
          Item: "mb-md",
        },
        [StackSpace.Lg]: {
          Item: "mb-lg",
        },
        [StackSpace.Xl]: {
          Item: "mb-xl",
        },
      },
    },
  },
};
