import {
  AccordionItemProvider,
  useAccordionContext,
  useAccordionItem,
} from "../utils";
import { cx, forwardRef, HTMLProps } from "@hybrbase/system";
import { useMemo } from "react";

export interface AccordionItemProps extends Omit<HTMLProps<"div">, "children"> {
  children?:
    | React.ReactNode
    | ((props: {
        isExpanded: boolean;
        isDisabled: boolean;
      }) => React.ReactNode);
}

export const AccordionItem = forwardRef<AccordionItemProps, "div">(
  ({ className, children, ...rest }, ref) => {
    const { styles } = useAccordionContext();
    const { ...ctx } = useAccordionItem(rest);

    const context = useMemo(() => ctx, [ctx]);

    return (
      <AccordionItemProvider value={context}>
        <div
          data-comp="accordion-item"
          className={cx(styles.Item, className)}
          ref={ref}
          {...rest}
        >
          {typeof children === "function"
            ? children({
                isExpanded: !!context.isOpen,
                isDisabled: !!context.isDisabled,
              })
            : children}
        </div>
      </AccordionItemProvider>
    );
  }
);
