import { useCallback, useMemo, useRef, useState } from "react";
import playInlineVideo from "iphone-inline-video";
import insertRule from "insert-rule";
import BackgroundCover from "background-cover";
import { noop } from "utils";
import { VideoLayout, VideoVariant } from "../types/Video.constants";
import { useSafeLayoutEffect } from "hooks";
import fullscreenHandler from "fullscreen-handler";
import { useCompConfig } from "@hybrbase/system";
import { VideoConfig } from "../styles/Video.config";
import { TVideoConfigReturn } from "../types/Video.config.types";

const iOSNavigator =
  typeof navigator !== "undefined" &&
  navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
const iOSVersion = iOSNavigator ? iOSNavigator[1] : null;
// eslint-disable-next-line @typescript-eslint/no-empty-function

export type onTimeUpdateProps = {
  currentTime: number;
  progress?: number;
  duration: number;
};

export type onReadyProps = {
  duration: number;
};

export type Captions = {
  kind: string;
  label: string;
  srclang: string;
  default: boolean;
  src: string;
};

export interface VideoCbEventsType {
  onMute?: () => void;
  onUnmute?: () => void;
  onPlay?: () => void;
  onPause?: () => void;
  onReady?: ({ duration }: onReadyProps) => void;
  onEnd?: () => void;
  onCaption?: () => void;
  onFullScreen?: () => void;
  onMouseMove?: () => void;
  onTimeUpdate?: ({
    currentTime,
    progress,
    duration,
  }: onTimeUpdateProps) => void;
}

export interface UseVideoOptions extends VideoCbEventsType {
  hasAllowKeyboardControl?: boolean;
  /**
   *  Required to be set to true for auto play on mobile in combination with 'autoPlay' option
   */
  muted?: boolean;
  /**
   *  Do not apply cover effect (e.g. disable it for specific screen resolution or aspect ratio)
   */
  hasDisableBackgroundCover?: boolean;
  /**
   *   Number from 0 to 1, where 0 means aligned to the left. Default value is 0.5 (centered)
   */
  horizontalAlign?: number;
  /**
   *    Number from 0 to 1, where 0 means aligned to the top. Default value is 0.5 (centered)
   */
  verticalAlign?: number;
  startTime?: number;
  volume?: number;
  autoPlay?: boolean;
  containerWidth?: number;
  containerHeight?: number;
  captions?: Captions;
  layout?: VideoLayout;
  variant?: VideoVariant;
}

export interface UseVideoProps extends UseVideoOptions {}

/**
 * Video hook that manages all the logic
 * and returns prop getters, state and actions.
 *
 * @param props
 */
export const useVideo = (props: UseVideoProps) => {
  const {
    onMute = noop,
    onUnmute = noop,
    onPlay = noop,
    onReady = noop,
    onPause = noop,
    onEnd = noop,
    onTimeUpdate = noop,
    onCaption = noop,
    onFullScreen = noop,
    onMouseMove = noop,
    containerWidth,
    containerHeight,
    hasDisableBackgroundCover = true,
    horizontalAlign = 0.5,
    verticalAlign = 0.5,
    startTime,
    volume = 1,
    autoPlay,
    muted,
    variant,
    layout,
    hasAllowKeyboardControl,
    captions,
  } = props;

  const containerRef = useRef<HTMLDivElement | null>(null);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const fullScreen = useRef(null);

  const [isLoaded, setLoaded] = useState<boolean>(false);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [isSetStartTime, setStartTime] = useState<boolean>(false);
  const [isMouseHovered, setIsMouseHovered] = useState<boolean>(false);
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);

  const [isShowingCaptions, setIsShowingCaptions] = useState<boolean>(
    captions && captions.default
  );

  const [currentCaptions, setCurrentCaptions] = useState<string>("");

  const { styles }: TVideoConfigReturn = useCompConfig(VideoConfig, {
    variant,
    css: {
      layout,
    },
  });

  const [currentTime, setTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [progress, setProgress] = useState(0);

  const formatedTime = useMemo(() => {
    const totalSecondsFloat = currentTime;
    const minutes = Math.floor(totalSecondsFloat / 60);
    const seconds = Math.round(totalSecondsFloat - minutes * 60);
    let minutesValue;
    let secondsValue;

    if (minutes < 10) {
      minutesValue = `0${minutes}`;
    } else {
      minutesValue = minutes;
    }
    if (seconds < 10) {
      secondsValue = `0${seconds}`;
    } else {
      secondsValue = seconds;
    }
    return `${minutesValue}:${secondsValue}`;
  }, [currentTime]);

  const play = () => {
    videoRef.current && videoRef.current.play();
  };

  const pause = () => {
    videoRef.current && videoRef.current.pause();
  };

  const togglePlay = () => {
    if (!videoRef.current) return;
    videoRef.current.paused ? play() : pause();
  };

  const mute = () => {
    if (!videoRef.current) return;
    videoRef.current.muted = true;
    onMute();
  };

  const unmute = () => {
    if (!videoRef.current) return;
    videoRef.current.muted = false;
    onUnmute();
  };

  const toggleMute = () => {
    if (!videoRef.current) return;
    videoRef.current.muted ? unmute() : mute();
  };

  const setCurrentTime = (val: number) => {
    if (!videoRef.current) return;
    videoRef.current.currentTime = val;
  };

  const isPaused = videoRef.current ? videoRef.current.paused : false;
  const isMuted = videoRef.current ? videoRef.current.muted : false;

  const _resize = () => {
    videoRef.current &&
      BackgroundCover(
        videoRef.current,
        containerRef.current,
        horizontalAlign,
        verticalAlign
      );
  };

  const _handleVideoReady = useCallback(() => {
    if (!hasDisableBackgroundCover) {
      _resize();
    }

    if (captions) {
      captions.src && setCaptions(captions);
    }

    setLoaded(true);

    startTime && setCurrentTime(startTime);
    autoPlay && play();
    videoRef.current && onReady({ duration });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    hasDisableBackgroundCover,
    startTime,
    autoPlay,
    play,
    onReady,
    duration,
    captions,
  ]);

  const _handleOnPlay = () => {
    onPlay();
    setIsPlaying(true);
  };

  const _handleOnPause = () => {
    onPause();
    setIsPlaying(false);
  };

  const _handleOnMouseOver = () => {
    onMouseMove(), setIsMouseHovered(true);
  };
  const _handleOnMouseOut = () => setIsMouseHovered(false);

  useSafeLayoutEffect(
    () => {
      if (containerRef.current) {
        containerRef.current.addEventListener("mouseover", _handleOnMouseOver);
        containerRef.current.addEventListener("mouseout", _handleOnMouseOut);
        return () => {
          containerRef.current.removeEventListener(
            "mouseover",
            _handleOnMouseOut
          );
          containerRef.current.removeEventListener(
            "mouseout",
            _handleOnMouseOver
          );
        };
      }
    },
    [containerRef.current] // Recall only if ref changes
  );

  const _handleIOSStartTime = () => {
    if (!videoRef.current) return;
    if (videoRef.current.currentTime < startTime && !isSetStartTime) {
      setCurrentTime(startTime);
      setStartTime(true);
    }
  };

  const handleOnTimeUpdate = () => {
    if (!videoRef.current) return;
    iOSVersion && _handleIOSStartTime();
    const currentTime = videoRef.current.currentTime;
    const duration = videoRef.current.duration;
    const progress = currentTime / duration;

    setTime(currentTime);
    setDuration(duration);
    setProgress(progress);

    onTimeUpdate({ currentTime, progress, duration });
  };

  const setTimeUpdate = ({
    currentTime,
    progress,
    duration,
  }: onTimeUpdateProps) => {
    setCurrentTime(currentTime);
    setDuration(duration);
    setProgress(progress);
  };

  const handleOnEnded = () => {
    onEnd();
  };

  const handleOnKeyPress = (e: React.KeyboardEvent) => {
    if (hasAllowKeyboardControl) {
      const event = e.key;
      if (event === "Spacebar" || event === " ") {
        togglePlay();
      }
    }
  };

  // tracks
  const track = useRef(null);

  const onTrackChange = () => {
    const trackList = videoRef.current.textTracks as any;

    const textTracks = trackList && trackList.length > 0 ? trackList[0] : null;
    const cue =
      textTracks && textTracks.activeCues && textTracks.activeCues.length > 0
        ? textTracks.activeCues[0]
        : null;
    const text = cue ? cue?.text : "";

    setCurrentCaptions(text);
  };

  const setCaptions = (captions) => {
    if (!captions) return;

    const video = videoRef.current;
    if (video.contains(track.current)) {
      video.removeChild(track.current);
      track.current?.removeEventListener("cuechange", onTrackChange);
    }

    const trackElement = document.createElement("track");
    trackElement.kind = captions.kind;
    trackElement.label = captions.label;
    trackElement.srclang = captions.srclang;
    trackElement.default = captions.default;
    trackElement.src = captions.src;
    trackElement.track.mode = "hidden";

    track.current = trackElement;
    video.appendChild(trackElement);
    // Turn off native subtitle
    video.textTracks[0].mode = "hidden";
    trackElement.style.display = "none";

    track.current.addEventListener("cuechange", onTrackChange);
  };

  // captions
  const toggleCaptions = () => {
    if (isShowingCaptions) {
      onCaption();
    }
    setIsShowingCaptions((isShowing) => !isShowing);
  };

  useSafeLayoutEffect(() => {
    setCaptions(captions);
  }, [captions]);

  // fullscreen
  const onEnterFullScreen = () => {
    setIsFullScreen(true);
  };

  const onExitFullScreen = () => {
    setIsFullScreen(false);
  };

  const toggleFullscreen = () => {
    if (isFullScreen) {
      onFullScreen();
      fullScreen.current.exit();
    } else {
      fullScreen.current.enter();
    }
  };

  useSafeLayoutEffect(() => {
    if (videoRef.current?.playsInline && iOSVersion) {
      const hasAudio = !(
        iOSVersion &&
        Number(iOSVersion) < 10 &&
        autoPlay &&
        muted
      ); // allow autoplay on iOS < 10 for silent videos
      const requireInteractionOnTablet = false;

      playInlineVideo(videoRef.current, hasAudio, requireInteractionOnTablet);
      insertRule(
        [
          "video::-webkit-media-controls-start-playback-button",
          ".IIV::-webkit-media-controls-play-button",
        ],
        {
          display: "none",
        }
      );
    }

    if (videoRef.current) {
      if (videoRef.current.readyState !== 4) {
        videoRef.current.addEventListener("loadedmetadata", _handleVideoReady);
      } else {
        _handleVideoReady();
      }
      videoRef.current.addEventListener("play", _handleOnPlay);
      videoRef.current.addEventListener("pause", _handleOnPause);
      videoRef.current.volume = volume;
    }

    fullScreen.current = fullscreenHandler(
      containerRef?.current,
      onEnterFullScreen,
      onExitFullScreen
    );

    if (!hasDisableBackgroundCover) {
      window.addEventListener("resize", _resize);
    }

    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener(
          "loadedmetadata",
          _handleVideoReady
        );
        videoRef.current.removeEventListener("play", _handleOnPlay);
        videoRef.current.removeEventListener("pause", _handleOnPause);
      }
      if (isFullScreen) {
        fullScreen.current.exit();
      }
      fullScreen.current?.destroy();

      if (!hasDisableBackgroundCover) {
        window.removeEventListener("resize", _resize);
      }

      if (track.current) {
        track.current.removeEventListener("cuechange", onTrackChange);
      }
    };
  }, []);

  useSafeLayoutEffect(() => {
    if (videoRef.current) {
      if (volume !== videoRef.current.volume) {
        videoRef.current.volume = volume;
      }

      if (muted !== videoRef.current.muted) {
        videoRef.current.muted = muted;
      }
    }
  }, [
    volume,
    muted,
    containerWidth,
    containerHeight,
    hasDisableBackgroundCover,
  ]);

  return {
    containerRef,
    videoRef,
    variant,
    styles,
    formatedTime,
    play,
    pause,
    togglePlay,
    toggleMute,
    toggleCaptions,
    toggleFullscreen,
    mute,
    unmute,
    duration,
    currentTime,
    progress,
    isLoaded,
    isPlaying,
    isMuted,
    isPaused,
    isMouseHovered,
    isFullScreen,
    isAutoplay: autoPlay,
    setTimeUpdate,
    setCurrentTime,
    handleOnTimeUpdate,
    handleOnEnded,
    handleOnKeyPress,
    isShowingCaptions,
    setIsShowingCaptions,
    currentCaptions,
  };
};

export type UseVideoReturn = ReturnType<typeof useVideo>;
