import { Intro } from "landing";
import { storyblokEditable } from "@hybrbase/storyblok";
import { renderRichText } from "@hybrbase/storyblok";

export const SbIntro = ({ blok }) => {
  const {
    title,
    subtitle,
    link_label,
    heading_size,
    link_href,
    variant,
    theme,
  } = blok; // Component scheme
  return (
    <Intro
      title={renderRichText(title)}
      subtitle={subtitle}
      link={{
        href: link_href?.cached_url,
        isOpenNewTab: link_href?.target === "_blank",
        children: link_label,
      }}
      variant={variant}
      theme={theme}
      headingSize={heading_size}
      {...storyblokEditable(blok)}
    />
  );
};
