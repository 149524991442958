import { globalConfig } from "@/config";
import { create } from "zustand";

interface PageState {
  // pageTransitionDuration: number;
  lenis: any;
  setLenis: (lenis: any) => void;
  isScreenLocked: boolean;
  setScreenLocked: (isScreenLocked: boolean) => void;
  isPageTransition: boolean;
  setPageTransition: (isPageTransition: boolean) => void;
  pageDuration: number;
  setPageDuration: (pageDuration: number) => void;
  isMenuOpen: boolean;
  setMenuOpen: (isMenuOpen: boolean) => void;
  isCalendlyOpen: boolean;
  setCalendlyOpen: (isCalendlyOpen: boolean) => void;
}

const usePageStore = create<PageState>()((set) => ({
  isPageTransition: false,
  setPageTransition: (isPageTransition) => set({ isPageTransition }),

  pageDuration: globalConfig.pageTransitionDuration,
  setPageDuration: (pageDuration) => set({ pageDuration }),

  lenis: undefined,
  setLenis: (lenis) => {
    set({ lenis });
  },

  isScreenLocked: false,
  setScreenLocked: (isScreenLocked) => set({ isScreenLocked }),

  isMenuOpen: false,
  setMenuOpen: (isMenuOpen) => set({ isMenuOpen }),

  isCalendlyOpen: false,
  setCalendlyOpen: (isCalendlyOpen) => set({ isCalendlyOpen }),
}));

export default usePageStore;
