import { AccordionParts, AccordionVariant } from "../types/Accordion.constants";
import { TAccordionConfigProps } from "../types/Accordion.config.types";

export const AccordionConfig: TAccordionConfigProps = {
  parts: Object.values(AccordionParts),
  css: {
    variants: {
      [AccordionVariant.Default]: {
        Item: "block w-full border-b-1 border-solid border-contrast-high",
        Panel: "pb-fluid-lg-to-xxxl",
        Button: "flex items-center justify-between w-full text-left",
      },
    },
  },
};
