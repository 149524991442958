import { GridColumns, GridItemSize, TextContainerAlign } from "layout";
import { FeatureVariant } from "../types/Feature.constants";

export const featureFactory = (variant: FeatureVariant) => {
  switch (variant) {
    default:
      return {
        textContainer: {
          align: TextContainerAlign.LeftCenter,
        },
        grid: {
          column: GridColumns.Twelve,
        },
        gridItem: {
          size: GridItemSize.Four,
        },
      };
  }
};
