import { createContext } from "@hybrbase/system";
import { UseAccordionItemReturn, UseAccordionReturn } from "./use-accordion";
import createDescendantContext from "@chakra-ui/descendant";

interface AccordionContext
  extends Omit<UseAccordionReturn, "htmlProps" | "descendants"> {}

const [AccordionProvider, useAccordionContext] =
  createContext<AccordionContext>({
    name: "AccordionContext",
    errorMessage:
      "useAccordionContext: `context` is undefined. Seems you forgot to wrap all Accordion's components within <Accordion />",
  });

export { AccordionProvider, useAccordionContext };

type AccordionItemContext = Omit<UseAccordionItemReturn, "htmlProps">;

export const [AccordionItemProvider, useAccordionItemContext] =
  createContext<AccordionItemContext>({
    name: "AccordionItemContext",
    errorMessage:
      "useAccordionItemContext: `context` is undefined. Seems you forgot to wrap Accordion.Button or Accordion.Panel parts within <Accordion.Item />",
  });

export const [
  AccordionDescendantsProvider,
  useAccordionDescendantsContext,
  useAccordionDescendants,
  useAccordionDescendant,
] = createDescendantContext<HTMLButtonElement>();
