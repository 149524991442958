import { HeroConfig } from "../styles/Hero.config";
import { HeroVariant } from "../types/Hero.constants";
import { HTMLProps, cx, forwardRef, useCompConfig } from "@hybrbase/system";
import { ThemeElement } from "@hybrbase/themes";
import {
  Heading,
  HeadingVariant,
  LinkFx,
  Text,
  VideoProps,
  VideoLayout,
  LinkFxProps,
  ImageProps,
} from "elements";
import {
  Section,
  SectionData,
  Container,
  TextContainer,
  Grid,
  Tile,
  AspectRatioSize,
  GridColumns,
  TileXSpace,
  AspectRatioLayout,
  TextContainerAlign,
} from "layout";
import dynamic from "next/dynamic";
import { THeroConfigReturn } from "../types/Hero.config.types";
import { useMemo } from "react";

const LoadableAspectRatioImage = dynamic(
  () => import("elements/src/aspect-ratio-image/src/parts/AspectRatioImage")
);

const LoadableVideo = dynamic(
  () => import("elements/src/video/src/parts/Video")
);

export interface HeroData extends SectionData {
  video?: VideoProps;
  image?: ImageProps;
  link?: LinkFxProps;
}

export interface HeroOptions {
  variant?: HeroVariant;
  theme?: ThemeElement;
  isVideo?: boolean;
}

export interface HeroProps
  extends Omit<HTMLProps<"section">, keyof HeroData>,
    HeroOptions,
    HeroData {}

export const Hero = forwardRef<HeroProps, "section">((props, ref) => {
  const {
    variant = HeroVariant.Default,
    className,
    title,
    text,
    image,
    link,
    video,
    theme,
    isVideo,
    ...rest
  } = props;

  const { styles }: THeroConfigReturn = useCompConfig(HeroConfig, { variant });

  const hasOnlyTitle = useMemo(() => !link || !text, [link, text]);

  return (
    <Section
      data-comp="hero"
      data-variant={variant}
      className={cx(styles.Root, className)}
      theme={theme}
      ref={ref}
      {...rest}
    >
      <Container className={styles.Container} theme={theme}>
        <Grid
          columns={GridColumns.Custom}
          className="flex-grid-2 sm:flex-grid-4"
        >
          <Grid.Item className={cx({ "xs:flex-col-3": !hasOnlyTitle })}>
            <Tile
              className={cx({
                "sm:pr-0 sm:-mr-lg md:mr-0 md:pr-xxl": !hasOnlyTitle,
              })}
            >
              <TextContainer
                className={cx(styles.TextContainer, "max-w-sm sm:max-w-none", {
                  "mx-auto sm:!max-w-xxl": hasOnlyTitle,
                })}
                align={
                  hasOnlyTitle
                    ? TextContainerAlign.Center
                    : TextContainerAlign.Left
                }
              >
                {/*  6xl */}
                <Heading variant={HeadingVariant.Hero}>{title}</Heading>
              </TextContainer>
              {link.href || link.children ? (
                <div className="flex justify-end sm:justify-start mt-xl sm:mb-xl mb-sm">
                  <LinkFx {...link} href={link.href} />
                </div>
              ) : null}
            </Tile>
          </Grid.Item>
          <Grid.Item className="sm:flex-col-1 self-end  order-3 sm:order-2 ">
            <Tile className="sm:-ml-5xl lg:ml-0">
              <TextContainer
                className={cx(
                  styles.TextContainer,
                  "max-w-xxxxs sm:max-w-none ml-auto md:ml-0"
                )}
              >
                {text ? <Text className={styles.Text}>{text}</Text> : null}
              </TextContainer>
            </Tile>
          </Grid.Item>
          {video?.src || image?.src ? (
            <Grid.Item className="sm:flex-col-4 sm:order-3">
              {/* add susspend loading */}
              <Tile spaceX={TileXSpace.None} className="-mx-md">
                {isVideo ? (
                  <LoadableVideo
                    {...video}
                    hasTogglePlayBtn={false}
                    layout={VideoLayout.Ratio}
                    autoPlay
                    muted
                    aspectRatio={{
                      size: AspectRatioSize.SquareToSixteenDivideByNine,
                      layout: AspectRatioLayout.Cover,
                    }}
                  />
                ) : (
                  <LoadableAspectRatioImage
                    image={image}
                    aspectRatio={{
                      size: AspectRatioSize.SquareToSixteenDivideByNine,
                      layout: AspectRatioLayout.Cover,
                    }}
                  />
                )}
              </Tile>
            </Grid.Item>
          ) : null}
        </Grid>
      </Container>
    </Section>
  );
});
