import React from "react";
import {
  HTMLProps,
  cx,
  forwardRef,
  useCompConfig,
  ForwardRefComponent,
} from "@hybrbase/system";
import { CardConfig } from "../styles/Card.config";
import { CardVariant } from "../types/Card.constants";
import { ThemeElement } from "@hybrbase/themes";
import { TextContainer } from "layout";
import { Heading, HeadingVariant, Text, TextVariant } from "../../../index";
import { TCardConfigReturn } from "../types/Card.config.types";

export interface CardData {
  title?: string;
  text?: string;
}

export interface CardOptions {
  /**
   * Variants for `Card`. You can extend the variant.
   */
  variant?: CardVariant;
}
export interface CardProps
  extends Omit<HTMLProps<"div">, keyof CardData>,
    CardOptions,
    CardData {}

type CardParts = ForwardRefComponent<"div", CardProps>;

export const Card: CardParts = forwardRef<CardProps, "div">((props, ref) => {
  const {
    variant = CardVariant.Default,
    title,
    text,
    className,
    children,
    ...rest
  } = props;

  const { styles }: TCardConfigReturn = useCompConfig(CardConfig, { variant });

  return (
    <div
      data-comp="card"
      data-variant={variant}
      className={cx(styles.Root, className)}
      ref={ref}
      data-theme-element={ThemeElement.Default}
      {...rest}
    >
      <header className={styles.Header}>
        <TextContainer>
          <Heading variant={HeadingVariant.Card}>{title}</Heading>
        </TextContainer>
      </header>
      <article className={styles.Body}>
        <TextContainer>
          <Text variant={TextVariant.Small}>{text}</Text>
        </TextContainer>
      </article>
    </div>
  );
});

Card.displayName = `Card`;

export default Card;
