import React, { useMemo } from "react";
import {
  HTMLProps,
  cx,
  forwardRef,
  useCompConfig,
  getValidChildren,
} from "@hybrbase/system";
import { FeatureConfig } from "../styles/Feature.config";
import { FeatureVariant } from "../types/Feature.constants";
import {
  FeatureProvider,
  useFeature,
  UseFeatureProps,
} from "../utils/use-feature";
import { Heading, HeadingVariant, Text } from "elements";
import { Section, SectionData, Container, TextContainer, Grid } from "layout";
import { ThemeElement } from "@hybrbase/themes";
import { featureFactory } from "../utils/feature.factory";
import { uid } from "react-uid";
import { TFeatureConfigReturn } from "../types/Feature.config.types";

export interface FeatureData extends SectionData {}

export interface FeatureOptions {
  variant?: FeatureVariant;
  theme?: ThemeElement;
}

export interface FeatureProps
  extends Omit<HTMLProps<"section">, keyof FeatureData>,
    FeatureOptions,
    FeatureData,
    UseFeatureProps {}

export const Feature = forwardRef<FeatureProps, "section">((props, ref) => {
  const {
    variant = FeatureVariant.Default,
    className,
    theme = ThemeElement.Default,
    title,
    text,
    children,
    subtitle,
    ...rest
  } = props;

  const { ...ctx } = useFeature(rest);
  const context = React.useMemo(() => ctx, [ctx]);

  const { styles }: TFeatureConfigReturn = useCompConfig(FeatureConfig, {
    variant,
  });
  const factoryConfig = useMemo(() => featureFactory(variant), [variant]);

  return (
    <FeatureProvider value={context}>
      <Section
        data-comp="feature"
        data-variant={variant}
        theme={theme}
        className={cx(styles.Root, className)}
        ref={ref}
        {...rest}
      >
        <Container>
          <Grid>
            <Grid.Item className={styles.ColumnOne}>
              {subtitle && (
                <TextContainer align={factoryConfig?.textContainer?.align}>
                  <Heading variant={HeadingVariant.SubTitle}>
                    {subtitle}
                  </Heading>
                </TextContainer>
              )}
              <TextContainer align={factoryConfig?.textContainer?.align}>
                <Heading variant={HeadingVariant.Section}>{title}</Heading>
                {text && <Text>{text}</Text>}
              </TextContainer>
            </Grid.Item>
            <Grid.Item className={styles.ColumnTwo}>
              <Grid
                columns={factoryConfig?.grid?.column}
                className={styles.Grid}
              >
                {children &&
                  getValidChildren(children).map((child, index) => {
                    return (
                      <Grid.Item
                        className={styles.GridItem}
                        key={uid(index)}
                        size={factoryConfig?.gridItem?.size}
                      >
                        {child}
                      </Grid.Item>
                    );
                  })}
              </Grid>
            </Grid.Item>
          </Grid>
        </Container>
      </Section>
    </FeatureProvider>
  );
});

Feature.displayName = `Feature`;

export default Feature;
