import { useAccordionContext, useAccordionItemContext } from "../utils";
import { cx, forwardRef, HTMLProps } from "@hybrbase/system";

export interface AccordionButtonProps extends HTMLProps<"button"> {}

export const AccordionButton = forwardRef<AccordionButtonProps, "button">(
  ({ className, children, ...rest }, ref) => {
    const { styles } = useAccordionContext();
    const { getButtonProps, isOpen } = useAccordionItemContext();

    const buttonProps = getButtonProps(rest, ref);

    return (
      <button
        data-comp="accordion-button"
        className={cx(styles.Button, { "is-open": isOpen }, className)}
        {...buttonProps}
      >
        {children}
      </button>
    );
  }
);
