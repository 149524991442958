import {
  MediaFeatureParts,
  MediaFeatureVariant,
} from "../types/MediaFeature.constants";
import { TMediaFeatureConfigProps } from "../types/MediaFeature.config.types";

export const MediaFeatureConfig: TMediaFeatureConfigProps = {
  parts: Object.values(MediaFeatureParts),
  css: {
    variants: {
      [MediaFeatureVariant.Default]: {
        Root: "",
      },
    },
  },
};
