export enum CardParts {
  Root = "Root",
  Header = "Header",
  Body = "Body",
  Icon = "Icon",
}

export enum CardVariant {
  Default = "default",
  Post = "Post",
}
